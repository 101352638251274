import React, {useEffect, useState} from 'react';
import './RecentChatListLoader.css';

const RecentChatListLoader = () => {
    const [loaderCount, setLoaderCount] = useState(0);

    useEffect(() => {
        const updateLoaderCount = () => {
            // Calculate how many loaders can fit in the current viewport height
            const count = Math.floor(window.innerHeight / 60);
            setLoaderCount(count);
        };

        // Update loader count on initial render
        updateLoaderCount();

        // Update loader count whenever the window is resized
        window.addEventListener('resize', updateLoaderCount);

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateLoaderCount);
    }, []);





    return (
        <div className='recent-chat-main-container'>


            {/* Top Component with two loaders */}
            <div className="top-loaders-container">
                <div className="smaller-loader-rectangular"></div>
                <div className="smaller-loader-rectangular"></div>
            </div>

            <div className='loader-wrapper'>
                <div className="recent-loader-standard-1"></div>
            </div>

            <div className='loader-wrapper'>
                <div className="recent-loader-standard-2"></div>
            </div>


            <div className="rectangle-array">
                {Array.from({ length: loaderCount }).map((_, index) => (


                    <div className='loader-wrapper'>
                        <div key={index} className="rectangle-item">

                            <div className="circle-loader"></div>

                            <div className="right-container-loader">
                                <div className="top-rectangle-loader"></div>
                                <div className="bottom-rectangle-loader"></div>
                            </div>
                        </div>
                    </div>))}

            </div>
        </div>



    );
}

export default RecentChatListLoader;
