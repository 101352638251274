import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useProfileData} from "../Context/ProfileDataContext";
const ReferralModal = ({isOpen, onClose, children, purchase, coins, rewardType}) => {


    const { addCoins } = useProfileData();
    const handleRedeem = () => {
        addCoins(coins);
        onClose();
    };


    if (!isOpen) return null;



    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    🎉 Woo-hoo!
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    {purchase ? <div className='sublabel-notification'>
                        A user you referred just purchased a
                        subscription plan.
                    </div> : <div className='sublabel-notification'>
                        A user just joined with your referral
                        link.
                    </div>}

                    <div className='sublabel-notification'>
                        You have earned <span

                        className="colored-purple-text">{coins} coins!</span>
                    </div>

                </div>


                <button onClick={handleRedeem} className="notification-button-main">Redeem</button>


            </div>
        </div>
    );
};

export default ReferralModal;