import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import {ReactComponent as StackCoins} from '../../../icons/stack-o-coins.svg';
import {ReactComponent as MoreImagesIcon} from '../../../icons/MoreImagesIcon.svg';
import {getUserAccessToken, getUserID} from "../../../App";
const ProductModal = ({
                          isOpen,
                          onClose,
    setRewardCoins,
    setBonusImages


                      }) => {


    const [selectedOption, setSelectedOption] = useState(1);
    const [totalCoins, setTotalCoins] = useState(0);
    const [showFailedMessage, setShowFailedMessage] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        switch (selectedOption) {
            // Adjust the switch cases as needed for your logic
            case 1:
                setTotalCoins(50);
                break;
            case 5:
                setTotalCoins(200);
                break;
            case 25:
                setTotalCoins(800);
                break;
            default:
                setTotalCoins(0);
        }
    }, [selectedOption]);



    const handlePurchase = async () => {
        try {
            const accessToken = await getUserAccessToken();
            const userId = await getUserID();
            const response = await fetch('https://api.elyza.app/v1/referral-purchase', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                },
                body: JSON.stringify({
                    userId: userId,
                    packageType: 'images',
                    packageCount: selectedOption // Adjust this based on your API's expectations
                }),
            });

            if (response.ok) {
                // console.log('Purchase successful!');
                setBonusImages(prevImages => Number(prevImages) + selectedOption); // Add the purchased images
                setRewardCoins(prevCoins => prevCoins - totalCoins);
                onClose(); // Close the modal upon successful purchase
            } else {
                console.error('Purchase failed');
                    setShowFailedMessage(true); // Show the FailedToBuyModal when purchase fails

            }
        } catch (error) {
            console.error('Error making purchase:', error);
            setShowFailedMessage(true);
        }
    };

    const handleClose = () => {
        setShowFailedMessage(false); // Close the modal
        if (onClose) {
            onClose(); // Call any additional onClose logic passed as a prop
        }
    };

    if (!isOpen) return null;
    const modalContent = (

        <div className="main-space-finder-modal" onClick={onClose}>

            <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>
                {showFailedMessage && (
                    <div className="modal-backdrop">
                        <div className="modal-content-notification">
                            <div className='notification-lable'>
                                ✋ Uh oh!
                                <div className="modal-close-icon-notification" onClick={handleClose}>
                                    <CloseIcon/>
                                </div>
                            </div>

                            <div className='sublabel-notification'>
                                Seems that your balance is too low! <br></br>
                                <div
                                    style={{marginTop: '20px'}}
                                    className="sublabel-notification"> You can earn more coins by inviting friends or completing tasks.
                                </div>
                            </div>


                            <button onClick={handleClose} className="notification-button-main">Okay

                            </button>


                        </div>
                    </div>
                )}
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">Verify your purchase!</div>
                    <div className="close-modal-icon" onClick={onClose}><CloseIcon/></div>
                </div>

                <div className="line-container">
                    <span className="text-line-container">PRODUCT</span>
                    <div className="line"></div>
                </div>
                <div className="profile-setting-sublabel">Spend your well earned Elyza coins here to purchase additional
                    features and addons.
                </div>


                <div className="parent-container-transfer">
                    <span className="coins-transfer-container">{totalCoins}<StackCoins/></span>
                    <span className="coins-transfer-container">=</span>
                    <span className="coins-transfer-container">{selectedOption}<MoreImagesIcon/></span>
                </div>


                <div className="line-container">
                    <span className="text-line-container">AMOUNT</span>
                    <div className="line"></div>
                </div>
                <div className="profile-setting-sublabel">Here you can select the amount of images you want to buy.
                </div>


                <div className="product-option-row">
                    <div className={`product-container-option ${selectedOption === 1 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(1)}>
                        <span className="product-option-insides">1
                        <MoreImagesIcon/>
                        </span>
                    </div>
                    <div className={`product-container-option ${selectedOption === 5 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(5)}>
                        <span className="product-option-insides">5
                        <MoreImagesIcon/>
                        </span>
                    </div>
                    <div className={`product-container-option ${selectedOption === 25 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(25)}>
                        <span className="product-option-insides">25
                        <MoreImagesIcon/>
                        </span>
                    </div>
                </div>


                <div className="line-container">
                    <span className="text-line-container">TOTAL</span>
                    <div className="line"></div>
                </div>


                <div className="parent">
                    <div className='total-cost-container'>
                        <span className="total-coins">Total cost: </span>
                        <span className='highlight-text-price'>{totalCoins}</span>
                        <StackCoins/>
                    </div>
                    <button className="redeem-button" onClick={handlePurchase}>Purchase</button>
                </div>


            </div>

        </div>
    );

    return ReactDOM.createPortal(modalContent, document.body);
};

export default ProductModal;