import React, { useState } from 'react';
import './CreateCharacter.css'; // Ensure this CSS file contains styles for the new layout
import FemaleImage from "./icons/female.webp";
import MaleImage from "./icons/male.webp";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CharacterStep2 = () => {
    const [selectedGender, setSelectedGender] = useState(() => {
        const savedGender = localStorage.getItem('selectedGender');
        return savedGender ? savedGender : null;
    });

    // Map gender to the corresponding image
    const genderToImageMap = {
        'Male': MaleImage,
        'Female': FemaleImage,
        // Add 'Non-binary' if there's a specific image for it
    };

    const handleGenderSelect = (gender) => {
        setSelectedGender(gender);
        localStorage.setItem('selectedGender', gender);
    };

    return (
        <div className="gender-selection-container">
            <div className="char-created-label-3">What gender will this character be?</div>
            <span className="profile-setting-sublabel">
                If the character is non-binary, you can select the 'Non-binary' option.
            </span>

            <div className="gender-options-container">
                {/* Loop through gender options to simplify rendering */}


                {Object.entries(genderToImageMap).map(([gender, imageSrc]) => (

                    <div
                        key={gender}
                        className={`gender-option ${selectedGender === gender ? 'gender-option-selected' : ''}`}
                        onClick={() => handleGenderSelect(gender)}
                    >


                        <LazyLoadImage
                            src={imageSrc}
                            alt={gender}
                            className={`gender-image ${selectedGender === gender ? 'selected' : ''}`}
                            effect="blur"

                            style={{pointerEvents: 'none'}}
                        />

                        <span className='enforced-display-char-creator'>{gender}</span>
                    </div>
                ))}
            </div>


            <div
                className={`non-binary-option ${selectedGender === 'Non-binary' ? 'non-binary-option-selected' : ''}`}
                onClick={() => handleGenderSelect('Non-binary')}
            >
                <span>Non-binary</span>
            </div>

        </div>
    );
};

export default CharacterStep2;
