import React, { useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';
import './CarouselPage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
    { id: 1, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/2.png' },
    { id: 2, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/6.png' },
    { id: 3, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/8.png' },
    { id: 4, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/7.png' },
    { id: 5, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/9.png'},
    { id: 6, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/10.png'},
    { id: 7, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/1.png'},
    { id: 8, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/11.png'},
    { id: 9, src: 'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/car1.webp'},
    { id: 10, src:'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/car2.webp'},
    { id: 11, src:'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/car3.webp'},
    { id: 12, src:'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/car4.webp'},
    { id: 13, src:'https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/car5.webp'},


];
const CarouselItem = ({ image, index, currentIndex }) => (
    <div key={image.id} className="carousel-slide">
        <img
            src={`${image.src}`}
            alt={`${index + 1}`}
            className={`carousel-image ${image.size}-carousel-image${(index === currentIndex) ? ' active-image' : ''}`}
        />
    </div>
);

const CarouselPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const callChange = useCallback((oldIndex, newIndex) => {
        // if (settings.slidesToShow === 5) {
        //     setCurrentIndex(((newIndex + 2) + images.length) % images.length);
        // } else {
        //     setCurrentIndex((newIndex + 1) % images.length);
        // }
        setCurrentIndex(newIndex);
    }, []);


    const settings = {
        beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex),
        dots: true,
        centerMode: true,
        centerPadding: "20px",
        arrows: false,
        draggable: true,
        pauseOnDotsHover: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex+2),
                    //fix the position of the carousel
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex+1),
                }
            },
            {
                breakpoint: 680,
                settings: {
                    swipeToSlide: true,
                    slidesToShow: 1,
                    dots: false,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex),
                }
            }
        ]
    };

useEffect(() => {
    // Preload images to prevent any flickering or missing images
    images.forEach(image => {
        new Image().src = image.src; // Preloading images
    });
    callChange(0, 0);
}, [callChange]);


return (
    <div className="carousel-background-container">
        <div className="carousel-container">
            <div className="carousel-title">
                <span className="upper-text">Endless options.</span>
                <span className="lower-text">Find your AI soulmate.</span>
                <span className="carousel-subtitle-2">She's right here, ready for a chat.  </span>
                <span className="carousel-subtitle-2">What are you waiting for? </span>
            </div>

            <Slider {...settings}>
                {images.map((image, index) => (
                    <CarouselItem key={image.id} image={image} index={index} currentIndex={currentIndex} />
                ))}

            </Slider>
        </div>
    </div>
);
};

export default CarouselPage;