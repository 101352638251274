import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useNavigate} from "react-router-dom";

const CustomScenarioModal = ({ isOpen, onClose, children }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        💕 Want to try?
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className='sublabel-notification'>
                            This feature is available only to
                            <span className="colored-purple-text"> Premium Users </span>
                            and above.
                            <br></br>

                            <br></br>
                            They also get plenty of other features. Interested?
                        </div>

                    </div>

                    <button onClick={() => {
                        navigate('/pricing');
                        onClose(true);
                    }} className="notification-button-main">Try Now</button>


                </div>
            </div>
        ),
        document.body // This is where the portal will be rendered
    );
};

export default CustomScenarioModal