import React, {useEffect, useState} from 'react';
import './CreateCharacter.css';
import {ReactComponent as Generate} from "./icons/generate.svg";
import {getUserID} from "../../App";
import Tooltip from '@mui/material/Tooltip';
import UpgradeNotification from "../Notifications/UpgradeNotification";
import {useNavigate} from "react-router-dom";

const CharacterStep4 = ({generateAiEntry, subscriptionError}) => {
    const [contentType, setContentType] = useState(() => localStorage.getItem('contentType') || 'NSFW');
    const [visibility, setVisibility] = useState(() => localStorage.getItem('visibility') || 'Private');
    const [characterName, setCharacterName] = useState(() => localStorage.getItem('characterName') || '');
    const [shortDescription, setShortDescription] = useState(() => localStorage.getItem('shortDescription') || '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [showUpgradeNotification, setShowUpgradeNotification] = useState(false); // State to control UpgradeNotification visibility

    useEffect(() => {
        localStorage.setItem('contentType', contentType);
        localStorage.setItem('visibility', visibility);
        localStorage.setItem('characterName', characterName);
        localStorage.setItem('shortDescription', shortDescription);
    }, [contentType, visibility, characterName, shortDescription]);

    const handleCharacterNameChange = (e) => {
        setCharacterName(e.target.value);
    };

    const handleShortDescriptionChange = (e) => {
        setShortDescription(e.target.value);
    };


    const handleContentTypeClick = (option) => {
        setContentType(option);
    };

    // Function to handle visibility option click
    const handleVisibilityClick = (option) => {
        setVisibility(option);
    };
    const handleGenerateClick = async () => {
        if (subscriptionError) {
            setShowUpgradeNotification(true); // Show upgrade notification on subscription error
            setIsLoading(false);
            return;
        }
        setShortDescription("");
        setIsLoading(true);
        setError(null);
        try {
            const userId = await getUserID(); // This should be dynamically set
            const type = "description"; // As specified for this use case
            const currentVersion = ""; // Example version, adjust as needed
            const backgroundInformation = 'character name is' + characterName; // Or however you define this
            const fandomLink = ""; // Adjust based on your requirements
            const generatedDescription = await generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink);
            setShortDescription(generatedDescription); // Update state with the generated description
        } catch (error) {
            console.error("Failed to generate AI entry:", error);
            setError("Failed to generate description. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };


    const [rows, setRows] = useState(window.innerWidth > 680 ? 4 : 6);

    useEffect(() => {
        function handleResize() {
            setRows(window.innerWidth > 680 ? 4 : 5);
        }

        window.addEventListener('resize', handleResize);
        // Call handleResize immediately to set the initial value based on the current viewport width
        handleResize();

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const navigate = useNavigate();

    return (<div className="gender-selection-container">
        <div className="char-created-label-3">Just a few short questions.</div>
        <span className="profile-setting-sublabel">
                These will help us create the base for your dream character.
            </span>


        <div className="textbox-name-general">
            <Tooltip title="25 characters max" placement="top" arrow>
                <label className="label-sign-up-h">Character Name</label>
            </Tooltip>

            <input
                type="text"
                placeholder="Public Name"
                value={characterName}
                onChange={handleCharacterNameChange}
                maxLength="25"  // Set the maximum number of characters to 20
            />
        </div>

        <div className="textbox-generate-button-general">
            <div className="textbox-name-general-short">

                <Tooltip title="250 characters max" placement="top" arrow>
                    <label htmlFor="shortDescription" className="label-sign-up-h">Short Description (2-3
                        sentences)</label>
                </Tooltip>

                <textarea
                    id="shortDescription"
                    placeholder="Tip: You can refer to character as {{char}} and to user as {{user}}."
                    rows={rows}
                    maxLength="250"
                    value={shortDescription}
                    onChange={handleShortDescriptionChange}
                />
            </div>
            <Tooltip title="Generate With AI">
                <button
                    className={`generate-w-ai-button ${isLoading ? 'loading' : ''}`}
                    onClick={handleGenerateClick}
                    disabled={isLoading}
                >
                    Generate <Generate/>
                </button>
            </Tooltip>
            {error && <p className="error">{error}</p>}
        </div>

        <div className="material-input-container-head">
            <div className="material-input-container-quiz">
                <div
                    className={`center-component-option-quiz ${contentType === 'NSFW' ? 'character-creation-option-selected' : ''}`}
                    onClick={() => handleContentTypeClick('NSFW')}
                >
                    <span
                        className={`inside-container-label-quiz ${contentType === 'NSFW' ? 'character-creation-option-selected' : ''}`}>Make it NSFW.</span>
                    <span
                        className={`inside-container-sublabel-quiz ${contentType === 'NSFW' ? 'inside-container-sublabel-selected' : ''}`}>I want it to be sexual and exciting.</span>
                </div>

                <div
                    className={`center-component-option-quiz ${contentType === 'SFW' ? 'character-creation-option-selected' : ''}`}
                    onClick={() => handleContentTypeClick('SFW')}
                >
                    <span
                        className={`inside-container-label-quiz ${contentType === 'SFW' ? 'character-creation-option-selected' : ''}`}>Make it SFW.</span>
                    <span
                        className={`inside-container-sublabel-quiz ${contentType === 'SFW' ? 'inside-container-sublabel-selected' : ''}`}>I want it to be friendly and helpful.</span>
                </div>
            </div>

            <div className="material-input-container-quiz">


                <div
                    className={`center-component-option-quiz ${visibility === 'Private' ? 'character-creation-option-selected' : ''}`}
                    onClick={() => handleVisibilityClick('Private')}
                >
                    <span
                        className={`inside-container-label-quiz ${visibility === 'Private' ? 'character-creation-option-selected' : ''}`}>Make it private.</span>
                    <span
                        className={`inside-container-sublabel-quiz ${visibility === 'Private' ? 'inside-container-sublabel-selected' : ''}`}>I want it for only for myself.</span>
                </div>

                <div
                    className={`center-component-option-quiz ${visibility === 'Public' ? 'character-creation-option-selected' : ''}`}
                    onClick={() => handleVisibilityClick('Public')}
                >
                    <span
                        className={`inside-container-label-quiz ${visibility === '{Public' ? 'character-creation-option-selected' : ''}`}>Make it public.</span>
                    <span
                        className={`inside-container-sublabel-quiz ${visibility === 'Public' ? 'inside-container-sublabel-selected' : ''}`}>Everyone can use this character.</span>
                </div>

            </div>
        </div>
        {showUpgradeNotification && (
            <UpgradeNotification isOpen={showUpgradeNotification} onClose={(confirm) => {
                setShowUpgradeNotification(false)
                if (confirm) {
                    navigate('/pricing');
                }
            }}/>
        )}
    </div>);
};

export default CharacterStep4;
