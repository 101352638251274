import {getUserAccessToken, getUserID} from "../../../App";

export async function editMemoryFragment(userId, identityName, memoryId, name, description) {
    const response = await fetch('https://api.elyza.app/v1/edit-memory-fragment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, identityName, memoryId, name, description })
    });
    return response.json();
}




export async function editPersonality(userId, identityName, personalityId, name, description) {

    const response = await fetch('https://api.elyza.app/v1/edit-personality', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, personalityId, name, description })
    });
    return response.json();
}



export async function addPersonalityProfile(userId, name, description, select) {

    const response = await fetch('https://api.elyza.app/v1/add-personality', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, name, description, select })
    });
    return response.json();
}

export async function addMemoryFragment(userId, identityName, name, description, select) {

    const response = await fetch('https://api.elyza.app/v1/add-memory-fragment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, identityName, name, description, select })
    });
    return response.json();
}

export async function getUpdatedMemoriesAndPersonalities(userId, identityName) {

    if(identityName.length === 0) {
        return;
    }

    if (userId === null || userId === undefined || userId.length === 0) {
        userId = await getUserID()
    }

    try {
        const response = await fetch('https://api.elyza.app/v1/fetch-memories-and-personalities', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({ userId, identityName })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching memories and personalities:", error);
        throw error;
    }
}

export async function deleteMemoryFragment(userId, identityName, memoryId) {
    const response = await fetch('https://api.elyza.app/v1/delete-memory-fragment', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, identityName, memoryId })
    });
    return response.json();
}


export async function deletePersonality(userId, personalityId) {
    const response = await fetch('https://api.elyza.app/v1/delete-personality', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, personalityId })
    });
    return response.json();
}

export async function selectMemoryFragments(userId, identityName, selectedMemoryIds) {
    const response = await fetch('https://api.elyza.app/v1/select-memory-fragments', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, identityName, selectedMemoryIds })
    });
    return response.json();
}

export async function selectPersonality(userId, selectedPersonalityId) {
    const response = await fetch('https://api.elyza.app/v1/select-personality', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ userId, selectedPersonalityId })
    });
    return response.json();
}
