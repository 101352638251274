import React, { useState, useEffect } from 'react';
import './MainPageLoader.css';
import {useTimer} from "../Context/TimerContext";

const MainPageLoader = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const cardWidth = windowWidth < 780 ? 140 : 200;
    const tagWidth = 80;
    const gap = 10;

    const [numberOfCards, setNumberOfCards] = useState(0);
    const [numberOfTags, setNumberOfTags] = useState(0);

    const updateDimensions = () => {
        const screenWidth = window.innerWidth;
        setNumberOfCards(Math.floor(screenWidth / (cardWidth + gap)));
        setNumberOfTags(Math.floor(screenWidth / (tagWidth + gap)));
    };

    useEffect(() => {
        updateDimensions(); // Set initial size
        window.addEventListener('resize', updateDimensions); // Add resize listener
        return () => window.removeEventListener('resize', updateDimensions);
        //eslint-disable-next-line
    }, []);



    const { timerExpired } = useTimer();



    return (
        <div className="main-page-loader"
        style={{ marginTop: !timerExpired ? '84px' : '95px' }}
        >
            <div className="loading-tags">
                {Array(numberOfTags).fill().map((_, index) => (
                    <div key={index} className="loading-tag"></div>
                ))}
            </div>

            <div id="loading-event-container"></div>
            <div className="placeholder-unify">
            <div className="placeholder-row">
                {Array(2).fill().map((_, containerIndex) => (
                    <div key={containerIndex} className="loading-character-row">
                        <div className="loading-row-header"></div>

                        <div className="loading-character-cards">
                            {Array(numberOfCards).fill().map((_, cardIndex) => (
                                <div key={cardIndex} className="loading-card"></div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="placeholder-row">
                {Array(2).fill().map((_, containerIndex) => (
                    <div key={containerIndex} className="loading-character-row">
                        <div className="loading-row-header"></div>

                        <div className="loading-character-cards">
                            {Array(numberOfCards).fill().map((_, cardIndex) => (
                                <div key={cardIndex} className="loading-card"></div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            </div>
        </div>
    );
}

export default MainPageLoader;
