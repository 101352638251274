import React, { useEffect, useState} from "react";
import {ReactComponent as CrownIcon} from "../../icons/crown.svg";
import {useTimer} from "../Context/TimerContext";
import {useProfileData} from "../Context/ProfileDataContext";

const initializeTargetTime = () => {
    const now = Date.now();
    const savedTargetTime = localStorage.getItem('countdownTargetTime');
    if (savedTargetTime && parseInt(savedTargetTime, 10) > now) {
        return parseInt(savedTargetTime, 10);
    } else {
        const newTargetTime = now + 3600000; // 1 hour from now
        localStorage.setItem('countdownTargetTime', newTargetTime.toString());
        return newTargetTime;
    }
};







const calculateTimeLeft = (targetTime) => {
    const now = Date.now();
    const distance = targetTime - now;
    if (distance < 0) {
        return { minutes: 0, seconds: 0, milliseconds: 0, expired: true };
    } else {
        return {
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
            milliseconds: Math.floor((distance % 1000) / 10),
            expired: false
        };
    }
};





const CountdownTimer = ({ onExpire }) => {
    const [frameId, setFrameId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(() => {
        const targetTime = initializeTargetTime(); // Ensure this gets the target time correctly from localStorage
        return calculateTimeLeft(targetTime);
    });

    const updateTimer = () => {
        const newTimeLeft = calculateTimeLeft(initializeTargetTime());
        setTimeLeft(newTimeLeft);

        if (!newTimeLeft.expired) {
            setFrameId(requestAnimationFrame(updateTimer));
        } else {
            onExpire();

        }
    };
    useEffect(() => {
        if (!timeLeft.expired) {
            setFrameId(requestAnimationFrame(updateTimer));
        }

        return () => {
            if (frameId) {
                cancelAnimationFrame(frameId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    const timerStyle = {display: 'flex', alignItems: 'baseline'};
    const timerSegmentStyle = {display: 'flex', flexDirection: 'column', alignItems: 'flex-start'};
    const numberStyle = {fontFamily: 'Monospace'};
    const textStyle = {fontFamily: 'Inter'};




    return (
        <div style={timerStyle}>
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.minutes).padStart(2, '0')}</span>
                <small style={textStyle}>Min</small>
            </div>
            <span style={{marginRight: '6px'}}>:</span>
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.seconds).padStart(2, '0')}</span>
                <small style={textStyle}>Sec</small>
            </div>
            <span style={{marginRight: '6px'}}>:</span>
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.milliseconds).padStart(2, '0')}</span>
                <small style={textStyle}>Ms</small>
            </div>
        </div>
    );
};


const TopTimer = ({ onTimerActive }) => {


    const { timerExpired, setTimerExpired } = useTimer();






    useEffect(() => {
        if (typeof onTimerActive === 'function') {
            onTimerActive(!timerExpired);
        }
    }, [timerExpired, onTimerActive]);

    const handleExpire = () => {

        localStorage.removeItem('countdownTargetTime'); // Clean up expired timer.

    };

    let oneHourInMs = 3600000;

    const { profileData } = useProfileData();


    useEffect(() => {
        // Check if the user has visited the /pricing page
        const visitedPricing = localStorage.getItem('visitedPricing') === 'true';


        if (!visitedPricing) {
            setTimerExpired(true);
        } else {
            // If they have visited the pricing page, determine the timer status based on the plan
            if (profileData.subscription_data.tier !== "FREE") {
                setTimerExpired(true);
            } else {
                setTimerExpired(false);
            }
        }
    }, [profileData.subscription_data.tier, setTimerExpired]);









    return (
        <div className='top-timer-container-chat'
             onClick={() => {
                 if (window.location.pathname !== '/pricing') {
                     window.location.href = '/pricing';
                 }
             }}>

            <div className="top-timer-text">
                <CrownIcon/>
                FIRST SUBSCRIPTION
            </div>
            <div className="top-timer-padding">Up to 60% Off!</div>


            {!timerExpired && (
                <div className="top-timer-timer-general">
                    <div className="top-timer-timer">
                        <CountdownTimer onExpire={handleExpire} target={oneHourInMs}/>
                    </div>
                </div>
            )}
        </div>

    );
};


export default TopTimer;