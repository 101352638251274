import React, {useEffect, useState} from "react";
import "./ImageGenerationPage.css";
import Tooltip from "@mui/material/Tooltip";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {getUserAccessToken, getUserID} from "../App";
import {useNavigate} from "react-router-dom";
import {ReactComponent as ArrowBack} from "../icons/narrowArrowBack.svg";
import {ReactComponent as CreateImageGallery} from "../icons/createImageGallery.svg";
import {ReactComponent as ForwardArrow} from "../icons/create-narrow-arrow.svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {TagSystemChat} from "../components/ChatComponents/TagSystemChat";
import {ReactComponent as ThumbsUpIcon} from "../icons/thumbs-up.svg";
import {ReactComponent as ThumbsDownIcon} from "../icons/thumbs-down.svg";
import ReportDislikeButton from "../components/ChatComponents/ReportDislikeButton";
import {ReactComponent as CloseIcon} from "../icons/cross.svg";
import UpgradeNotification from "../components/Notifications/UpgradeNotification";
import {useProfileData} from "../components/Context/ProfileDataContext";
// import {BeatLoader} from "react-spinners";

export default function ImageGenerationPage() {
    const navigate = useNavigate();
    const [images, setImages] = useState([]); // State to store images
    const [displayName, setDisplayName] = useState(null); // Add this line
    const [profileBackground, setProfileBackground] = useState(null); // Add this line
    const [imageUrl, setImageUrl] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const {profileData} = useProfileData();

    const toggleBodyScroll = (isModalOpen) => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    };

    const openModal = (image) => {
        setSelectedImage(image);
        setModalOpen(true);
        toggleBodyScroll(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        toggleBodyScroll(false);
    };

    const handleBackToChat = () => {
        const currentPath = window.location.pathname;
        const characterId = currentPath.substring(currentPath.lastIndexOf('/') + 1);
        navigate(`/chat/${characterId}`);
    };

    useEffect(() => {
        async function fetchData() {
            const characterId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            const userId = await getUserID();
            const accessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/character-gallery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                body: JSON.stringify({
                    userId: userId,
                    identityName: characterId
                })
            });

            if (response.ok) {
                const data = await response.json();
                setImages(data.images.filter(image => image.like_status !== 2 && image.like_status !== 3));
                setDisplayName(data.extra.display_name);
                setProfileBackground(data.extra.profile);

            } else {
                console.error('Failed to fetch images:', response.statusText);

            }
        }

        fetchData().catch(console.error); // Handle errors in async operations
    }, []);

    const [positivePrompt, setPositivePrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');

    const handlePositivePromptChange = event => {
        setPositivePrompt(event.target.value);
    };

    const handleNegativePromptChange = event => {
        setNegativePrompt(event.target.value);
    };

    const [isImageGenerating, setIsImageGenerating] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);

    const handleCreateClick = async () => {
        if (profileData.subscription_data.tier === 'FREE') {
            setSubscriptionError(true);
            return;
        }

        setIsImageGenerating(true);  // Start loading
        const characterId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        const userId = await getUserID();

        let requestBody;

        if (localStorage.getItem(`imageModel`) === 'new') {
            requestBody = {
                userId: userId,
                identityName: characterId,
                userMadePrompt: positivePrompt,
            };
        } else {
            requestBody = {
                userId: userId,
                identityName: characterId,
                positivePrompt: positivePrompt,
                negativePrompt: negativePrompt,
                loraName: tag,
            };
        }

        let isCivitDead = true;
        const endpoint = isCivitDead ? 'https://api.elyza.app/v1/image-generation-gallery-fb' : 'https://api.elyza.app/v1/updated-gallery-image';

        const response = await fetch(endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify(requestBody)
            });
        if (response.ok) {
            const data = await response.json();
            setImageUrl(data.url);

            const copy = [...images];
            copy.unshift({image_url: data.url, image_id: data.image_id, like_status: 0});
            setImages(copy);
        } else {
            console.error('Failed to generate image:', response.statusText);
        }
        setIsImageGenerating(false);  // Stop loading
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const reportNegativeImageQuality = async (imageUrl, issueReport) => {
        let index = 0;
        for (const image of images) {
            if (image.image_url === imageUrl) {
                break;
            }
            index++;
        }
        const copy = [...images];
        copy[index].like_status = 2;
        setImages(copy.filter(image => image.like_status !== 2 && image.like_status !== 3));

        setReportModalOpen(false);

        await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(), imageUrl, isPositive: false, issueReport,
            })
        });
    }

    const reportPositiveImageQuality = async (imageUrl) => {
        await fetch('https://api.elyza.app/v1/report-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(), imageUrl, isPositive: true, issueReport: '',
            })
        });
    }

    const handleLikeClick = (e, index, likeStatus) => {
        if (likeStatus !== 0) {
            return;
        }

        // TODO: FORM WITH REPORT MESSAGE
        e.stopPropagation();

        const copy = [...images];
        copy[index].like_status = 1;
        setImages(copy);
        // setLikeStatus(1);


        const url = images[index].image_url;
        reportPositiveImageQuality(url).then(() => {
        });
    };

    const [isReportModalOpen, setReportModalOpen] = useState(false);

    const openReportModal = () => {
        setReportModalOpen(true);
    };

    const [reportFormMessage, setReportFormMessage] = useState('');

    const handleShortDescriptionChange = (e) => {
        setReportFormMessage(e.target.value);
    }

    const [reportedNegativeImage, setReportedNegativeImage] = useState('');

    const handleDislikeClick = (e, index, likeStatus) => {
        if (likeStatus !== 0) {
            return;
        }

        e.stopPropagation();

        setReportedNegativeImage(images[index].image_url);

        openReportModal();
    };

    const [tag, setTag] = useState('');
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!isImageGenerating) {
            setProgress(0); // Reset progress when stopping image generation
            return;
        }

        setProgress(0); // Reset progress when starting a new loading
        const totalDuration = 10000; // Total duration for fake loading
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, totalDuration / 100); // Update progress every 100 ms

        return () => {
            clearInterval(interval);
            setProgress(0); // Ensure progress is reset when exiting
        };
    }, [isImageGenerating]);

    return (
        <>
            <HeaderMainMenu/>
            {subscriptionError && <UpgradeNotification isOpen={subscriptionError} onClose={(confirm) => {
                setSubscriptionError(false);
                if (confirm) {
                    navigate('/pricing');
                }
            }}/>}

            <div className="image-generation-page">
                <div className="image-generation-container-left">
                    <div className="normalizer-of-size-gallery">
                        {windowWidth >= 720 && (
                            <button className="back-character" onClick={handleBackToChat}>
                                <ArrowBack/>
                                Back to Character
                            </button>
                        )
                        }
                        <div className="prompt-generate-box"
                             style={profileBackground && !isImageGenerating && imageUrl === null ? {backgroundImage: `url(${profileBackground})`,} : {}}
                        >
                            {isImageGenerating ? (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(21,21,21,0.96)', // Dark overlay
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '14px'
                                }}>
                                    <div className="loading-image-container-gallery">

                                        <div className="prompt-generate-loader-icon">
                                            <svg width="100" height="100" viewBox="0 0 100 100">
                                                <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none"
                                                        stroke="#242424"/>
                                                <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none"
                                                        stroke="#916DE8"
                                                        strokeDasharray={Math.PI * 2 * 45}
                                                        strokeDashoffset={Math.PI * 2 * 45 * ((100 - progress) / 100)}
                                                        style={{transition: 'stroke-dashoffset 0.3s linear'}}
                                                />
                                                <text x="50" y="55" textAnchor="middle" fill="#916DE8"
                                                      className="loading-text">{`${progress}%`}</text>
                                            </svg>
                                        </div>
                                        <div className="loading-image-text">
                                            Please Wait!
                                        </div>

                                        <div className="loading-image-text-2">
                                            Your image is being generated.
                                        </div>

                                    </div>
                                </div>
                            ) : imageUrl ? (
                                <img
                                    src={`https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/${imageUrl}`}
                                    alt=""
                                    style={{width: '100%', height: 'auto', borderRadius: '14px'}}/>
                            ) : (
                                <div className="prompt-generate-box-general">
                                    <div className="gallery-content-create">
                                        <CreateImageGallery/>
                                        Enter a prompt and click create
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="image-generation-container-right">
                    <span className='char-name-header-gallery'>
                Let's create a picture of {displayName}
                </span>
                        {windowWidth < 720 && (
                            <div className="mobile-back-char">
                                <button className="back-character" onClick={handleBackToChat}>
                                    <ArrowBack/>
                                    Back to Character
                                </button>
                            </div>
                        )}
                        <div className="image-gen-right-background">
                            <div className="main-prompt-container">
                                <div>

                                    <div className="textbox-generate-button-general">
                                        <div className="textbox-prompt-gallery-general"
                                             style={windowWidth < 980 ? {width: '100%'} : {}}
                                        >
                                            <Tooltip title="1500 characters max" placement="top" arrow>
                                                <label htmlFor="dialogueDescription" className="label-sign-up-h"
                                                       style={windowWidth < 980 ? {whiteSpace: 'wrap'} : {}}
                                                >What do you want to see?</label>
                                            </Tooltip>
                                            <textarea
                                                id="positivePrompt"
                                                value={positivePrompt}
                                                onChange={handlePositivePromptChange}
                                                rows="2"
                                                maxLength="1500"
                                                cols="50"
                                                placeholder="wearing a cute top, smirking at the camera."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="textbox-generate-button-general">
                                        <div className="textbox-prompt-gallery-general"
                                             style={windowWidth < 980 ? {width: '100%'} : {}}
                                        >
                                            <Tooltip title="1500 characters max" placement="top" arrow>
                                                <label htmlFor="dialogueDescription" className="label-sign-up-h"
                                                       style={windowWidth < 980 ? {whiteSpace: 'wrap'} : {}}
                                                >Anything you
                                                    don't want to see? (optional)</label>
                                            </Tooltip>

                                            <textarea
                                                id="negativePrompt"
                                                value={negativePrompt}
                                                onChange={handleNegativePromptChange}
                                                rows="2"
                                                maxLength="1500"
                                                cols="50"
                                                placeholder="distorted, ugly, blurry"

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {localStorage.getItem(`imageModel`) === 'old' && (
                                <div className="pose-selection-general-header">
                                    <span className="character-pose-text"> What pose should the character be in?</span>
                                    <TagSystemChat setTag={setTag}/>
                                </div>
                            )}

                            <div className="create-image-button-width">
                                <button
                                    className="click-to-generate-button"
                                    onClick={handleCreateClick}
                                    disabled={isImageGenerating}
                                    style={isImageGenerating ? {background: 'gray'} : {}}
                                >
                                    Create
                                    <ForwardArrow/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {images.filter(image => image.like_status !== 2 && image.like_status !== 3).length > 0 ? (

                <div className="header-text-gallery-container">
                    <span className="header-text-gallery">Pictures of {displayName} you created</span>
                </div>
            ) : null}

            <div className={`bottom-of-page-gallery ${images.length <= 2 ? "few-images" : "many-images"}`}>

                {images.filter(image => image.like_status !== 2 && image.like_status !== 3).length > 0 ? (

                    <div className="gallery-container-collection">
                        {images.filter(image => image.like_status !== 2 && image.like_status !== 3).map((image, index) => (
                            <div className="image-liked-gallery-container" key={index} onClick={() => openModal(image)}>
                                <LazyLoadImage
                                    src={image.image_url ? `https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${image.image_url}` : ""}
                                    alt="Displayed image"
                                    className="image-liked-gallery"
                                    loading="lazy"
                                />
                                <div className="like-dislike-buttons-gallery">
                                    {image.like_status !== 2 && (
                                        <div
                                            className={`like-icon-gallery ${image.like_status === 1 ? 'icon-gallery-like-selected' : ''}`}
                                            onClick={(e) => handleLikeClick(e, index, image.like_status)}>
                                            <ThumbsUpIcon/>
                                        </div>
                                    )}

                                    {image.like_status !== 1 && (
                                        <div
                                            className={`dislike-icon-gallery ${image.like_status === 2 ? 'icon-gallery-like-selected' : ''}`}
                                            onClick={(e) => handleDislikeClick(e, index, image.like_status)}>
                                            <ThumbsDownIcon/>
                                        </div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

            </div>

            <ReportDislikeButton
                isOpen={isReportModalOpen}
                onCancel={() => setReportModalOpen(false)}
                imageUrl={reportedNegativeImage}
                reportNegativeImage={reportNegativeImageQuality}
                shortDescription={reportFormMessage}
                setShortDescription={setReportFormMessage}
                handleShortDescriptionChange={handleShortDescriptionChange}
            />

            {isModalOpen && (
                <div className="image-modal">

                    <div className="image-modal-content">
                        <CloseIcon className="modal-close-button" onClick={closeModal}/>
                        <img
                            src={selectedImage.image_url ? `https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${selectedImage.image_url}` : ""}
                            alt="Enlarged view" className="modal-image"/>
                    </div>

                    <div className="modal-backdrop" onClick={closeModal} style={{backgroundColor: 'rgba(0, 0, 0, 0.9)'}}></div>

                </div>
            )}


        </>
    );

}