import React, { useState, useEffect } from 'react';
import './ResetPassword.css';
import { ReactComponent as CloseModalIcon } from '../../icons/cross.svg';
// Adjust the import path to where your Supabase client is initialized
import { supabase } from '../../App';

const ResetPasswordModal = ({ show, onHide }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [timer, setTimer] = useState(0);
    const [emailError, setEmailError] = useState(false);


    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            } else {
                clearInterval(interval);
                localStorage.removeItem('resetTimer'); // Ensure to clean up expired timer
            }
        }, 1000);

        // Check if there's a timer in local storage on mount
        checkTimer();

        return () => clearInterval(interval);
    }, [timer]);

    const handleSendResetLink = async () => {
        if (!email.trim()) {
            setEmailError(true);
            setTimeout(() => setEmailError(false), 1000);
            return;
        }

        setLoading(true);
        setMessage('');

        const endTime = new Date().getTime() + 60000; // 60 seconds from now
        localStorage.setItem('resetTimer', endTime.toString());
        checkTimer(); // Start or continue the timer based on endTime

        try {
            //eslint-disable-next-line
            const { data, error } = await supabase.auth.resetPasswordForEmail(email);
            if (error) throw error;
            setMessage('Please check your email for the reset link.');
            setEmail('');
        } catch (error) {
            console.error('Error sending reset link:', error.message);
            setMessage(error.error_description || error.message);
        } finally {
            setLoading(false);
        }
    };
    const checkTimer = () => {
        const endTime = parseInt(localStorage.getItem('resetTimer'), 10);
        const now = new Date().getTime();
        const timeLeft = endTime - now;

        if (timeLeft > 0) {
            setTimer(Math.ceil(timeLeft / 1000));
        } else {
            localStorage.removeItem('resetTimer'); // Clear expired timer
            setTimer(0); // Ensure timer is reset in the component
        }
    };



    if (!show) {
        return null;
    }

    return (
        <div className="reset-password-modal">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="main-elyza-text-label-reset">Reset Password
                        <button onClick={onHide} className="close-modal-icon-reset">
                            <CloseModalIcon/>
                        </button></h5>
                    <span className="main-elyza-text-sublabel">We’ve all been there. No worries though,
                    we’ve got you covered.</span>
                </div>

                <div className="a-straight-line"></div>

                <div className="textbox-email" style={{margin: '0'}}>
                    <label className="text-field-header">Email</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="support@elyza.app"
                        className={`textbox-emailinput ${emailError ? 'shake-animation' : ''}`}
                        disabled={loading || timer > 0}
                    />


                    {timer > 0 && (
                        <p className="main-elyza-text-sublabel">If an account with that email exists on Elyza, a
                            password reset email should be sent shortly.</p>
                    )}
                    {message && <p>{message}</p>}
                </div>

                <div className="modal-footer">
                    <button
                        onClick={handleSendResetLink}
                        className={`reset-password-main-button ${loading || timer > 0 ? 'reset-password-button-loading' : ''}`}
                        disabled={loading || timer > 0}
                    >
                        {loading ? 'Sending...' : timer > 0 ? `Please wait ${timer}s` : 'Send Reset Link'}
                    </button>

                </div>
                <div className="a-straight-line"></div>
                <button onClick={onHide} className="popup-link">
                    Remember your password?
                    <span className="underline">Go Back</span>
                </button>
            </div>

            <div className="modal-backdrop" onClick={onHide}></div>
        </div>
    );
};

export default ResetPasswordModal;
