import React from 'react';
import './SalesHero.css';
// import { ReactComponent as ChatIcon } from "./icons/chat.svg";
// import { ReactComponent as NoAdultContentIcon } from "./icons/no-adult-content.svg";
// import { ReactComponent as MemoryIcon } from "./icons/memory.svg";
// import { ReactComponent as ImageIcon } from "./icons/image.svg";
// import JoinBetaHero from "./JoinBetaHero";
import {ReactComponent as TryUsNow} from "./icons/TryUsNow.svg";
// import girlImage1 from "./icons/girl1.webp";
// import girlImage2 from "./icons/girl2.webp";
import {Link} from 'react-router-dom';


const SalesHero = () => {
    return (<div className="sales-hero-container">
            <div className="sales-left-container">

                <div className="sales-title">
                    <span>Your fantasies</span>
                </div>

                <div className="sales-title-2">
                    <span>are waiting.</span>
                </div>


                <h2 className="sales-subtitle">Dream aloud, she's here to listen <br></br>and lift you up.</h2>
                <Link
                    className='join-button'
                    to="/"
                    smooth={true}
                    duration={500}
                    spy={true}
                >
                    <TryUsNow/>
                    AI Girlfriend
                </Link>
            </div>
<div className='united-image-container-sales'>
            <div className="container-bottom-left">
                <img src={"https://elyza.app/cdn-cgi/image/format=avif,width=768,height=1344,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/girl1.webp"} alt="Girl 1"/>
            </div>
            <div className="container-bottom-right">
                <img src={"https://elyza.app/cdn-cgi/image/format=avif,width=768,height=1344,compression=fast/https://supabase.elyza.app/storage/v1/object/public/images/sales/girl2.webp"} alt="Girl 2"/>
            </div>
</div>
        </div>);
};

export default SalesHero;
