import React from 'react';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import {ReactComponent as StarNotification} from "../SpecialNotifications/SpecialNotificationIcon/StarNotification.svg";
import {ReactComponent as DiscordIcon} from "../SpecialNotifications/SpecialNotificationIcon/DiscordIcon.svg";

const JoinDiscordCommunity = ({ isOpen, onClose, children }) => {
    const hasJoined = () => {
        return document.cookie.split(';').some((item) => item.trim().startsWith('joinedDiscord=true'));
    };

    if (!isOpen || hasJoined()) return null;

    const handleJoinClick = () => {
        window.open('https://discord.gg/V4YUYRBc5M', '_blank');
        document.cookie = "joinedDiscord=true; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
        onClose(true);
    };


    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification-discord">
                <div className="modal-close-icon-notification"
                 style={{display: 'flex', width: 'fit-content', marginLeft: 'auto', marginRight: '0'}}
                     onClick={() => {onClose(false)}}>
                    <CloseIcon/>
                </div>
                <div className='notification-label-header-special'>
                    Join our community
                </div>

                <div className="gap-between-notification">

                    <div className='notification-label-special'>
                        on Discord
                        <DiscordIcon/>
                    </div>

                </div>

                <button onClick={handleJoinClick}
                        style={{backgroundColor: '#3450B1', height: '60px', fontSize:'24px', fontWeight: '700', maxWidth: '380px', marginLeft: 'auto', marginRight: 'auto'}}
                        className="notification-button-main">
                    <StarNotification/>
                    Join Server!
                </button>
            </div>
        </div>
    );
};

export default JoinDiscordCommunity;