import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const UpagradeNewModel = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        💕 Want to try?
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className='sublabel-notification'>
                            Premium users get
                            <span className="colored-purple-text"> unlimited access </span>
                            to all models, have
                            <span className="colored-purple-text">  increased limits </span>


                            and get up to
                            <span className="colored-purple-text"> 300 images </span>
                            every month.
                            <br></br>
                            <br></br>
                            They also get plenty of other features. Interested?
                        </div>

                    </div>

                    <button onClick={() => {onClose(true)}} className="notification-button-main">Upgrade</button>


                </div>
            </div>
        ),
        document.body // This is where the portal will be rendered
    );
};

export default UpagradeNewModel