import React, {useState, useEffect} from 'react';
import './CreateCharacter.css';
import {ReactComponent as EditIcon} from "./icons/pencil.svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import { ReactComponent as AltCard } from "../MainMenuPage/icons/altcard.svg";

const CharacterReview = ({goToStep}) => {
    // Corrected initial states
    const characterGender = useState(() => localStorage.getItem('selectedGender') || 'Unknown');
    const selectedStyle = useState(() => localStorage.getItem('selectedStyle') || 'Unknown');
    // eslint-disable-next-line
    const [shortDescription, setShortDescription] = useState(() => localStorage.getItem('shortDescription') || '');
// eslint-disable-next-line
    const [personalityDescription, setPersonalityDescription] = useState(() => localStorage.getItem('personalityDescription') || '');
    // eslint-disable-next-line
    const [appearanceDescription, setAppearanceDescription] = useState(() => localStorage.getItem('appearanceDescription') || '');
    // eslint-disable-next-line
    const [scenarioDescription, setScenarioDescription] = useState(() => localStorage.getItem('scenarioDescription') || '');
    // eslint-disable-next-line
    const [dialogueDescription, setDialogueDescription] = useState(() => localStorage.getItem('dialogueDescription') || '');
    const contentType = useState(() => localStorage.getItem('contentType') || 'Unknown');
    const visibility = useState(() => localStorage.getItem('visibility') || 'Unknown');
    const characterName = useState(() => localStorage.getItem('characterName') || 'Unknown');
    const savedImage = localStorage.getItem('characterImage');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 680);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    // Utility function to truncate text
    const truncateText = (text, limit = 150) => {
        if (text) {
            if(Array.isArray(text)) {
                text = text[0];
            }
            return text.length > limit ? text.substring(0, limit) + '...' : text;
        }
        return '';
    };



    return (<>
            {!isMobile && (<div className="review-selection-container">

                    <div className="profile-setting-label-2">Let’s review your character.</div>
                    <div className="review-selection-main-row">
                        <div className="final-option">

                                <LazyLoadImage
                                    effect="blur"
                                    src={savedImage}
                                    alt={""}
                                    className="char-final-image"
                                    style={{ pointerEvents: 'none'}}
                                    placeholderSrc={AltCard}
                                />


                        </div>

                        <div className="review-main-column">
                            <div className="review-top-row">

                                <div className="review-holder-column">
                                    <div className="review-top-header">Name<EditIcon className="click-on-me"
                                                                                     onClick={() => goToStep(3)}/></div>
                                    <div className="review-top-subheader">{truncateText(characterName, 15)}</div>
                                </div>

                                <div className="review-holder-column">
                                <div className="review-top-header">Gender<EditIcon className="click-on-me"
                                                                                       onClick={() => goToStep(1)}/>
                                    </div>
                                    <div className="review-top-subheader">{characterGender}</div>
                                </div>

                                <div className="review-holder-column">
                                    <div className="review-top-header">Style<EditIcon className="click-on-me"
                                                                                      onClick={() => goToStep(2)}/>
                                    </div>
                                    <div className="review-top-subheader">{selectedStyle}</div>
                                </div>
                                <div className="review-holder-column">
                                    <div className="review-top-header">Safety<EditIcon className="click-on-me"
                                                                                       onClick={() => goToStep(3)}/>
                                    </div>
                                    <div className="review-top-subheader">{contentType}</div>
                                </div>

                                <div className="review-holder-column">
                                    <div className="review-top-header">Type<EditIcon className="click-on-me"
                                                                                     onClick={() => goToStep(3)}/></div>
                                    <div className="review-top-subheader">{visibility}</div>
                                </div>
                            </div>
                            <div className="character-double-column">
                                <div className="review-bottom-column">
                                    <div className="review-holder-column">
                                        <div className="review-top-header">Description<EditIcon className="click-on-me"
                                                                                                onClick={() => goToStep(3)}/>
                                        </div>
                                        <div className="review-top-subheader">

                                            {truncateText(shortDescription, 50)}


                                        </div>
                                    </div>
                                    <div className="review-holder-column">
                                        <div className="review-top-header">First Message<EditIcon className="click-on-me"
                                                                                             onClick={() => goToStep(4)}/>
                                        </div>
                                        <div
                                            className="review-top-subheader">{truncateText(dialogueDescription, 50)}</div>
                                    </div>


                                </div>
                                <div className="review-bottom-column">
                                    <div className="review-holder-column">
                                        <div className="review-holder-column">
                                            <div className="review-top-header">Appearance<EditIcon
                                                className="click-on-me" onClick={() => goToStep(4)}/></div>
                                            <div
                                                className="review-top-subheader">{truncateText(appearanceDescription, 50)}</div>
                                        </div>
                                        <div className="review-holder-column">
                                            <div className="review-top-header">Personality<EditIcon
                                                className="click-on-me" onClick={() => goToStep(4)}/></div>
                                            <div
                                                className="review-top-subheader">{truncateText(personalityDescription, 50)}</div>
                                        </div>
                                        <div className="review-top-header">Scenario<EditIcon className="click-on-me"
                                                                                             onClick={() => goToStep(4)}/>
                                        </div>
                                        <div
                                            className="review-top-subheader">{truncateText(scenarioDescription, 50)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )}


            {isMobile && (<div className="review-selection-container">

                    <div className="char-created-label-3">Let’s review your character.</div>

                    <div className="review-selection-main-row">
                        <div className='review-selection-mobile-image'>
                            <div>
                        <div className="review-top-header">Name<EditIcon className="click-on-me" onClick={() => goToStep(3)}/></div>
                        <div className="review-top-subheader">{truncateText(characterName, 15)}</div>
                            </div>
                        <div className="final-option">
                            {savedImage ? (
                                <LazyLoadImage
                                    effect="blur"
                                    src={savedImage}
                                    alt={""}
                                    className="char-final-image"
                                    placeholderSrc={AltCard}
                                />
                            ) : (
                                <AltCard className="character-svg-altcard"/>
                            )}
                        </div>

                        </div>
                        <div className="review-main-column">
                            <div className="review-top-row">


                                <div className="review-holder-column">

                                    <div className="review-top-header">Gender<EditIcon className="click-on-me"
                                                                                       onClick={() => goToStep(1)}/>
                                    </div>
                                    <div className="review-top-subheader">{characterGender}</div>

                                    <div className="review-top-header">Type<EditIcon className="click-on-me"
                                                                                     onClick={() => goToStep(3)}/>
                                    </div>
                                    <div className="review-top-subheader">{visibility}</div>
                                </div>


                                <div className="review-holder-column">
                                    <div className="review-top-header">Style<EditIcon className="click-on-me"
                                                                                      onClick={() => goToStep(2)}/>
                                    </div>
                                    <div className="review-top-subheader">{selectedStyle}</div>
                                    <div className="review-top-header">Safety<EditIcon className="click-on-me"
                                                                                       onClick={() => goToStep(3)}/>
                                    </div>
                                    <div className="review-top-subheader">{contentType}</div>

                                </div>


                            </div>
                            <div className="character-double-column">
                                <div className="review-bottom-column">
                                    <div className="review-holder-column">
                                        <div className="review-top-header">Description<EditIcon
                                            className="click-on-me" onClick={() => goToStep(3)}/></div>
                                        <div
                                            className="review-top-subheader">{truncateText(shortDescription, 50)}</div>
                                    </div>
                                    <div className="review-holder-column">
                                        <div className="review-top-header">First Message<EditIcon className="click-on-me"
                                                                                             onClick={() => goToStep(3)}/>
                                        </div>
                                        <div
                                            className="review-top-subheader">{truncateText(dialogueDescription, 50)}</div>
                                    </div>


                                </div>
                                <div className="review-bottom-column">
                                    <div className="review-holder-column">
                                        <div className="review-holder-column">
                                            <div className="review-top-header">Appearance<EditIcon
                                                className="click-on-me" onClick={() => goToStep(4)}/></div>
                                            <div
                                                className="review-top-subheader">{truncateText(appearanceDescription, 50)}</div>
                                        </div>
                                        <div className="review-holder-column">
                                            <div className="review-top-header">Personality<EditIcon
                                                className="click-on-me" onClick={() => goToStep(4)}/></div>
                                            <div
                                                className="review-top-subheader">{truncateText(personalityDescription, 50)}</div>
                                        </div>
                                        <div className="review-top-header">Scenario<EditIcon className="click-on-me"
                                                                                             onClick={() => goToStep(4)}/>
                                        </div>
                                        <div
                                            className="review-top-subheader">{truncateText(scenarioDescription, 50)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )}

        </>


    );
};

export default CharacterReview;
