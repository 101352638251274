import React, {useEffect, useState} from 'react';
import './Features.css';
// import ImageGirl from '../../icons/imagegirlside.png';
const Features = () => {
    const featuresData = [
        {
            id: 1,
            name: 'REALISM AND ENGAGEMENT',
            text: 'Our AI models are trained on real conversations to ensure that your characters are as realistic as possible.',
            icon: './icons/message-square-chat.svg', // Replace with actual icon image path

        },
        {
            id: 2,
            name: 'CUTTING-EDGE MEMORY',
            text: 'Memory is the key to a good conversation. Our AI models are trained to remember the context of the conversation and respond accordingly.',
            icon: './icons/microchip.svg', // Replace with actual icon image path

        },
        {
            id: 3,
            name: 'ON-DEMAND IMAGE GENERATION',
            text: 'Transforming words into visuals, our AI models generate relevant images during our chats. No more boring text-only conversations.',
            icon: './icons/clock-check.svg', // Replace with actual icon image path

        },
        {
            id: 4,
            name: 'LIMITLESS CHARACTERS',
            text: 'Total freedom in creation and selection. Create as many you as you want and select any of them to chat with at any time.',
            icon: './icons/infinity.svg', // Replace with actual icon image path

        },
        {
            id: 5,
            name: 'CONTEXTUAL IMAGES',
            text: 'Our AI models generate images that are contextually aligned with the conversation. No more random images that make no sense.',
            icon: './icons/images-user.svg', // Replace with actual icon image path

        },
    ];
    const firstRowFeatures = featuresData.slice(0, 3);
    const secondRowFeatures = featuresData.slice(3);

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };


        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>


            {isWide && (
<div className="features-section">
        <div className="feature-title">
            <span className="upper-text">Features for you.</span>
            <span className="lower-text">Build your AI soulmate.</span>
            <span className="carousel-subtitle-2">Various features to ensure that you can</span>
            <span className="carousel-subtitle-2">enjoy your experience to the max. </span>
        </div>
    <div className="features-container">
        {firstRowFeatures.map((feature) => (
            <div className="feature-item" key={feature.id}>
                <div className="feature-icon-text">
                    <img
                        src={require(`${feature.icon}`)}
                        alt={`${feature.name}`}
                        className="feature-icon"
                    />
                    <div className="feature-name">{feature.name}</div>
                </div>
                <div className="feature-description">
               {feature.text}
                </div>
            </div>
        ))}
    </div>
    <div className="features-container second-row">
        {secondRowFeatures.map((feature) => (
            <div className="feature-item" key={feature.id}>
                <div className="feature-icon-text">
                    <img
                        src={require(`${feature.icon}`)}
                        alt={`${feature.name}`}
                        className="feature-icon"
                    />
                    <div className="feature-name">{feature.name}</div>
                </div>
                <div className="feature-description">
                    <div className="text">{feature.text}</div>
                </div>
            </div>
        ))}
    </div>
</div>
            )}
            {!isWide && (
                <div className="features-section">
                    <div className="feature-title">
                        <span className="upper-text">Features for you.</span>
                        <span className="lower-text">Build your AI soulmate.</span>
                        <span className="carousel-subtitle-2">Various features to ensure that you can</span>
                        <span className="carousel-subtitle-2">enjoy your experience to the max. </span>
                    </div>
                    {/* First two features */}
                    <div className="features-container mobile-first-row">
                        {featuresData.slice(0, 2).map((feature) => (
                            <div className="feature-item" key={feature.id}>
                                <div className="feature-icon-text">
                                    <img
                                        src={require(`${feature.icon}`)}
                                        alt={`${feature.name}`}
                                        className="feature-icon"
                                    />
                                    <div className="feature-name">{feature.name}</div>
                                </div>
                                <div className="feature-description">
                                    {feature.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Next two features */}
                    <div className="features-container mobile-second-row">
                        {featuresData.slice(2, 4).map((feature) => (
                            <div className="feature-item" key={feature.id}>
                                <div className="feature-icon-text">
                                    <img
                                        src={require(`${feature.icon}`)}
                                        alt={`${feature.name}`}
                                        className="feature-icon"
                                    />
                                    <div className="feature-name">{feature.name}</div>
                                </div>
                                <div className="feature-description">
                                    {feature.text}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* Last feature centered */}
                    <div className="features-container mobile-last-row">
                        <div className="feature-item" key={featuresData[4].id} style={{ margin: '0 auto' }}> {/* Adjust styling as needed */}
                            <div className="feature-icon-text">
                                <img
                                    src={require(`${featuresData[4].icon}`)}
                                    alt={`${featuresData[4].name}`}
                                    className="feature-icon"
                                />
                                <div className="feature-name">{featuresData[4].name}</div>
                            </div>
                            <div className="feature-description">
                                {featuresData[4].text}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
};

export default Features;
