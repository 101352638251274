import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const UpgradeSaleModal = ({ isOpen, onClose, children, percent }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    💎 Premium, but less?
                    <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">

                    <div className='sublabel-notification'>
                        Premium is on sale with
                        <span className="colored-purple-text"> {percent}% off </span>
                        the usual price!
                    </div>

                    <div className='sublabel-notification'>
                        Unlimited messages, hundreds of image
                        generations, and more features waiting
                        for you to use them.
                    </div>

                    <div className='sublabel-notification'>
                        Don’t let the time run out!
                    </div>

                </div>


                <button onClick={() => {onClose(true)}} className="notification-button-main">Upgrade</button>


            </div>
        </div>
    );
};

export default UpgradeSaleModal;