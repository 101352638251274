import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useNavigate} from "react-router-dom";

const ImageLimitUpsell = ({isOpen, onClose, children}) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        💕 You like it that much?
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false)
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className='sublabel-notification'>
                            It seems you have ran out of the images included in your
                            <span className="colored-purple-text"> Premium Plan. </span>
                            <br></br>
                            You can buy more
                            <span className="colored-purple-text"> images </span>
                            using one of the
                            <span className="colored-purple-text"> addons. </span>
                            <br></br>
                            <br></br>
                            Thank you for supporting us!
                        </div>
                    </div>
                    <button onClick={() => {
                        onClose(true)
                        navigate('/addons');
                    }} className="notification-button-main">I Want More!
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default ImageLimitUpsell