import React from 'react';
import {ReactComponent as Logo} from "./LogoText.svg";
import { useNavigate } from 'react-router-dom';
import './404Page.css';
const ErrorPage = () => {
    const navigate = useNavigate();

    // Styles for the container
    return (
        <div className="error-frf-container">
            <Logo />
            <span className="bigger-error-text">Error 404</span>
            <span className="smaller-error-text">The page you were looking for does not exist, please return to the main page!</span>
            <button className="signup-button" onClick={() => navigate('/')}>Back to Home page</button>
        </div>
    );
};

export default ErrorPage;
