import React, {useEffect} from 'react';
import './BannerStyle.css';
import {ReactComponent as Discord} from './BannerIcons/discordicon.svg';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';
import {ReactComponent as Twitter} from './BannerIcons/twitter.svg';
// import Anime1 from './BannerIcons/anime1.png';
// import Real1 from './BannerIcons/real1.png';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../../icons/cross.svg";
import Cookies from 'js-cookie';


const MobileHeroBanner = ({isBannerVisibleFirstDiscord, hideBanner}) => {

    const hideBannerAndSetCookie = () => {
        hideBanner();
        Cookies.set('bannerHidden', 'true', { expires: 10 });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (Cookies.get('bannerHidden')) {
                hideBanner();
            } else {
                document.body.style.overflow = 'hidden'; // Disable scrolling
            }
        }, );

        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto'; // Enable scrolling when component is unmounted
        };
        //eslint-disable-next-line
    }, []);



    if (!isBannerVisibleFirstDiscord) return null;

    const bannerContent = (<div className="banner-container-hero-mobile banner-state-4">
            <div className="banner-content-hero">
                <div className="banner-left-hero-mobile">
                    <div className="row-title-screen-mobile">
                    <span className="main-title-screen">
                    <span className="banner-title-hero">Chat
                        <span className="colorful-text-nsfw" style={{padding: '0'}}> NSFW </span>
                       with
                    </span>
                    <span className="banner-title-hero">
                        an AI companion.
                    </span>
                    </span>

                        <CloseIcon style={{cursor: "pointer"}}
                                   onClick={hideBanner}/>
                    </div>


                    <span className="banner-subtitle-hero">
                        Elyza is home to hundreds of characters who are just waiting for you to start chatting with them.
                    </span>
                    <span className="banner-subtitle-hero">
                       You can send them messages or 🌶️ photos, and they’ll send you them back.
                    </span>

                    <button className="banner-button-hero button-state-4"  onClick={hideBannerAndSetCookie}>
                        <StarIcon className='star-icon-banner'/>
                        View all characters
                    </button>
                    <span className='sub-button-text' style={{cursor: "unset"}}>or join the community</span>
                    <div className="join-community-row">

                        <Discord className="hero-banner-icon"
                                 onClick={() => window.open('https://discord.gg/V4YUYRBc5M', '_blank')}/>

                        <Twitter className="hero-banner-icon"
                                 onClick={() => window.open('https://x.com/elyzaapp', '_blank')}/>


                    </div>
                </div>
                <div className="banner-right-hero"></div>
            </div>
        </div>);


    return ReactDOM.createPortal(bannerContent, document.getElementById('modal-root'));
};

export default MobileHeroBanner;
