import React, {useEffect, useRef, useState} from 'react';
import './ProfileEdit.css';
import {getUserAccessToken, getUserEmail, getUserID, supabase} from "../../App";
// import {AuthModal} from "../Forms/AuthModal";
import {useNavigate} from "react-router-dom";
import {ReactComponent as EditIcon} from "./ProfileIcons/Edit.svg";
import {ReactComponent as Visa} from "./ProfileIcons/Visa.svg"
import {ReactComponent as Card} from "./ProfileIcons/Card.svg"
import {ReactComponent as FreeCard} from "./ProfileIcons/FreeCard.svg"
import {ReactComponent as CloseIcon} from "./ProfileIcons/Close.svg"
import Tooltip from '@mui/material/Tooltip';
import {ReactComponent as VisaGray} from "./ProfileIcons/FreeMoney.svg";
import ReferralMenu from "../Banners/Referral/ReferralMenu";
import {ReactComponent as DefaultProfile} from "./ProfileIcons/default-profile.svg";
import {useProfileData} from "../Context/ProfileDataContext";
import {LazyLoadImage} from "react-lazy-load-image-component";

const ProfileEdit = ({onSave, onClose}) => {

    const profileEditRef = useRef(null);
    const navigate = useNavigate();
    const [showNameChangePopup, setShowNameChangePopup] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [email, setEmail] = useState('');
    const displayEmail = email.length > 20 ? `${email.substring(0, 16)}...` : email;
    const [usernameChanged, setUsernameChanged] = useState('');
    const [showEmailChangePopup, setShowEmailChangePopup] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [emailChangeStatus, setEmailChangeStatus] = useState('');

    const {profileData} = useProfileData();
    const setCurrentName = (newUsername) => {
        profileData.username = newUsername;
    }
    let subscriptionType = profileData.subscription_data.type;
    subscriptionType = subscriptionType === 'MONTH' ? 'Monthly' : subscriptionType === 'ANNUAL' ? 'Annually' : subscriptionType;

    let lastFourDigits = profileData.subscription_data.lastFourDigits;
    let customerId = profileData.subscription_data.customerId;
    let lastPayment = profileData.subscription_data.lastPaymentTimestamp;
    let nextPayment = profileData.subscription_data.nextRenewalTimestamp;
    const [profileUrl, setProfileUrl] = useState(profileData.profile);

    const handleClosePopup = () => {
        setShowNameChangePopup(false);
        setShowEmailChangePopup(false);
    };

    const updateUserData = async (base64String = "") => {
        try {
            const userId = await getUserID();
            const body = {
                userId: userId, imageBase64: base64String, // Handle image data as needed
                newUsername: newUsername, // Use the state variable directly
                newBio: "", // Handle bio as needed
                justRegistered: false, genderPreference: ''
            };
            const response = await fetch('https://api.elyza.app/v1/update-profile', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify(body),
            });

            if (response.ok) {
                setUsernameChanged('success'); // Set success message
                setCurrentName(newUsername); // Update the current name state
            } else {
                setUsernameChanged('error'); // Set error message
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            setUsernameChanged('error'); // Set error message
        }
    };

    function formatTimestamp(timestamp) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const date = new Date(timestamp);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        const formattedDate = `${month} ${day}${getDaySuffix(day)}, ${year}`;
        // const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return `${formattedDate}`;
    }

    function getDaySuffix(day) {
        const lastDigit = day % 10;
        const lastTwoDigits = day % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return "th";
        }

        switch (lastDigit) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    useEffect(() => {
        if (profileData.username.length === 0) {
            setCurrentName('Loading...');
        }

        const fetchUserData = async () => {
            const userID = await getUserID();
            if (!userID) return;

            const response = await fetch('https://api.elyza.app/v1/profile-data', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': await getUserAccessToken(),
                }, body: JSON.stringify({userId: userID}),
            });

            const data = await response.json();

            setCurrentName(data.username);


        }

        fetchUserData();
        //eslint-disable-next-line
    }, [profileData.username]);

    const internalOnSave = async () => {
        try {
            await updateUserData();
            setShowNameChangePopup(false); // Hide the popup after saving
            onSave();
        } catch (error) {
            console.error('Error saving user data:', error);
            setUsernameChanged('error'); // Set error message
        }
    };

    const handleNewNameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const handleNameChangeClick = () => {
        setNewUsername(profileData.username); // Initialize with current name
        setShowNameChangePopup(true);
    };


    const handleSignOut = async () => {
        const {error} = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error.message);
        } else {

            localStorage.clear();

            navigate('/'); // Replace with your routing logic
            window.location.reload();
        }
    };

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (profileEditRef.current && !profileEditRef.current.contains(event.target)) {
                setShowNameChangePopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileEditRef]);

    useEffect(() => {
        const fetchAndSetUserEmail = async (setEmailFunction) => {
            try {
                const userEmail = await getUserEmail(); // Function to get the user's email
                setEmailFunction(userEmail); // Callback function to handle the fetched email
            } catch (error) {
                console.error('Error fetching user email:', error);
            }
        };

        fetchAndSetUserEmail(setNewEmail); // Assuming you want to fetch and set the current email as the initial state
    }, []);

    const fetchAndSetUserEmail = async (setEmailFunction) => {
        try {
            const userEmail = await getUserEmail(); // Function to get the user's email
            setEmailFunction(userEmail); // Callback function to handle the fetched email
        } catch (error) {
            console.error('Error fetching user email:', error);
        }
    };

    useEffect(() => {
        // Assuming fetchAndSetUserEmail is defined elsewhere and imported
        fetchAndSetUserEmail(setEmail);
    }, []);

    const handleNewEmailChange = (e) => {
        setShowEmailChangePopup(true);
        setNewEmail(e.target.value);
    };

    const updateEmail = async () => {
        const userId = await getUserID();
        if (!userId) {
            setEmailChangeStatus('error');
            return;
        }
        //eslint-disable-next-line
        const {data, error} = await supabase.auth.updateUser({
            email: newEmail
        })

        if (error) {
            setEmailChangeStatus('error');

        } else {
            setEmailChangeStatus('success');

        }
    };

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };


        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [language, setLanguage] = useState('English');
    const [theme, setTheme] = useState('Dark');

    const languages = ['English'];
    const themes = ['Dark'];

    const languageSelectRef = useRef(null);
    const themeSelectRef = useRef(null);

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const handleThemeChange = (e) => {
        setTheme(e.target.value);
    };

    // Function to focus the select element
    const focusSelect = (selectRef) => {
        selectRef.current?.focus();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);

    const handleCloseModal = () => setIsModalOpen(false);

    return (<>
            <ReferralMenu isOpen={isModalOpen} onCancel={handleCloseModal} style={{zIndex: '9999'}}/>

            <div className="profile-edit-overlay">
                <div className="profile-container-menu" ref={profileEditRef}>
                    {!isWide && (<>
                            <div className="profile-cont-t1">
                                <div className="profile-setting-label">Your profile
                                    <div className="close-modal-icon-profile"
                                         onClick={onClose}
                                    >
                                        <CloseIcon/>
                                    </div>
                                </div>
                                <div className="profile-setting-sublabel">View details about your profile below. You can
                                    modify them as well as you see fit.
                                </div>
                            </div>
                            <div className="a-straight-line"></div>

                            <div className="profile-cont-t2">


                                <Tooltip title='Upload Profile' arrow>
                                    <div className="profile-circle">
                                        <label htmlFor="image-upload" className="image-upload-label">
                                            {profileUrl ? (<LazyLoadImage
                                                    effect="blur"
                                                    alt={""}
                                                    className="profile-image"
                                                    placeholderSrc={DefaultProfile}
                                                    src={profileUrl}
                                                />) : (<div className="default-avatar"></div>)}
                                            <input
                                                type="file"
                                                id="image-upload"
                                                accept="image/*"
                                                onChange={async (event) => {
                                                    const file = event.target.files[0];
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            const base64String = reader.result;
                                                            setProfileUrl(base64String);

                                                            // const base64Data = base64String.split(',')[1]; // This removes the data URL prefix
                                                            updateUserData(base64String);
                                                            // const base64String = reader.result;
                                                            // const base64Data = base64String.split(',')[1];

                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                style={{display: 'none'}}
                                            />
                                        </label>
                                    </div>
                                </Tooltip>

                                <div className="grid-4-profile">

                                    <div className="grid-profile-item">
                                        <label className="info-label">Username
                                            <EditIcon className="edit-settings-icon" onClick={handleNameChangeClick}/>
                                        </label>
                                        <Tooltip title={profileData.username} arrow>
                                        <div className="info-content">
                                            {profileData.username.length > 15
                                                ? `${profileData.username.substring(0, 15)}...`
                                                : profileData.username
                                            }
                                        </div>
                                        </Tooltip>
                                    </div>

                                    <div className="grid-profile-item">
                                        <label className="info-label">Email
                                            {/*<EditIcon className="edit-settings-icon" onClick={handleNewEmailChange}/>*/}
                                        </label>
                                        <div className="info-content">
                                            <Tooltip title={email} arrow>
                                                <span>{displayEmail}</span>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <Tooltip title='TBA' arrow>
                                        <div className="grid-profile-item">
                                            <label className="info-label">Language
                                                <EditIcon className="edit-settings-icon"
                                                          onClick={() => focusSelect(languageSelectRef)}/>
                                            </label>
                                            <select ref={languageSelectRef} value={language}
                                                    onChange={handleLanguageChange}
                                                    className="info-content-dropdown">
                                                {languages.map((lang) => (
                                                    <option key={lang} value={lang}>{lang}</option>))}
                                            </select>
                                        </div>
                                    </Tooltip>

                                    <Tooltip title='TBA' arrow>
                                        <div className="grid-profile-item">
                                            <label className="info-label">Theme
                                                <EditIcon className="edit-settings-icon"
                                                          onClick={() => focusSelect(themeSelectRef)}/>
                                            </label>
                                            <select ref={themeSelectRef} value={theme} onChange={handleThemeChange}
                                                    className="info-content-dropdown">
                                                {themes.map((themeOption) => (<option key={themeOption}
                                                                                      value={themeOption}>{themeOption}</option>))}
                                            </select>
                                        </div>
                                    </Tooltip>

                                </div>
                            </div>
                        </>)}


                    {isWide && (<>
                            <div className="profile-cont-t1">
                                <div className="profile-setting-label">Your profile
                                    <div className="close-modal-icon-profile"
                                         onClick={onClose}
                                    >
                                        <CloseIcon/>
                                    </div>


                                </div>
                                <div className="profile-setting-sublabel">View details about your profile below. You can
                                    modify them as well as you see fit.
                                </div>
                            </div>
                            <div className="a-straight-line"></div>
                            <div className="profile-cont-t2">

                                <Tooltip title='Upload Profile' arrow>
                                    <div className="profile-circle">
                                        <label htmlFor="image-upload" className="image-upload-label">
                                            {profileUrl ? (<LazyLoadImage
                                                    effect="blur"
                                                    alt={""}
                                                    className="profile-image"
                                                    placeholderSrc={DefaultProfile}
                                                    src={profileUrl}
                                                />) : (<div className="default-avatar"></div>)}
                                            <input
                                                type="file"
                                                id="image-upload"
                                                accept="image/*"
                                                onChange={async (event) => {
                                                    const file = event.target.files[0];
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            const base64String = reader.result;
                                                            setProfileUrl(base64String);

                                                            // const base64Data = base64String.split(',')[1]; // This removes the data URL prefix
                                                            updateUserData(base64String);
                                                            // const base64String = reader.result;
                                                            // const base64Data = base64String.split(',')[1];

                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                style={{display: 'none'}}
                                            />
                                        </label>
                                    </div>
                                </Tooltip>


                                <div className="grid-4-profile">

                                    <div className="grid-profile-item">
                                        <label className="info-label">Username
                                            <EditIcon className="edit-settings-icon" onClick={handleNameChangeClick}/>
                                        </label>
                                        <Tooltip title={profileData.username} arrow>
                                            <div className="info-content">
                                                {profileData.username.length > 15
                                                    ? `${profileData.username.substring(0, 15)}...`
                                                    : profileData.username
                                                }
                                            </div>
                                        </Tooltip>                                  </div>

                                    <div className="grid-profile-item">
                                        <label className="info-label">Email
                                            {/*<EditIcon className="edit-settings-icon" onClick={handleNewEmailChange}/>*/}
                                        </label>
                                        <div className="info-content">
                                            <Tooltip title={email} arrow>
                                                <span>{displayEmail}</span>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <Tooltip title='TBA' arrow>

                                        <div className="grid-profile-item">
                                            <label className="info-label">Language
                                                <EditIcon className="edit-settings-icon"
                                                          onClick={() => focusSelect(languageSelectRef)}/>
                                            </label>
                                            <select ref={languageSelectRef} value={language}
                                                    onChange={handleLanguageChange}
                                                    className="info-content-dropdown">
                                                {languages.map((lang) => (
                                                    <option key={lang} value={lang}>{lang}</option>))}
                                            </select>
                                        </div>
                                    </Tooltip>


                                    <Tooltip title='TBA' arrow>
                                        <div className="grid-profile-item">

                                            <label className="info-label">Theme
                                                <EditIcon className="edit-settings-icon"
                                                          onClick={() => focusSelect(themeSelectRef)}/>
                                            </label>
                                            <select ref={themeSelectRef} value={theme} onChange={handleThemeChange}
                                                    className="info-content-dropdown">
                                                {themes.map((themeOption) => (<option key={themeOption}
                                                                                      value={themeOption}>{themeOption}</option>))}
                                            </select>

                                        </div>
                                    </Tooltip>


                                </div>


                            </div>
                        </>)}


                    <div className="signout-container">
                        <span className="profile-setting-sublabel-2">Not you?</span>
                        <button onClick={handleSignOut} className="sign-out-button">
                            Sign Out
                        </button>
                    </div>
                    <div className="a-straight-line"></div>

                    <div className="profile-cont-t3">


                        <div className="profile-setting-label">Billing info</div>
                        <div className="profile-setting-sublabel">Manage and view your current plan, payment info,
                            and other details.
                        </div>

                        <div className="signout-container" style={{marginTop: '5px'}}>
                            <span className="profile-setting-sublabel-2">Looking to refer?</span>

                            <button onClick={handleOpenModal} className="sign-out-button">
                                Refer here
                            </button>
                        </div>


                    </div>


                    <div className="a-straight-line"></div>

                    <div className="profile-cont-t4">

                        <div className="grid-payment-2">

                            <div className="active-plan-container">

                                <label className="info-sub-2-label">Active plan
                                    <EditIcon
                                        className="edit-settings-icon"
                                        onClick={async () => {
                                            if (customerId === undefined) {
                                                navigate('/pricing')
                                            }

                                            const response = await fetch('https://api.elyza.app/v1/profile-data-update', {
                                                method: 'POST', headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': await getUserAccessToken(),
                                                }, body: JSON.stringify({
                                                    userId: await getUserID(),
                                                    newName: "",
                                                    newEmail: "",
                                                    newLanguage: "",
                                                    requestSelfServePortal: true,
                                                    customerId: customerId,
                                                }),
                                            });

                                            if (!response.ok) {
                                                throw new Error(`HTTP error ${response.status}`);
                                            }

                                            const data = await response.json();
                                            window.location.href = data.url;
                                        }}
                                    />

                                </label>
                                <div className="sub-container">


                                    {profileData.subscription_data.tier === 'FREE' ?
                                        <FreeCard className="sub-cart-details"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      if (profileData.subscription_data.tier === 'FREE') {
                                                          window.location.href = '/pricing';
                                                      }
                                                  }}

                                        /> : <Card className="sub-cart-details"/>

                                    }
                                    <div className="container-text-capture">


                                        <div className="plan-length">{subscriptionType}</div>
                                        <div
                                            className={`plan-type ${profileData.subscription_data.tier === 'FREE' ? 'free-plan' : ''}`}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                if (profileData.subscription_data.tier === 'FREE') {
                                                    window.location.href = '/pricing';
                                                }
                                            }}
                                        >
                                            Elyza {profileData.subscription_data.tier === 'GOLD' ? 'ULTIMATE' : profileData.subscription_data.tier}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-method-container">
                                <label className="info-sub-2-label">Payment method
                                    <EditIcon
                                        className="edit-settings-icon"
                                        onClick={async () => {
                                            if (customerId === undefined) {
                                                navigate('/pricing')
                                            }

                                            const response = await fetch('https://api.elyza.app/v1/profile-data-update', {
                                                method: 'POST', headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': await getUserAccessToken(),
                                                }, body: JSON.stringify({
                                                    userId: await getUserID(),
                                                    newName: "",
                                                    newEmail: "",
                                                    newLanguage: "",
                                                    requestSelfServePortal: true,
                                                    customerId: customerId,
                                                }),
                                            });

                                            if (!response.ok) {
                                                throw new Error(`HTTP error ${response.status}`);
                                            }

                                            const data = await response.json();
                                            window.location.href = data.url;
                                        }}
                                    />
                                </label>
                                <div className="act-container">
                                    {profileData.subscription_data.tier === 'FREE' ?
                                        <VisaGray className="sub-cart-details"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      if (profileData.subscription_data.tier === 'FREE') {
                                                          window.location.href = '/pricing';
                                                      }
                                                  }}

                                        /> : <Visa className="sub-cart-details"/>}


                                    <div
                                        className={`container-text-capture ${profileData.subscription_data.tier === 'Free' ? 'free-plan' : ''}`}>
                                        {profileData.subscription_data.tier === 'FREE' ? (
                                            <div className="plan-length"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => {
                                                     if (profileData.subscription_data.tier === 'FREE') {
                                                         window.location.href = '/pricing';
                                                     }
                                                 }}
                                            >None</div>) : (<>
                                                <div className="plan-length">Ending in</div>
                                                <div className="plan-type-2">
                                                    <svg height="10" width="10">
                                                        <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                    </svg>
                                                    <svg height="10" width="10">
                                                        <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                    </svg>
                                                    <svg height="10" width="10">
                                                        <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                    </svg>
                                                    <svg height="10" width="10">
                                                        <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                    </svg>
                                                    {lastFourDigits}
                                                </div>
                                            </>)}
                                    </div>
                                </div>
                            </div>

                        </div>


                        {profileData.subscription_data.tier !== "FREE" && (<>
                                <div className="profile-setting-sublabel-2">Last payment was on
                                    <span
                                        className="profile-setting-sublabel-bold"> {formatTimestamp(lastPayment)} EST.</span>
                                </div>
                                <div className="profile-setting-sublabel-2"> Next payment scheduled for
                                    <span
                                        className="profile-setting-sublabel-bold"> {formatTimestamp(nextPayment)} EST.</span>
                                </div>
                            </>)}


                    </div>


                    {showNameChangePopup && (<div className="popup-background"
                                                  onClick={handleClosePopup}>

                        <div className="name-change-popup" onClick={(e) => e.stopPropagation()}>
                            <div className="main-upper-text-row">
                                <div className="profile-setting-label">✒️Get a New Username</div>
                                <div className="close-modal-icon" onClick={handleClosePopup}><CloseIcon/></div>
                            </div>
                            <div className="profile-setting-sublabel">Here you may change your username to something
                                new.
                            </div>

                            <input
                                style={{marginTop: '15px'}}
                                type="text"
                                placeholder="New Username"
                                className="new-name-input"
                                value={newUsername}
                                onChange={handleNewNameChange}
                            />

                            <div className='a-straight-line-3'


                            ></div>
                            <button className="save-new-name-button" onClick={internalOnSave}>
                                Save Username
                            </button>

                            {usernameChanged === 'success' && (
                                <div className="profile-success-message">Username changed successfully!</div>)}
                            {usernameChanged === 'error' && (
                                <div className="profile-error-message">Failed to change username. Please try
                                    again.</div>)}
                        </div>

                    </div>)}
                    {showEmailChangePopup && (
                        <div className="modal-backdrop" onClick={handleClosePopup}>
                            <div className="modal-content-notification" style={{padding: '20px'}}
                                 onClick={(e) => e.stopPropagation()}>
                                <div className="notification-lable" style={{paddingBottom: '5px'}}>Edit Email
                                    <div className="modal-close-icon-notification" onClick={handleClosePopup}>
                                        <CloseIcon/>
                                    </div>
                                </div>

                                <div className='sublabel-notification' style={{paddingBottom: '10px'}}>
                                    You will have to verify your new email address.
                                </div>


                                <input
                                    type="email"
                                    placeholder="New Email"
                                    className="new-name-input"
                                    value={newEmail}
                                    onChange={handleNewEmailChange}
                                />

                                <div className='a-straight-line'></div>
                                <button className="notification-button-main" style={{margin: 0}} onClick={updateEmail}>
                                    Send
                                </button>

                                {emailChangeStatus === 'success' && (
                                    <div className="profile-success-message">Email changed successfully!</div>)}
                                {emailChangeStatus === 'error' && (
                                    <div className="profile-error-message">Failed to change email. Please try
                                        again.</div>)}
                            </div>
                        </div>)}
                </div>
            </div>
        </>

    );
};

export default ProfileEdit;