import React, {useEffect} from 'react';
import './BannerStyle.css';
import {ReactComponent as Discord} from './BannerIcons/discordicon.svg';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';
import {ReactComponent as Twitter} from './BannerIcons/twitter.svg';
import Cookies from "js-cookie";
// import Anime1 from './BannerIcons/anime1.png';
// import Real1 from './BannerIcons/real1.png';


const HeroBannerMain = ({isBannerVisibleFirstDiscord, hideBanner}) => {

    const hideBannerAndSetCookie = () => {
        hideBanner();
        Cookies.set('bannerHidden', 'true', { expires: 10 });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (Cookies.get('bannerHidden')) {
                hideBanner();
            }
        }, );

        return () => {
            clearTimeout(timer);
        };
        //eslint-disable-next-line
    }, []);


    if (!isBannerVisibleFirstDiscord) return null;

    return (
        <div className="banner-container-hero banner-state-4">
            <div className="banner-content-hero">
                <div className="banner-left-hero">
                    <span className="main-title-screen">
                    <span className="banner-title-hero">Chat with
                        <span className="colorful-text-nsfw"> uncensored <span style={{fontStyle: "normal"}}>NSFW</span></span>
                    </span>
                    <span className="banner-title-hero">companions

                          <span className="colorful-text-nsfw"> <span style={{fontStyle: "normal"}}>who want</span> you.</span>

                    </span>
                    </span>

                    <span className="banner-subtitle-hero">
                        Elyza is home to hundreds of characters who are waiting for you to start chatting with them.
                    </span>
                    <span className="banner-subtitle-hero">
                        You can send them messages or 🌶️ spicy photos, and they’ll send you messages, 🥵 sexy photos, and cute voice notes.
                    </span>

                    <button className="banner-button-hero button-state-4" onClick={hideBannerAndSetCookie}>
                        <StarIcon className='star-icon-banner'/>
                        View all characters
                    </button>

                    <span className='sub-button-text' style={{cursor: "unset"}}>or join the community</span>
                    <div className="join-community-row">

                    <Discord className="hero-banner-icon"
                                 onClick={() => window.open('https://discord.gg/V4YUYRBc5M', '_blank')}/>

                        <Twitter className="hero-banner-icon"
                                 onClick={() => window.open('https://x.com/elyzaapp', '_blank')}/>
                    </div>
                </div>
                <div className="banner-right-hero">
                </div>
            </div>
        </div>);
};

export default HeroBannerMain;
