import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const ImageGenerationModal = ({ isOpen, onClose, children, images }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    💔 We’re sorry!
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        Our image generation wasn’t up to par,
                        and we’re sorry.
                    </div>

                    <div className='sublabel-notification'>
                        We’ve reimbursed <span className="colored-purple-text">{images / 4} image{(images / 4) > 1 ? 's' : ''}</span>.
                    </div>

                    <div className='sublabel-notification'>
                        We’ve also given <span
                        className="colored-purple-text">{(3 * images) / 4} extra images </span>
                        for you to use as an apology.
                    </div>

                    <div className='sublabel-notification'>
                        Thank you for using Elyza.
                    </div>
                </div>


                <button onClick={onClose} className="notification-button-main">Claim Reward</button>


            </div>
        </div>
    );
};

export default ImageGenerationModal;