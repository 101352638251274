import React, {createContext, useContext, useState} from 'react';

const ChatFunctionsContext = createContext();

export const ChatFuncContext = () => useContext(ChatFunctionsContext);

export const ChatFunctionPasser = ({ children }) => {

    const [contextualImageLoading, setContextualImageLoading] = useState(false);
    const [contextualImageURL, setContextualImageURL] = useState('');
    const [contextualMessageID, setContextualMessageID] = useState('');

    const [contextualAudioLoading, setContextualAudioLoading] = useState(false);
    // console.log(contextualAudioLoading + ' is audio loading')
    const [contextualAudioURL, setContextualAudioURL] = useState('');
    const [contextualAudioID, setContextualAudioID] = useState('');

    const value = {
        contextualImageLoading,
        setContextualImageLoading,
        contextualImageURL,
        setContextualImageURL,
        contextualMessageID,
        setContextualMessageID,
        contextualAudioLoading,
        setContextualAudioLoading,
        contextualAudioURL,
        setContextualAudioURL,
        contextualAudioID,
        setContextualAudioID,
    };

    return (
        <ChatFunctionsContext.Provider value={value}>
            {children}
        </ChatFunctionsContext.Provider>
    );
};
