import React from "react";
import "./NewPricingContainer.css";
import {ReactComponent as CheckIcon} from "../icons/CheckIcon.svg";

import {ReactComponent as CrossIcon} from "../icons/cross.svg";

export function NewPricingContainer({ heading, features, onSelect, isSelected }) {


    const getPlanClass = (heading) => {
        switch (heading) {
            case "Premium Plan":
                return "new-premium-container";
            case "Ultimate Plan":
                return "new-ultimate-container";
            case "Basic Plan":
                return "new-basic-container";
            case "Free Plan":
                return "new-free-container";

            default:
                return "";
        }
    };

    const iconForFeature = (feature) => {
        switch (feature) {
            case "All basic plan features.":
            case "Unlimited messages.":
            case "AI Character Creation.":
            case "Advanced memory.":
            case "NSFW images.":
            case "Early access.":
            case "Basic memory.":
            case "50 images / month.":
            case "100 images / month.":
            case "500 images / month.":
            case "100 audios / month.":
            case "500 audios / month.":
            case "50 audios / month.":
            case "50 messages / month.":
            case "Join group chats with limits.":
            case "Join Group Chats without limits.":
            case "Create and join group chats.":
            case "Create your own exclusive characters using basic models.":
            case "Early access to new features and video generation.":
            case "Customize scenarios and response settings.":
            case "Ultimate memory.":
            case "Create your own exclusive characters with basic models for free.":
            case "Create group and join group chats.":
            case "Unlimited image generation.":
            case "Ability to gift messages and images to friends.":
            case "Custom scenarios and response settings.":
            case "Better memory.":
            case "Faster access to beta features.":
            case "Maximum memory.":
            case "Custom scenario and response settings.":
            case '600 images / month.':
            case 'Early access to new features.':
            case 'Advanced chat model.':
            case 'Refund of poor images.':
            case 'Dynamic Scenario.':
            case '1 Memory Fragment.':
            case '1 Personality Profile.':
            case '2 Memory Fragments.':
            case '2 Personality Profiles.':
            case '4 Memory Fragments.':
            case '4 Personality Profiles.':
            case '6 Memory Fragments.':
            case '6 Personality Profiles.':

                return <CheckIcon className="check-icon-pricing" />;

            case "Referral program.":
                case "Access to free characters.":
                    return <CheckIcon className="check-icon-pricing" />;

            case "Access to the most capable models and NSFW image generation.":
            case "Create group chats.":
            case "Custom settings.":
                return <CrossIcon className="check-icon-pricing" />;




            default:
                return null;
        }
    };
    const handleClick = () => {
        if(heading === "Free Plan") return;
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Enables smooth scrolling
        });
        if (onSelect) onSelect(heading);
    };



    return (
        <div className="feature-card-container" onClick={handleClick}>
            <div className={`new-pricing-card ${getPlanClass(heading)} ${isSelected ? "selected-plan" : ""}`}>



                {heading === "Premium Plan" ? <div className="new-mostPopular">Most Popular!</div> : null}
                {heading === "Ultimate Plan" ? <div className="new-ultimatePlan">Best Value!</div> : null}
                {heading === "Basic Plan" ? <div className="new-unlimitedMessages">Stay Connected!</div> : null}


            <div className="new-sidebarMenu">
                <h2 className="new-textnamesub">{heading}</h2>

                {features.map((feature, index) => (
                    <div className="new-menuOptionContainer" key={index}>
                        {iconForFeature(feature)}
                        <span className="new-menuText">{feature}</span>
                    </div>
                ))}

            </div>
        </div>
        </div>
    );
}
