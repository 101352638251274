import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {supabase, getUserAccessToken} from '../../App';
import {ReactComponent as DiscordIcon} from '../../icons/discord.svg';
import {ReactComponent as GoogleIcon} from '../../icons/google.svg';
import {ReactComponent as CloseModalIcon} from '../../icons/cross.svg';
import ResetPasswordPage from './ResetPassword.js';
import './AuthModal.css';
import {Turnstile} from '@marsidev/react-turnstile'

export const AuthModal = ({onClose}) => {
    const [isSignUp, setIsSignUp] = useState(true); // false means login, true means signup
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [captchaToken, setCaptchaToken] = useState()
    const captchaRef = useRef();

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/logout') {

            const genderPreference = localStorage.getItem('genderPreference');
            localStorage.clear();

            if (genderPreference) {
                // Restore genderPreference since it was cleared with everything else
                localStorage.setItem('genderPreference', genderPreference);
            }


            supabase.auth.signOut().then(() => {

            });
        }
    }, []);

    const handleAuthSwitch = () => {
        setError(""); // Clear any errors
        setIsSignUp(!isSignUp); // Switch mode
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);

        return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers;
    };

    const handleSignUp = async (event) => {

        event.preventDefault();

        if (!email || !isValidEmail(email)) {
            setEmailError(true);
            setError("Please enter a valid email address.");
            setCaptchaToken(null);
            return;
        } else {
            setEmailError(false);
        }

        if (!password || !isValidPassword(password)) {
            setPasswordError(true);

            setError("Password must be at least 8 characters long, include uppercase letters, and a number.");
            setCaptchaToken(null);
            return;
        } else {
            setPasswordError(false);
        }

        window.rdt('track', 'SignUp');

        setError("");
        setIsLoading(true);
        try {

            const response = await supabase.auth.signUp({
                email, password, options: {captchaToken}
            });
//TODO - Add user gender preference into storage
            if (response.error) {
                setError(response.error.message);
                setCaptchaToken(null);
                return;
            } else {
                if (response.data.user) {
                    const id = response.data.user.id;
                    await fetch('https://api.elyza.app/v1/update-profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': await getUserAccessToken()
                        },
                        body: JSON.stringify({
                            userId: id,
                            imageBase64: "",
                            newUsername: name.length > 0 ? name : "Guest",
                            newBio: "",
                            genderPreference: localStorage.getItem('genderPreference') ?? "Female",
                            justRegistered: true,
                            affCode: localStorage.getItem('affCode') ?? "",
                            referralCode: localStorage.getItem('referralCode') ?? "",
                            campaignCode: localStorage.getItem('campaignCode') ?? "",
                            targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([]))
                        })
                    });

                    onClose();
                    window.location.reload();
                }
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleLogin = async (event) => {
        event.preventDefault();

        setErrorMessage("");
        setIsLoading(true);
        try {

            const {error} = await supabase.auth.signInWithPassword({email, password, options: {captchaToken}});
            if (error) {
                setErrorMessage(error.message);
                setCaptchaToken(null);
            } else {
                onClose(); // Close the login modal or reset the login state
                window.location.reload(); // Reload the page
            }
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setIsLoading(false);
        }
    };


    const handleGoogleSignIn = async () => {
        try {
            //eslint-disable-next-line
            const {user, session, error} = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location}`,

                    // redirectTo: `http://localhost:3000//auth/v1/callback`,
                    // redirectTo: 'https://www.elyza.app/auth/v1/callback',
                    // redirectTo: 'http://supabase.elyza.app/auth/v1/callback',
                    // redirectTo: 'https://localhost:3000/auth/v1/callback',
                    // redirectTo: 'http://localhost:3000/auth/v1/callback',
                },
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                }
            });
            // console.log('Redirecting to:', window.location)
            if (error) throw error;
            // console.log('Google sign-in successful:', user, session);

        } catch (error) {
            // console.error('Google sign-in error:', error);
        }
    };
    // const handleGoogleSignIn = async () => {
    //     try {
    //         // Original path with double slash
    //         let originalPath = '/auth/v1/callback';
    //
    //         // Encode the path in a way that bypasses normal URL interpretation
    //         // This example uses base64 encoding for illustration; adjust as needed
    //         let encodedPath = btoa(originalPath).replace(/=/g, ''); // Remove padding for cleaner URL
    //
    //         // Construct the redirectTo URL using the encoded path
    //         const redirectTo = `http://localhost:3000/${encodedPath}`;
    //
    //         const { user, session, error } = await supabase.auth.signInWithOAuth({
    //             provider: 'google',
    //             options: {
    //                 redirectTo,
    //             },
    //             queryParams: {
    //                 access_type: 'offline',
    //                 prompt: 'consent',
    //             }
    //         });
    //         if (error) throw error;
    //         console.log('Redirecting to:', redirectTo);
    //         console.log('Google sign-in successful:', user, session);
    //
    //     } catch (error) {
    //         console.error('Google sign-in error:', error);
    //     }
    // };


    const handleDiscordSignIn = async () => {
        try {
            // Note: redirectTo is part of the options object for the provider.
            //eslint-disable-next-line
            const {user, session, error} = await supabase.auth.signInWithOAuth({
                provider: 'discord',
                options: {
                    redirectTo: `${window.location}`,
                },
            });

            if (error) throw error;
            // console.log('Discord sign-in successful:', user, session);
        } catch (error) {
            // console.error('Discord sign-in error:', error);
            setError(error.message); // Ensure setError is defined and can manage component state
        }
    };

    const AuthRef = useRef(null);
    useEffect(() => {
        // Function to call onClose if the click is outside the modal
        const handleClickOutside = (event) => {
            if (AuthRef.current && !AuthRef.current.contains(event.target)) {
                onClose(); // Call your onClose function if the click is outside
            }
        };

        // Attach the event listener to the document
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener from the document when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);


    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const handleForgotPassword = () => {
        setShowResetPasswordModal(true); // Show the reset password modal
    };


    //TODO allow form submission on enter


    return (

        <div className="profile-edit-overlay">


            <div className="auth-modal" ref={AuthRef}>


                <div className="auth-container">

                    {isSignUp ? (

                        <>
                            <div className="signup-form">


                                <div className="main-upper-text-row">

                                    <div className="main-elyza-text-label">Let's get started</div>

                                    <div className="close-modal-icon" onClick={onClose}><CloseModalIcon/></div>

                                </div>
                                <div className="main-elyza-text-sublabel">Sign up for an Elyza account to create your
                                    own character experiences.
                                </div>
                                <div className="a-straight-line social-signup-divider"></div>

                                <div className="social-signup-container">
                                    <button className="social-signup-button google" onClick={handleGoogleSignIn}>
                                        <GoogleIcon className="social-signup-button-icon-google"/>
                                        <span className="social-signup-button-text">Sign up with Google </span>
                                    </button>
                                    <button className="social-signup-button discord" onClick={handleDiscordSignIn}>
                                        <DiscordIcon className="social-signup-button-icon-discord"/>
                                        <span className="social-signup-button-text">Sign up with Discord   </span>
                                    </button>
                                </div>

                                <form onSubmit={handleSignUp}>

                                    <div className="or-container">
                                        <div className="line"></div>
                                        <span className="or-label">OR</span>
                                        <div className="line"></div>
                                    </div>

                                    {/*TODO figure out if we need this*/}
                                    {/*<div className={`textbox-name ${nameError ? 'shake-animation' : ''}`}>*/}
                                    <div className={`textbox-name`}>
                                        <label className="label-sign-up-h">Username</label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Username"
                                        />

                                    </div>

                                    <div className={`textbox-email ${emailError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">Email</label>
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="support@elyza.app"  // Added placeholder attribute
                                        />
                                    </div>

                                    <div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>
                                        <label className="label-sign-up-h">Password</label>
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="●●●●●●●●●"  // Added placeholder attribute
                                        />
                                    </div>


                                    <button className='signup-button' onClick={handleSignUp}
                                            disabled={!name.length || !email.length || !password.length || isLoading || !captchaToken}>
                                        Sign up
                                    </button>

                                    {!captchaToken ?
                                        <Turnstile
                                            ref={captchaRef}
                                            style={{
                                                display: "none",
                                            }}
                                            siteKey="0x4AAAAAAAWnS3GcUrv9riNn"
                                            onSuccess={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        /> : null
                                    }

                                    {error && <div className="error-message">{error}</div>}
                                    <div className="tos">
                                        By signing up, you also agree with the
                                        <Link to="/legal/terms-of-use" className="underline">
                                            Terms of Use & Privacy Policy.
                                        </Link> which may be subject to change.
                                    </div>

                                    {isLoading && <div>Loading...</div>}


                                    <div style={{marginTop: '15px'}} className="a-straight-line"></div>


                                    <button onClick={handleAuthSwitch}
                                            className="popup-link popup-link-bottom">
                                        Already have and account?
                                        <span className="underline">Log In</span>
                                    </button>


                                </form>

                            </div>

                        </>

                    ) : (
                        <>
                            <div className="login-container">
                                <div className="main-upper-text-row">

                                    <div className="main-elyza-text-label">Welcome back</div>
                                    <div className="close-modal-icon" onClick={onClose}><CloseModalIcon/></div>

                                </div>
                                <div className="main-elyza-text-sublabel">Log in to your account to access all your
                                    characters and start chatting.
                                </div>
                                <div className="a-straight-line social-signup-divider"
                                     style={{marginTop: '10px'}}
                                ></div>
                                <div className="social-signup-container">
                                    <button className="social-signup-button google" onClick={handleGoogleSignIn}>
                                        <GoogleIcon className="social-signup-button-icon-google"/>
                                        <span className="social-signup-button-text">Log in with Google </span>
                                    </button>

                                    <button className="social-signup-button discord" onClick={handleDiscordSignIn}>
                                        <DiscordIcon className="social-signup-button-icon-discord"/>
                                        <span className="social-signup-button-text">Log in with Discord   </span>
                                    </button>
                                </div>

                                <div className="or-container"
                                     style={{marginBottom: '-10px'}}
                                >
                                    <div className="line"></div>
                                    <span className="or-label">OR</span>
                                    <div className="line"></div>
                                </div>

                                <form onSubmit={handleLogin}>

                                    <div className="textbox-email">
                                        <label className="text-field-header">Email</label>
                                        <input
                                            type="email"
                                            id="login-email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="support@elyza.app"
                                            className="textbox-emailinput"
                                        />
                                    </div>

                                    <div className="textbox-password">
                                        <label className="text-field-header">Password</label>
                                        <input
                                            type='password'
                                            id="login-password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="●●●●●●●●●"
                                            className="textbox-passwordinput"
                                        />
                                    </div>


                                    <button
                                        type="submit"
                                        className="signup-button"
                                        onClick={handleLogin}
                                        disabled={!email.length || !password.length || isLoading || !captchaToken}
                                    >
                                        Log in
                                    </button>
                                    {!captchaToken ?
                                        <Turnstile
                                            ref={captchaRef}
                                            style={{
                                                display: "none",
                                            }}
                                            siteKey="0x4AAAAAAAWnS3GcUrv9riNn"
                                            onSuccess={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        /> : null
                                    }

                                    {errorMessage && <div className="error-message">{errorMessage}</div>}

                                </form>

                                <div className="a-straight-line-2"></div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <button onClick={handleAuthSwitch}
                                            className="popup-link popup-link-bottom">
                                        Don't have an account?
                                        <span className="underline">Sign up</span>
                                    </button>

                                    <button onClick={handleForgotPassword}
                                            className="popup-link">
                                        Forgot your password?
                                        <span className="underline">Reset it</span>
                                    </button>
                                </div>
                                <ResetPasswordPage
                                    show={showResetPasswordModal}
                                    onHide={() => setShowResetPasswordModal(false)}
                                />

                            </div>
                        </>
                    )}
                    {/*<button onClick={handleAuthSwitch}>*/}
                    {/*    {isSignUp ? 'Already have an account? Log In' : "Don't have an account? Sign Up"}*/}
                    {/*</button>*/}

                </div>

            </div>

        </div>

    );
};

