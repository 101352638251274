import React, {useState} from 'react';
import './NewMainPage.css';
import {NewCharacterList} from "../components/MainMenuPage/NewCharacterList";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {CharacterContextProvider} from '../components/Context/CharacterContext';

export const MenuPageUpdated = () => {

// eslint-disable-next-line
    const [selectedTab, setSelectedTab] = useState('Main');
    const [searchText, setSearchText] = useState('');
    const [realChars, setRealChars] = useState([]);
    const [searchCharacters, setSearchCharacters] = useState([]);

    return (
        <CharacterContextProvider value={{realChars, setRealChars}}>
            <div className="MainNewPage">
                <HeaderMainMenu searchText={searchText} setSearchText={setSearchText}
                                setSearchCharacters={setSearchCharacters}/>
                <div className="CharacterListH">
                    <NewCharacterList
                        ownCharacters={selectedTab === "Created Characters"}
                        search={searchText}
                        setSearchText={setSearchText}
                        tag={"All"}
                        searchCharacters={searchCharacters}
                        searchText={searchText}
                        setSearchCharacters={setSearchCharacters}
                    />
                </div>
            </div>
        </CharacterContextProvider>
    );
};