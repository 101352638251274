import React from "react";
import './SalesPage.css';
import SalesNavbar from "../components/Sales&SEO/SalesNavbar";
import CarouselPage from "../components/Sales&SEO/CarouselPage";
import Features from "../components/Sales&SEO/Features";
import FrequentQuestions from "../components/Sales&SEO/FrequentQuestions";
import Footer from "../components/Sales&SEO/Footer";
import {Element} from 'react-scroll';

export const SalesPage = () => {
    return (
        <div className="background-sales-page">
            <div className="overalls">
                <SalesNavbar/>
                <Element name="showcase">
                    <CarouselPage/>
                </Element>
                <div className='features-gradient'>
                    <Element name="features">
                        <Features/>
                    </Element>
                </div>
                <div className='frequent-questions-bg'>
                    <Element name="faq">
                        <FrequentQuestions/>
                    </Element>
                </div>
                <Footer/>
            </div>
        </div>

    );
};
