import React, { useContext, createContext, useState } from 'react';

// Create a Context
const TimerContext = createContext();

// Provider in your app
export const TimerProvider = ({ children }) => {
    const [timerExpired, setTimerExpired] = useState(null);

    // Optionally initialize or modify state based on localStorage or other logic here

    const value = { timerExpired, setTimerExpired };

    return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
};

// Custom hook to use the timer context
export const useTimer = () => {
    const context = useContext(TimerContext);
    if (context === undefined) {
        throw new Error('useTimer must be used within a TimerProvider');
    }
    return context;
};
