import {ReactComponent as ImageGenIcon} from "../../icons/message/image-add.svg";
import {ReactComponent as RegenIcon} from "../../icons/message/regen.svg";
import {ReactComponent as DeleteIcon} from "../../icons/message/trash.svg";
import {ReactComponent as ContinueIcon} from "../../icons/message/continue.svg";
import {ReactComponent as AudioIcon} from "../../icons/message/audio.svg";
import Tooltip from '@mui/material/Tooltip';
import "./MessageButtonSelection.css";
import React, {useState} from "react";
import DeleteMessageModal from './DeleteMessageModal'; // Adjust the path as necessary

export const MessageButtonSelection = ({regenerateEnabled, imageEnabled, audioEnabled, deleteEnabled, continueEnabled, messageId, generateImage, loading, setLoading, hasImage, regenerateMessage, isLatest, deleteMessage,  handleContinueMessage, handleGenerateAudio, hasAudio }) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const internalGenerateImage = async () => {
        if(loading || hasImage()) return;
        setLoading(true);
        await generateImage();
        setLoading(false);
    }

    const internalRegenerateMessage = async () => {
        if(loading || !isLatest) return;
        setLoading(true);
        await regenerateMessage();
        setLoading(false);
    }

    const internalGenerateAudio = async () => {
        if(loading || hasAudio()) return;
        setLoading(true);
        await handleGenerateAudio();
        setLoading(false);


    }



    const internalDeleteMessage = async () => {
        if(loading || !isLatest) return;
        setLoading(true);
        await deleteMessage();
        setLoading(false);
    }


    return (
        <div className="selection-menu-general">

            <div className="app-modal-backdrop">
                {showDeleteModal && (

                    <DeleteMessageModal
                        isVisible={showDeleteModal}
                        onDeleteConfirm={internalDeleteMessage}
                        onCancel={() => setShowDeleteModal(false)}
                    />

                )}
            </div>

            <div className="icons_functional">
                {imageEnabled && !loading && (
                    <Tooltip title="Generate Image" arrow>
                        <div className={hasImage() ? "image-gen-button-off" : "image-gen-button"} onClick={internalGenerateImage}>
                            <ImageGenIcon/>
                        </div>
                    </Tooltip>
                )}

                {audioEnabled && !loading &&  (
                    <Tooltip title="Voice Message" arrow>
                        <div className={!hasAudio() ? "voice-button" : "voice-button-off"} onClick={internalGenerateAudio}>
                            <AudioIcon/>
                        </div>
                    </Tooltip>
                )}



                {regenerateEnabled && !loading && (
                    <Tooltip title="Regenerate Message" arrow>
                        <div className={isLatest ? "regenerate-button" : "regenerate-button-off"} onClick={internalRegenerateMessage}>
                            <RegenIcon/>
                        </div>
                    </Tooltip>
                )}
                {continueEnabled && !loading && (
                    <Tooltip title="Continue Message" arrow>
                        <div className={isLatest ? "continue-button" : "continue-button-off"} onClick={handleContinueMessage}>
                            <ContinueIcon/>
                        </div>
                    </Tooltip>
                )}
                {deleteEnabled && !loading && (
                    <Tooltip title="Delete Message" arrow>
                        <div className={isLatest ? "delete-message-button" : "delete-message-button-off"} onClick={() => setShowDeleteModal(true)}>
                            <DeleteIcon/>
                        </div>
                    </Tooltip>
                )}


            </div>
        </div>
    );
};
