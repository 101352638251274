import React from 'react';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import Cookies from 'js-cookie';

const GenerateImagePopup = ({isOpen, onClose, children}) => {


    const handleDoNotShowAgainClick = () => {
        onClose();
        Cookies.set('hideGenerateImagePopup', 'true', { expires: 30 }); // Set a cookie that expires in 30 days
    };



    if (!isOpen) return null;
    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    ✋ Please Wait!
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        Here are some tips and tricks to help you get started with generating a profile image.
                    </div>
                    <div className='sublabel-notification'>
                        Changing or removing your base image can significantly alter the results of your image
                        generation. If your first result isn't perfect, try a different style!
                    </div>
                    <div className='sublabel-notification'>
                        Basic users can generate up to <span className="colored-purple-text">5 images</span>.
                        Premium users, however, utilize their <span
                        className="colored-purple-text">image limits </span> depending on the plan.
                    </div>
                </div>

                <button onClick={onClose} className="notification-button-main">Got it.</button>
                <button onClick={handleDoNotShowAgainClick} className="notification-button-main-gray">Do not show
                    again.
                </button>
            </div>
        </div>
    );
};

export default GenerateImagePopup;