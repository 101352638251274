import React from "react";
import "./UserOutputChat.css";
import "../../pages/ChatPage.css";

const ChatContainer = ({text, imageUrl, imageData, optionalUsername, optionalProfile}) => { // TODO: THE EXTRA 2 PARAMETERS ONLY COME FOR GCs



    function italicizeAsterisksText(text, isLastLine) {
        // Trim the message and replace multiple consecutive newline characters with a single newline character
        text = text.trim().replace(/\n+/g, '\n');
        const splitText = text.split(/(\*[^*]+\*)/);
        return splitText.map((segment, index, array) => {
            const isLastSegment = index === array.length - 1;
            if (segment.startsWith('*') && segment.endsWith('*')) {
                return (
                    <React.Fragment key={index}>
                        <i>{segment.slice(1, -1)}</i>
                        {!isLastSegment || !isLastLine ? (
                            <br style={{ lineHeight: '0.5', margin: '0.25em 0' }} />
                        ) : null}
                    </React.Fragment>
                );
            }
            return segment;
        });
    }

    const imageSrc = imageUrl != null && true ? imageUrl : imageData;

    return (
        <div className="user-chat-container-main">






    <div className="user-chat-container">
            <div className="user-text">


                {text.split('\n').map((line, index, array) => {
                    const isLastLine = index === array.length - 1;
                    return (
                        <React.Fragment key={index}>
                            {italicizeAsterisksText(line, isLastLine)}
                            {!isLastLine ? (
                                <br style={{lineHeight: '0.5', margin: '0.25em 0'}}/>
                            ) : null}
                        </React.Fragment>

                    );
                })}
            </div>
    </div>

            <div className="user-attached-image">
                <img
                    className="user-attached-image-actual-image"
                    src={imageSrc}
                    alt=""
                    style={{
                        border: imageSrc ? '1px solid #C4C4C4' : 'none'
                    }}
                />
            </div>

        </div>

    );
};

export default ChatContainer;
