import ReactDOM from "react-dom";
import React from "react";
import {ReactComponent as CloseIcon} from "../../icons/cross.svg";


export const ExitIntent = ({onClose}) => {
    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    ✋ Please Wait!
                    <div className="modal-close-icon-notification" onClick={() => {
                        onClose()
                    }}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        Heading out? You might want to test our <span
                        className="colored-purple-text">premium features</span> first!
                    </div>
                </div>


                <button
                    onClick={() => {
                        onClose();
                        window.location.href = 'https://elyza.app/pricing';
                    }}
                    className="notification-button-main"
                    style={{marginTop: '15px'}}
                >
                    Yes please!
                </button>

            </div>
        </div>
        ,
        document.getElementById('modal-root')
    );
};