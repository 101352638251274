import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import './ReferralMenus.css';
import {ReactComponent as CopyPasteIcon} from '../../../icons/copy-paste.svg';
import ProductModal from "./ProductModal";
import ImageProductModal from "./ImageProductModal";
import {ReactComponent as MoreMessageIcon} from '../../../icons/MoreMessagesIcon.svg';
import {ReactComponent as MoreImagesIcon} from '../../../icons/MoreImagesIcon.svg';
import {ReactComponent as StackCoins} from '../../../icons/stack-o-coins.svg';
import {ReactComponent as FacebookCircle} from '../../../icons/facebook.svg';
import {ReactComponent as InstagramCircle} from '../../../icons/instagram.svg';
import {ReactComponent as SnapchatCircle} from '../../../icons/snapchat.svg';
import {ReactComponent as RedditCircle} from '../../../icons/redditsocial.svg';
import {ReactComponent as DiscordCircle} from '../../../icons/discordsocial.svg';
import {ReactComponent as TwitterCircle} from '../../../icons/xsocial.svg';
import {useProfileData} from "../../Context/ProfileDataContext";

// Assuming ReferralRedeemMenu content is ready to be integrated
const ReferralMenu = ({
                          isOpen, onCancel,
                      }) => {


    const { profileData, rewardCoins, setRewardCoins } = useProfileData();
    const [bonusMessages, setBonusMessages] = useState(profileData.bonus_messages);
    const [bonusImages, setBonusImages] = useState(profileData.bonus_images);


    const [menuState, setMenuState] = useState('earn');
    const [copySuccess, setCopySuccess] = useState(false);
    const [copyMessage, setCopyMessage] = useState(`https://elyza.app?referral=${profileData.referral}`);


    const [isProductModalOpen, setProductModalOpen] = useState(false);


    const handleProductClick = () => {
        setProductModalOpen(true);
    };

    const closeProductModal = () => {
        setProductModalOpen(false);
    };


    const [isProductImageModalOpen, setProductImageModalOpen] = useState(false);

    const handleProductImageClick = () => {
        setProductImageModalOpen(true);
    };

    const closeProductImageModal = () => {
        setProductImageModalOpen(false);
    };

    const handleCopy = async () => {
        if (!copySuccess) { // Only proceed if the last copy operation was not successful yet
            try {
                await navigator.clipboard.writeText(`https://elyza.app?referral=${profileData.referral}`);
                setCopySuccess(true);
                setCopyMessage("Copied successfully!");
                setTimeout(() => {
                    setCopySuccess(false);
                    setCopyMessage(`https://elyza.app?referral=${profileData.referral}`); // Reset copyMessage to the original link after 2 seconds
                }, 2000);
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }
    };


    const handleRedeemClick = () => {
        setMenuState('redeem');
    };

    const handleGoBackClick = () => {
        setMenuState('earn');
    };


    useEffect(() => {
        // Add event listener to handle Esc key press
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onCancel(); // Close modal when Esc key is pressed
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onCancel]);

    if (!isOpen) return null; // Early return if not open


    return ReactDOM.createPortal(<div className="main-space-finder-modal">
        <div className="app-modal-content-referral" onClick={e => e.stopPropagation()}>
            <div className="close-modal-icon" onClick={() => setMenuState('')}><CloseIcon/></div>


            {menuState === 'earn' && (<>
                    <div className="main-space-finder-modal" onClick={onCancel}>
                        <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>

                            <div className="main-upper-text-row">
                                <div className="profile-setting-label">Refer and earn now!</div>
                                <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>

                            </div>
                            <div className="profile-setting-sublabel">Share your referral code with people who wish
                                to join the Elyza community.
                            </div>


                            <div className={`copy-paste-container ${copySuccess ? 'copy-success' : ''}`}
                                 onClick={handleCopy}>
                                <div className="copy-paste-container-insides">
                                    {copyMessage}
                                    <CopyPasteIcon className="copy-paste-icon"/>
                                </div>
                            </div>


                            <div className="line-container">
                                <span className="text-line-container">SHARE ON</span>
                                <div className="line"></div>
                            </div>


                            <div className="social-media-row">

                                <a href="https://www.facebook.com" target="_blank"
                                   rel="noopener noreferrer">
                                    <FacebookCircle className="social-icon"/>
                                </a>
                                <a href="https://www.instagram.com" target="_blank"
                                   rel="noopener noreferrer">
                                    <InstagramCircle className="social-icon"/>
                                </a>
                                <a href="https://www.snapchat.com" target="_blank"
                                   rel="noopener noreferrer">
                                    <SnapchatCircle className="social-icon"/>
                                </a>
                                <a href="https://www.reddit.com" target="_blank"
                                   rel="noopener noreferrer">
                                    <RedditCircle className="social-icon"/>
                                </a>
                                <a href="https://discord.gg" target="_blank"
                                   rel="noopener noreferrer">
                                    <DiscordCircle className="social-icon"/>
                                </a>
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                    <TwitterCircle className="social-icon"/>
                                </a>

                            </div>


                            <div className="line-container">
                                <span className="text-line-container">REWARDS</span>
                                <div className="line"></div>
                            </div>


                            <div className="new-pricing-subtitle">
                                <span>You will receive </span>
                                <span className="highlight-text-price">50 coins</span>
                                <span> for a Sign Up.</span>
                            </div>
                            <div className="new-pricing-subtitle">
                                <span>You will receive </span>
                                <span className="highlight-text-price">2000 coins</span>
                                <span> for a user who makes a purchase.</span>
                            </div>

                            <div className="line-container">
                                <span className="text-line-container">TRACKER</span>
                                <div className="line"></div>
                            </div>


                            <div className="new-pricing-subtitle">
                                <span className="highlight-text-price">{profileData.referral_count} Users </span>
                                <span>have signed up using your referral code.</span>
                            </div>
                            <div className="new-pricing-subtitle">
                                <span className="highlight-text-price">{profileData.referral_purchase_count} Users </span>
                                <span>have bought a plan using your referral code.</span>
                            </div>


                            <div className="line-container">
                                <span className="text-line-container">BALANCE</span>
                                <div className="line"></div>
                            </div>
                            <div className="new-pricing-subtitle">
                                <span>You currently have</span>
                                <span className="highlight-text-price"> {rewardCoins} coins </span>
                                <span>to redeem.</span>
                            </div>

                            <div className="parent">
                                <button className="redeem-button" onClick={handleRedeemClick}>Redeem</button>
                            </div>
                        </div>


                    </div>

                </>)}


            {menuState === 'redeem' && (<>
                    <div className="main-space-finder-modal" onClick={onCancel}>
                        <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>

                            <div className="main-upper-text-row">
                                <div className="profile-setting-label">Redeem your coins here!</div>
                                <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>

                            </div>
                            <div className="profile-setting-sublabel">Spend your well earned Elyza coins here to
                                purchase additional
                                features and addons.
                            </div>

                            <div className="line-container">
                                <span className="text-line-container">BALANCE</span>
                                <div className="line"></div>
                            </div>


                            <div className="balance-container">
                                <div className="balance-row">
                                    <div className="balance-text">Current Balance:</div>
                                    <div className="balance-number">
                                            <span className="highlight-text-price">
  {rewardCoins === 0 ? 'None' : rewardCoins}
</span>


                                        <StackCoins/>
                                    </div>
                                </div>
                            </div>
                            <div className="counter-top-container-gap">
                                <div className="balance-row">
                                    <div className="balance-text">Messages Left:</div>
                                    <div className="balance-number">
                                        <span className="highlight-text-price">{bonusMessages}</span>
                                        <MoreMessageIcon/>
                                    </div>
                                </div>
                                <div className="balance-row">

                                    <div className="balance-text">Images Left:</div>
                                    <div className="balance-number">
                                        <span className="highlight-text-price">{bonusImages}</span>
                                        <MoreImagesIcon/>
                                    </div>
                                </div>
                            </div>

                            <div className="line-container">
                                <span className="text-line-container">PRODUCTS</span>
                                <div className="line"></div>
                            </div>

                            {/*TODO: STRUCTURE WILL CAUSE BUGS ON MORE THAN 2 LINES LMAO*/}
                            <div className="product-column-menu">
                                <div className="product-row" onClick={handleProductClick}>
                                    <div className="circle">
                                        <MoreMessageIcon className="inside-product-icon"/>
                                    </div>
                                    <div className="product-row-text">
                                        <span className="product-head">More Messages</span>
                                        <span className="product-desc">Here you can buy additional messages for the tokens you earned.</span>
                                    </div>
                                </div>
                                <div className="product-row" onClick={handleProductImageClick}>
                                    <div className="circle">
                                        <MoreImagesIcon className="inside-product-icon"/>
                                    </div>

                                    <div className="product-row-text">
                                        <span className="product-head">More Images</span>
                                        <span className="product-desc">Here you can buy additional images for the tokens you earned.</span>
                                    </div>

                                </div>
                                {/*<div className="product-row" onClick={handleProductClick}>*/}
                                {/*    <div className="circle">*/}
                                {/*        <MoreImagesIcon className="inside-product-icon"/>*/}
                                {/*    </div>*/}
                                {/*    <div className="product-row-text">*/}
                                {/*        <span className="product-head">Header Text 2</span>*/}
                                {/*        <span className="product-desc">Product Description 2</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>


                            <div className="line-container">
                                <span className="text-line-container">OPTIONS</span>
                                <div className="line"></div>
                            </div>

                            <div className="new-pricing-subtitle">
                                <span>The currency presented here serves no real value outside of Elyza and its services.</span>
                            </div>

                            <div className="parent">
                                <button className="go-back-button" onClick={handleGoBackClick}>Go Back</button>
                            </div>


                            <ProductModal
                                isOpen={isProductModalOpen}
                                onClose={closeProductModal}
                                rewardCoins={rewardCoins}
                                bonusMessages={bonusMessages}
                                bonusImages={bonusImages}
                                setRewardCoins={setRewardCoins}
                                setBonusImages={setBonusImages}
                                setBonusMessages={setBonusMessages}
                            />

                            <ImageProductModal
                                isOpen={isProductImageModalOpen}
                                onClose={closeProductImageModal}
                                rewardCoins={rewardCoins}
                                bonusMessages={bonusMessages}
                                bonusImages={bonusImages}
                                setRewardCoins={setRewardCoins}
                                setBonusImages={setBonusImages}
                                setBonusMessages={setBonusMessages}
                            />

                        </div>
                    </div>


                </>)}
        </div>
    </div>, document.body);
};

export default ReferralMenu;
