import React from 'react';
import {useNavigate} from 'react-router-dom';
import './GeneralLegal.css';
import {ReactComponent as BackIcon} from "../../components/Forms/FormsIcons/back.svg";
import Markdown from "react-markdown";
import content from "./PrivacyPolicy.md";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const [markdown, setMarkdown] = React.useState('');

    React.useEffect(() => {
        fetch(content)
            .then((response) => response.text())
            .then((text) => setMarkdown(text));
    }, []);


    return (<div>
        <button className="GoBackButton" onClick={() => navigate("/legal")}>
            <BackIcon/>
        </button>

        <div className="tos-container">
            <Markdown className="tos-content">
                {markdown}
            </Markdown>
        </div>
    </div>);
}

export default PrivacyPolicy;
