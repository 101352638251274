// TagContext.js
import React, { createContext, useState, useContext } from 'react';

const TagContext = createContext();

export const useTags = () => useContext(TagContext);

export const TagProvider = ({ children }) => {
    const [tags] = useState(['Anime', 'Real', 'Furry', 'Gay', 'Lesbian', 'Bisexual', 'Femboy', 'Futanari', 'Tomboy', 'Goth', 'Family', 'Celebrity', 'YouTuber', 'VTuber', 'Non-English', 'Manga', 'Videogames', 'Cartoon', 'Teenager', 'School', 'Girlfriend', 'Wholesome', 'Romantic', 'Cute', 'Drama', 'Childhood Friend', 'Wife', 'BDSM', 'Femdom', 'Non-Consensual', 'MILF', 'Chubby', 'Original', 'Mind Control', 'Non-Human', 'Size Difference', 'Historical', 'Kink']);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleTagClick = (tag) => {
        setSelectedTags(prevTags => prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]);
    };


    return (
        <TagContext.Provider value={{ tags, selectedTags, setSelectedTags, handleTagClick }}>
            {children}
        </TagContext.Provider>
    );
};
