import React from 'react';
import './BannerStyle.css';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';

const UpgradePlanBanner = ({ isBannerVisible, hideBanner }) => {
    const handleClick = () => {
        window.location.href = '/pricing';
    };

    // Use hideBanner function when "Remind me to upgrade later" is clicked
    const handleHideBannerClick = () => {
        hideBanner(); // This will set the visibility state to false in the parent component
    };

    if (!isBannerVisible) return null;

    return (
        <div className="banner-container banner-state-3">
            <div className="banner-content">
                <div className="banner-left">
                    <span className="banner-title">Upgrade your plan</span>
                    <span className="banner-subtitle">Elyza Premium
                        <div className='banner-icon'></div> {/* No specific icon shown for this state */}
                    </span>
                </div>
                <div className="banner-right">
                    <div className="center-component-banner">
                        <button className="banner-button button-state-3" onClick={handleClick}>
                            <StarIcon className='star-icon-banner'/>
                            Upgrade Now
                        </button>
                        <span className='sub-button-text' onClick={handleHideBannerClick}>Remind me to upgrade later</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradePlanBanner;
