import './App.css';

import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import {ChatPage} from './pages/ChatPage';
import {createClient} from '@supabase/supabase-js';
import {SalesPage} from "./pages/SalesPage";
import {MenuPageUpdated} from "./pages/NewMainPage";
import NewPricingPage from './pages/PricingPage';
import CreateCharacterNew from './pages/CreateCharacter/CreateCharacterPage';
import TermsOfService from './pages/LegalPages/TermsOfUse';
import {UpdatePasswordPage} from "./components/Forms/UpdatePasswordPage";
import PrivacyPolicy from './pages/LegalPages/PrivacyPolicy';
import LegalNavigation from "./pages/LegalPages/LegalNavigation";
import DMCA from "./pages/LegalPages/DMCA";
import ErrorPage from './pages/ErrorPage/404Page';
import MicroTransactions from "./pages/MicroTransactions";
import React, {useEffect, useState} from "react";
import ReferralSuccessNotification from "./components/Notifications/ReferralSuccessNotification";
import UpgradeNotification from "./components/Notifications/UpgradeNotification";
import ImageGenerationNotification from "./components/Notifications/ImageGenerationNotification";
import UpgradeSaleNotification from "./components/Notifications/UpgradeSaleNotification";
import MaintenanceNotification from "./components/Notifications/MaintenanceNotification";
import {TimerProvider} from "./components/Context/TimerContext";
import {ProfileDataProvider} from "./components/Context/ProfileDataContext";
import Legal from "./pages/LegalPages/Legal";
import ContentRemoval from "./pages/LegalPages/ContentRemoval";
import PlanCongrats from "./components/Notifications/PlanCongrats";
import MicroTransCongrats from "./components/Notifications/MicroTransCongrats";
import {ChatProvider} from "./components/Context/ChatContext";
import ImageGenerationPage from "./pages/ImageGenerationPage";
import {ChatFunctionPasser} from "./components/Context/ChatFunctionsContext";
import JoinDiscordCommunity from "./components/Notifications/SpecialNotifications/JoinDiscordCommunity";

const SupaUrl = process.env.REACT_APP_SUPABASE_URL;
const SupaKey = process.env.REACT_APP_SUPABASE_KEY;
const SupaBearerKey = `Bearer ${SupaKey}`;

export const supabase = createClient(SupaUrl, SupaKey);

function App() {
    const [notifications, setNotifications] = useState(null);
    const [lastFetchTime, setLastFetchTime] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        const storedLastFetchTime = localStorage.getItem('lastFetchTime');
        setLastFetchTime(storedLastFetchTime ? parseInt(storedLastFetchTime) : null);
    }, []);

    useEffect(() => {
        const fetchNotifications = async () => {
            if (!isLoggedIn) {
                return;
            }
            try {
                const response = await fetch('https://api.elyza.app/v1/user-notifications', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({
                        userId: await getUserID(),
                    })
                });
                const data = await response.json();

                if (data.notifications.length > 0) {
                    setNotifications(data.notifications);
                } else {
                    setNotifications(null);
                }

                setLastFetchTime(Date.now());
                localStorage.setItem('lastFetchTime', Date.now().toString());
            } catch (error) {
                // console.error('Error fetching notifications:', error);
            }
        };

        const thirtySecondsInMs = 30 * 1000;
        const timeSinceLastFetch = lastFetchTime ? Date.now() - lastFetchTime : Infinity;

        if (timeSinceLastFetch >= thirtySecondsInMs) {
            fetchNotifications();
        } else {
            const delay = thirtySecondsInMs - timeSinceLastFetch;
            setTimeout(fetchNotifications, delay);
        }
    }, [lastFetchTime, isLoggedIn]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const referralCode = queryParams.get('referral');
        if (referralCode) {
            localStorage.setItem('referralCode', referralCode);
        }

        const affCode = queryParams.get('aff');
        if (affCode) {
            localStorage.setItem('affCode', affCode);
        }
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const campaignCode = queryParams.get('campaign');
        const targetType = queryParams.get('type');
        const typesArray = targetType ? targetType.split(',').map(type => {
            switch (type.trim()) { // Trim to remove any accidental leading/trailing whitespace
                case 'r':
                    return 'Realistic';
                case 'a':
                    return 'Anime';
                case 'f':
                    return 'Furry';
                default:
                    return type; // Return the type as is if it doesn't match any case
            }
        }) : [];

        if (campaignCode) {
            localStorage.setItem('campaignCode', campaignCode);
            renderCampaignClick(campaignCode).then((response) => {
            });
        }

        if (targetType) {
            localStorage.setItem('type', JSON.stringify(typesArray));
        }
    }, []);

    const renderCampaignClick = async (campaignCode) => {
        try {
            const response = await fetch('https://api.elyza.app/v1/render-campaign-click', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({
                    campaign: campaignCode
                })
            });
            return await response.json();
        } catch (error) {
            // console.error('Error rendering campaign click:', error);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            let url = new URL(window.location.href);
            url.searchParams.delete('aff');
            url.searchParams.delete('campaign');
            url.searchParams.delete('referral');
            window.history.replaceState({}, document.title, url);
        }, 1000);
    }, []);


    return (
        <div>
            <div id="modal-root"></div>
            <ProfileDataProvider>
                <ChatFunctionPasser>
                    {notifications && notifications.length > 0 && (
                        <div>
                            {notifications.map((notification) => (
                                <div key={notification.id}>
                                    {renderNotificationComponent(notification.notification_type, notification.id, notification.reward_type, notification.reward_amount, notifications, setNotifications, notification.message)}
                                </div>
                            ))}
                        </div>
                    )}
                    <ChatProvider>
                        <TimerProvider>
                            <Router>
                                <Routes>
                                    <Route path="/main" element={<Navigate to="/"/>}/>
                                    <Route path="/" element={<MenuPageUpdated/>}/>
                                    <Route path="/chat" element={<ChatPage/>}/>
                                    <Route path="/chat/:characterId" element={<ChatPage/>}/>
                                    <Route path="/chat/gc/:chatId" element={<ChatPage/>}/>
                                    <Route path="/update-password" element={<UpdatePasswordPage/>}/>
                                    <Route path="/sales" element={<SalesPage/>}/>
                                    <Route path="/addons" element={<MicroTransactions/>}/>
                                    {/*<Route path="/verify-email" element={<EmailConfirmation/>}/>*/}

                                    <Route path="/legal" element={<LegalNavigation/>}/>
                                    <Route path="/legal/dmca" element={<DMCA/>}/>
                                    <Route path="/legal/other" element={<Legal/>}/>
                                    <Route path="/legal/content" element={<ContentRemoval/>}/>
                                    <Route path="/legal/terms-of-use" element={<TermsOfService/>}/>
                                    <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>}/>
                                    <Route path="/pricing" element={<NewPricingPage/>}/>
                                    <Route path="/create-character" element={<CreateCharacterNew/>}/>
                                    <Route path="*" element={<ErrorPage/>}/>
                                    <Route path="/gallery/:id" element={<ImageGenerationPage/>}/>
                                    {/*<Route path="/dev" element={<UltimateNotificationTester/>}/>*/}
                                </Routes>
                            </Router>
                        </TimerProvider>

                    </ChatProvider>
                </ChatFunctionPasser>
            </ProfileDataProvider>
        </div>
    );
}

const renderNotificationComponent = (type, notificationId, rewardType, rewardAmount, notifications, setNotifications, message = '') => {
    switch (type) {
        case 'REFERRAL':
            return <ReferralSuccessNotification onClose={async () => {
                // remove notification with id
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
                // TODO: Navigate to referral store
            }} purchase={false} coins={rewardAmount} type={'COINS'} isOpen={true}/>;
        case 'REFERRAL_PURCHASE':
            return <ReferralSuccessNotification onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
                // TODO: Navigate to referral store
            }} purchase={true} coins={rewardAmount} type={'COINS'} isOpen={true}/>;
        case 'MESSAGE':
            return <MaintenanceNotification onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'UPSELL':
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'REIMBURSE':
            return <ImageGenerationNotification onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true} images={rewardAmount}/>;
        case 'REWARD':
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'DISCORD':
            return <JoinDiscordCommunity onClose={async () => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
        case 'SUBSCRIPTION':
            return <PlanCongrats onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                // if (redirect) {
                //     window.location.href = 'https://elyza.app/pricing';
                // }
                await receiveNotification(notificationId)
            }} isOpen={true} plan={message}/>;
        case 'MICROTRANSACTION':
            return <MicroTransCongrats onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                // if (redirect) {
                //     window.location.href = 'https://elyza.app/addons';
                // }
                await receiveNotification(notificationId)
            }} isOpen={true} images={parseInt(message.split('|')[0], 10)}
                                       audios={parseInt(message.split('|')[1], 10)}/>;
        // TODO: Add daily gift system
        // return <DailyGiftNofification/>;
        case 'DISCOUNT':
            return <UpgradeSaleNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true} percent={rewardAmount}/>;
        default:
            return <UpgradeNotification onClose={async (redirect) => {
                setNotifications(notifications.filter(notification => notification.id !== notificationId));
                if (redirect) {
                    window.location.href = 'https://elyza.app/pricing';
                }
                await receiveNotification(notificationId)
            }} isOpen={true}/>;
    }
};

const receiveNotification = async (notificationId) => {
    try {
        const response = await fetch('https://api.elyza.app/v1/redeem-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                userId: await getUserID(),
                notificationId,
            })
        });
        return await response.json();
    } catch (error) {
        // console.error('Error receiving notification:', error);
    }
}

export const getSessionResult = async () => {
    let result = await supabase.auth.getSession();
    if (result.error || !result.data.session) {
        result = await supabase.auth.refreshSession();
    }
    return result;
}

export const getUserID = async () => {
    try {
        const result = await getSessionResult();
        return result.data.session ? result.data.session.user.id : null;
    } catch (error) {
        console.error('No user found', error);
        await supabase.auth.signOut();
        window.location.href = '/';
        return null;
    }
}

export const getUserEmail = async () => {
    const result = await getSessionResult();
    return result.data.session ? result.data.session.user.email : null;
}

export const userIDExists = async () => {
    const result = await getSessionResult();
    return result.error === null && result.data.session !== null;
}

export const getUserAccessToken = async () => {
    if (await userIDExists() === false) {
        return SupaBearerKey;
    }

    const result = await getSessionResult();
    const session = result.data.session;
    return 'Bearer ' + session.access_token;
}


export default App;