import React, {useEffect, useRef, useState} from 'react';
import './TagSystemChat.css';

const poseList = [
    { label: "Oral Sex", actual: "Blowjob" },
    { label: "Ahegao", actual: "Ahegao" },
    { label: "Spread Ass", actual: "Spread Ass" },
    { label: "Futa", actual: "Futa" },
    { label: "Doggy Style", actual: "Doggy Style Face Down" },
    { label: "POV Missionary", actual: "POV Missionary-Raised Legs" },
    { label: "Suspended", actual: "Suspended Congress" },
    { label: "Reversed Congress", actual: "Reversed congress" },
    { label: "Goth", actual: "Goth" },
    { label: "Glory Hole", actual: "GloryHole Blowjob" },
    { label: "Femboy", actual: "Femboy Concept" },
    { label: "Deepthroat", actual: "Balls Deep Deepthroat" },
    { label: "Doggy Style", actual: "Doggy Style" },
    { label: "Handjob", actual: "Handjob [POV]" },
    { label: "Masturbation", actual: "Female Masturbation [Fingering]" },
    { label: "Reverse Cowgirl", actual: "Reverse Cowgirl Position" },
    { label: "Cowgirl", actual: "Holding Waist POV Cowgirl Position" },
    { label: "Spitroast", actual: "Standing Spitroast" },
    { label: "Ass on Glass", actual: "Ass on Glass" },
    { label: "Cum Swap", actual: "Cum Swap" },
    { label: "Bukkake", actual: "Bukkake" },
    { label: "Face Sitting", actual: "Face Sitting" },
    { label: "Cum on Tongue", actual: "Cum on Tongue" },
    { label: "Tentacles", actual: "Tentacles" },
    { label: "Chain Leash", actual: "Chain leash" },
    { label: "Titfuck", actual: "Titfuck" },
    { label: "BDSM - Ballgag", actual: "BDSM - Ballgag" },
    { label: "BDSM - Tape Gag", actual: "BDSM - Tape Gag" },
    { label: "Shibari", actual: "shibari" },
    { label: "BDSM - Chair Bondage", actual: "BDSM - Chair Bondage" },
    { label: "BDSM - Ring Gag", actual: "BDSM - Ring Gag" },
    { label: "BDSM - Tied to Bed", actual: "BDSM - Tied to bed" },
    { label: "Panty Gag", actual: "Panty Gag" },
    { label: "BDSM - Hog Tied", actual: "BDSM - Hog Tied" },
    { label: "Jack-o Pose", actual: "Jack-o Pose" },
    { label: "BDSM - Lying Bondage", actual: "BDSM - Lying Bondage" },
    { label: "BDSM - Blindfold Bondage", actual: "BDSM - Blindfold Bondage" },
];

const TagItem = ({ tag, selected, onTagClick }) => {
    const [isActive, setIsActive] = useState(false);


    const handleMouseDown = () => {
        if (!selected) {
            setIsActive(true);
        }
    };

    const handleMouseUp = () => {
        setIsActive(false);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };

    return (
        <div
            className={`tag-item ${selected ? 'selected' : ''} ${isActive && !selected ? 'active' : ''}`}
            onClick={() => onTagClick(tag.actual)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            {tag.label}
        </div>
    );
};

const TagSystemChatList = ({onSelectTag}) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const tagListRef = useRef(null);
    const requestRef = useRef();
    const [isScrolling, setIsScrolling] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const onTagClick = (actual) => {
        if (selectedTag === actual) {
            setSelectedTag(null);
            onSelectTag(null);
        } else {
            setSelectedTag(actual);
            onSelectTag(actual);
        }
    };

    const animateScroll = (direction) => {
        if (tagListRef.current) {
            const scrollAmount = direction === 'left' ? -5 : 5; // Smaller increment for smoother scroll
            tagListRef.current.scrollLeft += scrollAmount;
            requestRef.current = requestAnimationFrame(() => animateScroll(direction));
        }
    };

    const startScrolling = (direction) => {
        if (!isScrolling) {
            setIsScrolling(true);
            animateScroll(direction);
        }
    };

    let stopScrolling;
    stopScrolling = () => {
        if (isScrolling) {
            setIsScrolling(false);
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        }
    };










    // const scrollIntervalRef = useRef(null);
    const handleMouseLeave = () => {
        stopScrolling();
    };

    const handleMouseMove = (e) => {
        const tagListContainer = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const hoverMargin = 100; // Pixels from edge to trigger scroll

        // Check if cursor is within the bounds of the tag list container
        if (mouseX < tagListContainer.left || mouseX > tagListContainer.right || mouseY < tagListContainer.top || mouseY > tagListContainer.bottom) {
            stopScrolling();
            return;
        }

        if (mouseX - tagListContainer.left < hoverMargin) {
            startScrolling('left');
        } else if (tagListContainer.right - mouseX < hoverMargin) {
            startScrolling('right');
        } else {
            stopScrolling();
        }
    };


    useEffect(() => {
        return () => stopScrolling();
    }, [stopScrolling]);



    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };



    const checkScroll = () => {
        const node = tagListRef.current;
        if (node) {
            const atStart = node.scrollLeft === 0;
            const atEnd = node.scrollLeft + node.offsetWidth >= node.scrollWidth - 1;

            setCanScrollLeft(node.scrollLeft > 0);
            setCanScrollRight(!atEnd);

            if (atStart) {
                node.classList.add('no-blur-left');
            } else {
                node.classList.remove('no-blur-left');
            }

            if (atEnd) {
                node.classList.add('no-blur-right');
            } else {
                node.classList.remove('no-blur-right');
            }
        }
    };


    useEffect(() => {
        const node = tagListRef.current;
        if (node) {
            node.addEventListener('scroll', checkScroll);

            // Apply the no-blur-left class initially
            checkScroll();
        }

        // Cleanup
        return () => {
            if (node) {
                node.removeEventListener('scroll', checkScroll);
            }
        };
    }, []);



    return (
        <div
            className="tag-list-container-gallery"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            <div className={`tag-list ${canScrollLeft ? 'no-blur-left' : ''} ${canScrollRight ? 'no-blur-right' : ''}`}>
                <div className="tag-list-gradient" ref={tagListRef}>
                    {poseList.map((tag, index) => (
                        <TagItem
                            key={tag.actual}
                            tag={tag}
                            selected={selectedTag === tag.actual} // change isSelected to selected
                            onTagClick={onTagClick}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const TagSystemChat = ({setTag}) => {


    return (
        <TagSystemChatList onSelectTag={setTag}/>
    );
};