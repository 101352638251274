import Tooltip from "@mui/material/Tooltip";
import React from 'react';
import {ReactComponent as GenerateImagePicture} from "../../icons/generateImage.svg";
import {useTimer} from "../Context/TimerContext";

export const LargeGalleryBanner = ({isGalleryMounted, isLargeScreen, isGalleryBannerVisible, profileURLBanner, currentCharName, galleryPlaceholders, handleClick, updatedScenario, updatedDescription}) => {
    const {timerExpired} = useTimer();

    if (!isGalleryMounted && isLargeScreen) return null;

    const replaceCharName = (text) => {
        // Replace {{char}} with currentCharName on text and return
        return text.replace(/{{char}}/g, currentCharName);
    }

    let trimmedScenario = updatedScenario.length > 250 ? updatedScenario.substring(0, 247) + '...' : updatedScenario;
    let trimmedDescription;
    if (window.innerHeight > 980) {
        trimmedDescription = updatedDescription.length > 200 ? updatedDescription.substring(0, 197) + '...' : updatedDescription;
    } else { // for screens narrower than 800px
        trimmedDescription = updatedDescription.length > 60 ? updatedDescription.substring(0, 57) + '...' : updatedDescription;
    }


    return (
        <>
            {isGalleryBannerVisible && <div className="backdrop-epic-darken-mobile">
                <div className='gallery-container-h'
                        style={{minHeight: !timerExpired ? 'calc(100vh - 145px)' : 'calc(100vh - 85px)'}}
                >
                    <div className='image-banner-right-gallery'>
                        <img
                            src={profileURLBanner}
                            alt=''
                            className={'image-gallery-banner'}
                            style={{pointerEvents: 'none', height: isLargeScreen ? '380px' : '360px'}}
                        />
                    </div>
                    <div className="section-wrapper">
                        <div className="gallery-text-banner">
                            <div className="char-name-banner"
                                 style={{fontSize: isLargeScreen ? '1.55em' : ''}}
                            >{currentCharName}</div>
                            <Tooltip title={replaceCharName(updatedDescription)} placement="top" arrow
                                     style={{fontSize: isLargeScreen ? '.95em' : ''}}
                            >
                                <div className="banner-main-text-small">{replaceCharName(trimmedDescription)}</div>
                            </Tooltip>
                        </div>
                        <div className="gallery-text-banner">
                            <div className="banner-header"
                                 style={{fontSize: isLargeScreen ? '1.15em' : ''}}
                            >Scenario
                            </div>
                            <Tooltip title={replaceCharName(updatedScenario)} placement="top" arrow
                                     style={{fontSize: isLargeScreen ? '.95em' : ''}}
                            >
                                <div className="banner-main-text-small">{replaceCharName(trimmedScenario)}</div>
                            </Tooltip>
                        </div>
                        <div className="gallery-text-banner">
                            <div className="banner-header"
                                 style={{fontSize: isLargeScreen ? '1.15em' : ''}}
                            >Gallery
                            </div>
                            <div className="banner-main-text-small"
                                 style={{fontSize: isLargeScreen ? '.95em' : ''}}
                            >View and generate photos of this character to store in your collection.
                            </div>
                        </div>
                                    <div className="card-container" onClick={handleClick}>
                                        {galleryPlaceholders.length > 0 ? (
                                            galleryPlaceholders.map((imageUrl, index) => (
                                                <div
                                                    key={index}
                                                    className={`card ${index === 0 ? 'front-card' : index === 1 ? 'back-card left' : 'back-card right'}`}
                                                    style={{borderColor: imageUrl ? '#fff' : 'initial'}}
                                                >
                                                    {imageUrl ? (
                                                        <img
                                                            loading="lazy"
                                                            src={`https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${imageUrl}`}
                                                            alt={''}
                                                        />
                                                    ) : (
                                                        <div className="character-svg-altcard-gallery"></div>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            Array.from({length: 3}).map((_, index) => (
                                                <div key={index}
                                                     className={`card ${index === 0 ? 'front-card' : 'back-card right'}`}>
                                                    <div className="character-svg-altcard-gallery">
                                                        <GenerateImagePicture/>
                                                        <p>Click to Generate Image</p>
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                        {galleryPlaceholders.length < 3 && (
                                            [...galleryPlaceholders, ...Array(3 - galleryPlaceholders.length).fill(null)].map((imageUrl, index) => (
                                                <div
                                                    key={index}
                                                    className={`card ${index === 0 ? 'front-card' : index === 1 ? 'back-card left' : 'back-card right'}`}
                                                    style={{borderColor: imageUrl ? '#fff' : '#242424'}}
                                                >
                                                    {imageUrl ? (
                                                        <img
                                                            loading="lazy"
                                                            src={`https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${imageUrl}`}
                                                            alt={''}
                                                        />
                                                    ) : (
                                                        <div className="character-svg-altcard-gallery"></div>
                                                    )}
                                                </div>
                                            ))
                                        )}

                                        {galleryPlaceholders.length === 0 && (
                                            Array.from({length: 1}).map((_, index) => (
                                                <div
                                                    key={index}
                                                    className="card front-card"
                                                    style={{zIndex: '9999', borderColor: '#242424'}}
                                                >
                                                    <div
                                                        className="character-svg-altcard-gallery"
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '100%',
                                                            color: '#c4c4c4',
                                                            borderColor: '#242424',
                                                            fontSize: '.85em',
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        <GenerateImagePicture/>
                                                        <span>Generate a photo</span>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>

                    </div>
                </div>
            </div>}
        </>
    );
};