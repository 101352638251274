import React, {useEffect, useState} from "react";
import {NewPricingContainer} from "../components/MainMenuPage/PricingPage/NewPricingContainer";
import "./PricingPage.css";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {ReactComponent as FireIcon} from "../icons/FireIcon.svg";
import {ReactComponent as CreditCardIcon} from "../icons/credit-card.svg";
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {A11y, Navigation} from "swiper/modules";
import {getUserEmail, userIDExists} from "../App";
import { ReactComponent as CloseIcon } from '../icons/cross.svg';
import AnimeGirl from "../components/Banners/BannerIcons/anime1.png";
import GirlReal from "../components/Banners/BannerIcons/real1.png";
import TopTimer from "../components/Notifications/TopTimer";
import {useTimer} from "../components/Context/TimerContext";
import {AuthModal} from "../components/Forms/AuthModal";
import {useProfileData} from "../components/Context/ProfileDataContext";
import {useExitIntent} from "use-exit-intent";
import ReactDOM from "react-dom";
import ReferralMenu from "../components/Banners/Referral/ReferralMenu";

const NewPricingPage = () => {

    const [yearlyPricing, setYearlyPricing] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(pricingPlans.filter(plan => plan.heading === "Premium Plan")[0]);
    const [premiumPlans, setPremiumPlans] = useState(pricingPlans.filter(plan => plan.heading === "Premium Plan"));
    const [extraAdditionalPricingData, setExtraAdditionalPricingData] = useState(additionalPricingData);
    const [selectedPlanHeading, setSelectedPlanHeading] = useState("Premium Plan");
    const {profileData} = useProfileData();
    const {timerExpired, setTimerExpired} = useTimer();

    const getFinalPrice = () => {
        if (yearlyPricing) {

            const annualPricePerMonth = parseFloat(selectedPlan.annual.price.replace('$', ''));
            let totalAnnualPrice = (annualPricePerMonth * 12).toFixed(2);


            if (totalAnnualPrice.endsWith('.88')) {
                totalAnnualPrice = totalAnnualPrice.substring(0, totalAnnualPrice.length - 2) + '99';
            }

            return `${totalAnnualPrice} per Year`;
        } else {
            const monthlyPrice = parseFloat(selectedPlan.monthly.price.replace('$', ''));


            const monthlyPriceFormatted = monthlyPrice.toFixed(2);
            return `${monthlyPriceFormatted} per Month`;
        }
    };

    const handlePlanSelection = (planHeading) => {
        if (planHeading === "Free Plan") return;
        const plan = pricingPlans.find(p => p.heading === planHeading);
        if (plan) {
            // Store the previously selected plan
            const previousPlan = selectedPlan;

            setSelectedPlan(plan);
            setSelectedPlanHeading(planHeading);

            // Update the premiumPlans state
            setPremiumPlans([plan]);

            // Add the previously selected plan back to the extraAdditionalPricingData array
            if (previousPlan) {
                setExtraAdditionalPricingData(prevData => [...prevData, previousPlan]);
            }

            // Remove the newly selected plan from the extraAdditionalPricingData array
            setExtraAdditionalPricingData(prevData => prevData.filter(p => p.heading !== planHeading));
        }
        setShowMorePlans(false);
        document.body.style.overflow = 'unset';
    };

    useEffect(() => {
        if (profileData.subscription_data.tier === "PREMIUM" || profileData.subscription_data.tier === "GOLD") {
            handlePlanSelection("Ultimate Plan");
        }
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier]);

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showMorePlans, setShowMorePlans] = useState(false);

    const closeModal = () => {
        setShowMorePlans(false);
    };

    // Toggle body scroll based on the modal visibility
    useEffect(() => {
        if (showMorePlans) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup function to ensure scrolling is re-enabled
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showMorePlans]);

    // Close modal if clicked outside of it
    const handleClickOutside = (event) => {
        if (event.target.classList.contains('backdrop')) {
            closeModal();
        }
    };

    const handleViewMorePlans = () => {
        setShowMorePlans(true);
    };

    const handleOpenAddons = () => {
        window.location.href = './addons';
    };

    useEffect(() => {
        // Check if the pricing page has been visited before
        const visitedPricing = localStorage.getItem('visitedPricing');

        if (!visitedPricing) {
            // If not, set visitedPricing to true and reset the timer
            localStorage.setItem('visitedPricing', 'true');
            setTimerExpired(false);
        }


    }, [setTimerExpired]);

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const placeholderPlans = [{
        heading: 'Premium Plan',
        features: ["Unlimited messages.", "Advanced chat model.",  "Maximum memory.", "100 images / month.", "100 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "4 Personality Profiles.", "4 Memory Fragments."]
    }, {
        heading: 'Ultimate Plan',
        features: ["Unlimited messages.", "Advanced chat model.",  "Ultimate memory.", "500 images / month.", "500 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "6 Personality Profiles.", "6 Memory Fragments."]
    }, {
        heading: 'Basic Plan',
        features: ["Unlimited messages.", "Advanced chat model.",  "Better memory.", "50 images / month.", "50 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "2 Personality Profiles.", "2 Memory Fragments."]
    }, {
        heading: 'Free Plan',
        features: ["Unlimited messages.",  "Create your own exclusive characters with basic models for free.",  "Basic memory.", "Custom scenarios and response settings.", "Access to the most capable models and NSFW image generation.", "1 Personality Profile.", "1 Memory Fragment."]
    }];

    const [showExitModal, setShowExitModal] = useState(false);

    const { registerHandler } = useExitIntent({

        cookie: {
            daysToExpire: 30,
            key: "use-exit-intent"
        },
        desktop: {
            triggerOnIdle: false,
            useBeforeUnload: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: 1
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: 5
        }
    });

    registerHandler({
        id: 'openModal',
        handler: () => {
            // console.log('Exit intent detected, showing modal');
            setShowExitModal(true);
        }
    });

    // Additional handlers can be defined here if needed
    registerHandler({
        id: 'anotherHandler',
        // handler: () => console.log('Another handler'),
        context: ['onUnsubscribe', 'onMobile']
    });
    
    const handleCloseExitModal = () => {
        setShowExitModal(false);
    };
    
    const ExitIntent = ({onClose}) => {
        return ReactDOM.createPortal(
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        ✋ Don't leave yet!
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose()
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            We understand that you might not be ready to upgrade yet.
                            <br></br>
                            <br></br>
                            How about you try our <span className="colored-purple-text">premium</span> features for only
                            <span className="colored-purple-text"> $9.99 / month? </span>
                        </div>
                    </div>

                    <button
                        onClick={() => {
                            onClose();
                            handlePlanSelection('Basic Plan');
                        }}
                        className="notification-button-main"
                        style={{marginTop: '20px'}}
                    >
                        I'm Interested!
                    </button>
                </div>
            </div>,
            document.getElementById('modal-root')
        );
    };

    const [showReferralModal, setShowReferralModal] = useState(false);

    return (


        <>

            <ReferralMenu isOpen={showReferralModal} onCancel={() => setShowReferralModal(false)} />


            <HeaderMainMenu setSearchText={() => {
            }} searchText={''}/>

            {isAuthModalOpen && (<AuthModal
                    isOpen={isAuthModalOpen}
                    onClose={() => setIsAuthModalOpen(false)}
                />)}


            {showExitModal && profileData.subscription_data.tier === 'FREE' && (
                <ExitIntent isOpen={showExitModal} onClose={handleCloseExitModal}/>
            )}

            {!timerExpired ? (<div className="top-timer-container-main-pricing"
                                   style={{display: timerExpired !== false ? "none" : 'block'}}
                >
                    <TopTimer/>
                </div>) : null}


            <div className="new-pricing-page-container">
                
                {isWide && (
                    <div className="top-pricing-row-container">
                        
                        <div className="new-toggle-pricing">

                            <div className="new-pricing-subtitle">
                                <h2>Upgrade Plan</h2>
                                <span>Premium plans </span>
                                <span className="highlight-text-price"> up to 60% off</span>
                                <span> for the next hour!</span>

                                <span><br></br>100% anonymous. You can cancel anytime.</span>
                            </div>
                            
                            <div
                                className={!yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                onClick={() => setYearlyPricing(false)}
                            >
                                <div
                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.monthly.discountLabel : "40% Off!"}</div>
                                <div className="pricing-title-text">Monthly</div>
                                <div className="pricing-container-general">
                                    <div
                                        className="pricing-cost-text-price">{selectedPlan ? selectedPlan.monthly.price : "$14.99"}</div>
                                    <div className="pricing-cost-text-main">
                                        <div className="pricing-cost-text">
                                <span
                                    className="crossed-out">{selectedPlan ? selectedPlan.monthly.originalPrice : "$24.99"}</span>
                                            <span> / month</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="pricing-subtitle-text">Cancel anytime.</div>

                            </div>

                            <div
                                className={yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                onClick={() => setYearlyPricing(true)}
                            >

                                <div
                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.annual.discountLabel : "50% Off!"}</div>
                                <div className="most-popular-pin">
                                    <FireIcon/>POPULAR
                                </div>
                                <div className="pricing-title-text">Annually</div>
                                <div className="pricing-container-general">
                                    <div
                                        className="pricing-cost-text-price">{selectedPlan ? selectedPlan.annual.price : "$12.99"}</div>
                                    <div className="pricing-cost-text-main">
                                        <div className="pricing-cost-text">
                                <span
                                    className="crossed-out">{selectedPlan ? selectedPlan.annual.originalPrice : "$24.99"}</span>
                                            <span> / month</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="pricing-subtitle-text">Cancel anytime.</div>
                            </div>

                        </div>
                        
                        <div className="pricing-container-top-column">

                            {profileData.subscription_data.tier === 'GOLD' && (
                                <>
                                {placeholderPlans
                                    .filter(plan => plan.heading === 'Ultimate Plan')
                                    .map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'PREMIUM' && (
                                <>
                                    {placeholderPlans
                                        .filter(plan => plan.heading === 'Ultimate Plan')
                                        .map((plan, index) => (<NewPricingContainer
                                                key={index}
                                                heading={plan.heading}
                                                features={plan.features}
                                            />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'BASIC' && (
                                <>
                                    {premiumPlans.map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                        isSelected={selectedPlanHeading === plan.heading}
                                        onSelect={handlePlanSelection}
                                    />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'FREE' && (
                                <>
                                    {premiumPlans.map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                        isSelected={selectedPlanHeading === plan.heading}
                                        onSelect={handlePlanSelection}
                                    />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'GOLD' && (
                                <>
                                    <div className="buy-right-now-button"
                                         style={{textAlign: 'center', fontSize: '1.25rem', cursor: 'default'}}
                                         onClick={async () => {
                                             validateIsLoggedIn().then(async (loggedIn) => {
                                                 if (loggedIn) {
                                                     setTimeout(() => {
                                                         setIsAuthModalOpen(false);
                                                     }, 100); // Add delay here
                                                 } else {
                                                     setIsAuthModalOpen(true);
                                                 }
                                             });
                                         }}>

                                        You have the Ultimate Plan.
                                        <br></br>
                                        Thank you!
                                    </div>
                                </>
                            )}

                            {profileData.subscription_data.tier !== 'GOLD' && (<>
                                    <div className="buy-right-now-button"
                                         onClick={async () => {
                                             validateIsLoggedIn().then(async (loggedIn) => {
                                                 if (loggedIn) {
                                                     setTimeout(() => {
                                                         setIsAuthModalOpen(false);
                                                     }, 100); // Add delay here
                                                     window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(await getUserEmail())}`
                                                 } else {
                                                     setIsAuthModalOpen(true);
                                                 }
                                             });
                                         }}>
                                        <CreditCardIcon/>
                                        Pay with Card
                                    </div>
                                    <div className="get-pricing-final">
                                        <header>You will be charged $</header>
                                        {getFinalPrice()}
                                    </div>
                                </>
                            )}
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}
                
                {!isWide && (

                    <div className="top-pricing-row-container">
                        <div className="new-toggle-pricing">
                            <div className="left-padding-text-container">
                                <h2>Upgrade Plan</h2>
                                <div className="new-pricing-subtitle">
                                    <span>Premium plans </span>
                                    <span className="highlight-text-price">up to 60% off</span>
                                    <span> for the next hour!</span>
                                    <span><br></br>100% anonymous. You can cancel anytime.</span>
                                </div>
                            </div>
                            
                            <div className="card-container-main-premium">
                                {premiumPlans.map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                    isSelected={selectedPlanHeading === plan.heading}
                                    onSelect={handlePlanSelection}
                                />))}

                                <div className="pricing-swiper-component">
                                    <Swiper
                                        modules={[A11y]}
                                        slidesPerView={2} // Show 2 slides at a time
                                        centeredSlides={true} // Center the active slide
                                        loop={false}
                                        // onSwiper={(swiper) => console.log(swiper)}
                                        // onSlideChange={() => console.log('slide change')}
                                    >
                                        <SwiperSlide>
                                            <div
                                                className={yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                                onClick={() => setYearlyPricing(true)}
                                            >
                                                <div
                                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.annual.discountLabel : "50% Off!"}</div>
                                                <div className="most-popular-pin">
                                                    <FireIcon/>POPULAR
                                                </div>
                                                <div className="pricing-title-text">Annually</div>
                                                <div className="pricing-container-general">
                                                    <div
                                                        className="pricing-cost-text">{selectedPlan ? selectedPlan.annual.price : "$12.99"}</div>
                                                    <div className="pricing-cost-text-main">
                                                        <div className="pricing-cost-text">
                                                        <span
                                                            className="crossed-out">{selectedPlan ? selectedPlan.annual.originalPrice : "$24.99"}</span>
                                                            <span> / month</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-subtitle-text">Cancel anytime.</div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div
                                                className={!yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                                onClick={() => setYearlyPricing(false)}
                                            >
                                                <div
                                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.monthly.discountLabel : "40% Off!"}</div>
                                                <div className="pricing-title-text">Monthly</div>
                                                <div className="pricing-container-general">
                                                    <div
                                                        className="pricing-cost-text">{selectedPlan ? selectedPlan.monthly.price : "$14.99"}</div>
                                                    <div className="pricing-cost-text-main">
                                                        <div className="pricing-cost-text">
                                                        <span
                                                            className="crossed-out">{selectedPlan ? selectedPlan.monthly.originalPrice : "$24.99"}</span>
                                                            <span> / month</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-subtitle-text">Cancel anytime.</div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        
                        <div className="pricing-container-top-column">
                            <div className="buy-right-now-button"
                                 onClick={async () => {
                                     validateIsLoggedIn().then(async (loggedIn) => {
                                         if (loggedIn) {
                                             setTimeout(() => {
                                                 setIsAuthModalOpen(false);
                                             }, 100); // Add delay here
                                             window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(await getUserEmail())}`
                                         } else {
                                             setIsAuthModalOpen(true);
                                         }
                                     });
                                 }}>
                                <CreditCardIcon/>
                                Pay with Card
                            </div>
                            <div className="get-pricing-final">
                                <header>You will be charged $</header>
                                {getFinalPrice()}
                            </div>
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                <div className="bottom-of-the-page-container">

                    <div className="left-padding-text-container">
                        <h2>This is your current plan.</h2>

                        {profileData.subscription_data.tier !== 'GOLD' && (

                            <>
                                <div className="new-pricing-subtitle">
                                <span>It’s pretty neat, but let’s be real;<br/>You’re missing out <span

                                    className="highlight-text-price">on a bunch of stuff!</span></span>

                                    {profileData.subscription_data.tier !== 'PREMIUM' && (
                                        <>
                                            {!showMorePlans && (<div className="buy-right-now-button-credit"
                                                                     onClick={handleViewMorePlans}>
                                                    View other plans
                                                </div>)}
                                            <div className='buy-right-now-button-credit-addons'
                                                 onClick={handleOpenAddons}>

                                                Additions for plan
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {profileData.subscription_data.tier === 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>And it seems you have maxed out!<br/>Thank you for using 
                                    <span className="highlight-text-price">Elyza!
                                    </span></span>
                                </div>
                                <div className='buy-right-now-button-credit-addons' onClick={handleOpenAddons}>
                                    Additions for plan
                                </div>
                            </>
                        )}
                    </div>
                    
                    {profileData.subscription_data.tier === 'GOLD' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Ultimate Plan')
                                .map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'PREMIUM' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Premium Plan')
                                .map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'BASIC' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Basic Plan')
                                .map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'FREE' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Free Plan')
                                .map((plan, index) => (<NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />))}
                        </>
                    )}

                    <img src={AnimeGirl} alt="Anime Girl" className="anime-girl" draggable="false"/>

                </div>
            </div>

            {showMorePlans && isWide && (
                <>
                    <div className="backdrop" onClick={handleClickOutside}></div>
                    <div className="modal-pricing" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content-pricing">
                            {extraAdditionalPricingData.map(plan => (<NewPricingContainer
                                    key={plan.heading} {...plan}
                                    heading={plan.heading}
                                    features={plan.features}
                                    isSelected={selectedPlanHeading === plan.heading}
                                    onSelect={handlePlanSelection}
                                />))}
                        </div>
                    </div>
                </>
            )}

            {showMorePlans && !isWide && (<>
                    <div className="backdrop" onClick={handleClickOutside}></div>
                    <div className="modal-pricing" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header-pricing"></div>
                        <div className="modal-content-pricing">
                            <div className="pricing-swiper-component">
                                <Swiper
                                    modules={[Navigation, A11y]}
                                    slidesPerView={2}
                                    centeredSlides={true}
                                    loop={false}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    // onSlideChange={() => console.log('slide change')}
                                >
                                    {extraAdditionalPricingData.map(plan => (<SwiperSlide key={plan.heading}>
                                            <NewPricingContainer
                                                {...plan}
                                                heading={plan.heading}
                                                features={plan.features}
                                                isSelected={selectedPlanHeading === plan.heading}
                                                onSelect={handlePlanSelection}
                                            />
                                        </SwiperSlide>))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default NewPricingPage;

const additionalPricingData = [{
    heading: "Basic Plan",
    features: ["Unlimited messages.", "Advanced chat model.",  "Better memory.", "50 images / month.", "50 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "2 Personality Profiles.", "2 Memory Fragments."]
}, {
    heading: "Ultimate Plan",
    features: ["Unlimited messages.", "Advanced chat model.",  "Ultimate memory.", "500 images / month.", "500 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "6 Personality Profiles.", "6 Memory Fragments."]
}];

const pricingPlans = [{
    heading: "Basic Plan",
    monthly: {
        price: "$9.99",
        originalPrice: "$12.99",
        discountLabel: "25% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Monthly&utm_source=cb-app-copy",
    },
    annual: {
        price: "$7.99",
        originalPrice: "$12.99",
        discountLabel: "40% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Yearly&utm_source=cb-app-copy",
    },
    features: ["Unlimited messages.", "Advanced chat model.",  "Better memory.", "50 images / month.", "50 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "2 Personality Profiles.", "2 Memory Fragments."]
}, {
    heading: "Premium Plan",
    monthly: {
        price: "$14.99",
        originalPrice: "$24.99",
        discountLabel: "40% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Monthly&utm_source=cb-app-copy",
    },
    annual: {
        price: "$12.99",
        originalPrice: "$24.99",
        discountLabel: "50% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Yearly&utm_source=cb-app-copy",
    },
    features: ["Unlimited messages.", "Advanced chat model.",  "Maximum memory.", "100 images / month.", "100 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "4 Personality Profiles.", "4 Memory Fragments."]
}, {
    heading: "Ultimate Plan",
    monthly: {
        price: "$49.99",
        originalPrice: "$69.99",
        discountLabel: "30% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Monthly&utm_source=cb-app-copy",
    },
    annual: {
        price: "$29.99",
        originalPrice: "$69.99",
        discountLabel: "60% Off!",
        redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Yearly&utm_source=cb-app-copy",
    },
    features: ["Unlimited messages.", "Advanced chat model.", "Ultimate memory.", "500 images / month.", "500 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "6 Personality Profiles.", "6 Memory Fragments."]
},];