import React, { useState } from 'react';
import './ResetPassword.css';
import { supabase } from '../../App';

export function UpdatePasswordPage() {
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');





    const handleUpdatePassword = async () => {
        if (!isValidPassword(newPassword)) {
            setPasswordError('Password must be at least 8 characters long.');
            return;
        }

        if (newPassword !== repeatPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }

        setLoading(true);
        setPasswordError(''); // Reset password error
        try {
            const {error} = await supabase.auth.updateUser({
                password: newPassword,
            });

            if (error) throw error;

            setMessage('Your password has been updated successfully.');
            setNewPassword('');
            setRepeatPassword('');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } catch (error) {
            console.error('Error updating password:', error.message);
            // Assuming error.error_description or error.message contains the relevant message
            // from Supabase, including "New password should be different from the old password."
            setPasswordError(error.error_description || error.message); // Use setPasswordError for consistency
            setMessage(''); // Clear any previous success messages
        } finally {
            setLoading(false);
        }
    };



    function isValidPassword(password) {
        // Check if password is at least 8 characters long
        if (password.length < 8) {
            return false;
        }
        // If all conditions are met, return true
        return true;
    }




    return (
        <div className="reset-password-modal">
            <div className="modal-content">
                <div className="modal-header-2">
                    <span className="main-elyza-text-label-reset-2">Update Password</span>
                    <p className="main-elyza-text-sublabel">Thanks for confirming your email. Let’s
                        choose a new password for your account.</p>
                </div>


                {/*<div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>*/}


                <div className='a-straight-line'></div>

                <div className="textbox-password-gap">


                    <div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>

                        <label className="label-sign-up-h">New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            className="textbox-passwordinput"

                        />
                    </div>


                    <div className={`textbox-password ${passwordError ? 'shake-animation' : ''}`}>
                        <label className="label-sign-up-h">Repeat Password</label>
                        <input
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            placeholder="Repeat new password"
                            className="textbox-passwordinput"
                        />
                    </div>


                </div>

                {passwordError && <div className="message-error">{passwordError}</div>}
                {message && <div className="message-info">{message}</div>}
                <div className='a-straight-line'></div>


                <div className="modal-footer">
                    <button className="change-step-button-reset" onClick={handleUpdatePassword}
                            disabled={loading || !newPassword || !repeatPassword}>
                        {loading ? 'Updating...' : 'Update Password'}
                    </button>
                </div>

            </div>
        </div>
    );
}
