import React from 'react';
import ReactDOM from 'react-dom';
import './DeleteMessageModal.css';
import { ReactComponent as CloseIcon } from '../../icons/cross.svg';

const DeleteMessageModal = ({ isVisible, onDeleteConfirm, onCancel }) => {
    // Render nothing if not visible
    if (!isVisible) return null;

    // Create the modal JSX content
    const modalContent = (
        <div className="main-space-finder-modal" onClick={onCancel}>
            <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>

                <div className="main-upper-text-row">
                    <div className="profile-setting-label">Delete previous messages</div>
                    <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>

                </div>
                <div className="profile-setting-sublabel">This will delete the two previous messages in chat, confirm to delete.</div>


{/*<div className='line' style={{marginTop: '15px'}}></div>*/}
                <div className="parent">
                    <button onClick={onDeleteConfirm} className="redeem-button" style={{width: '100%', height: '45px' }}>Delete Message</button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default DeleteMessageModal;
