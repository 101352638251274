import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import '../Notifications/ModalAndNotifications.css';
import { ReactComponent as CloseIcon } from "../MainMenuPage/icons/cross.svg";
import {getUserAccessToken, supabase} from "../../App";
import {useNavigate} from "react-router-dom";


const EmailVerificationModal = ({ isOpen, onClose }) => {
    const [codes, setCodes] = useState(Array(6).fill(''));
    const inputsRef = useRef([]);
    const [cooldown, setCooldown] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);
    const [verifyCooldown, setVerifyCooldown] = useState(0);
    const [shake, setShake] = useState(false);
    const [verifyError, setVerifyError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            // Prevent scrolling
            document.body.style.overflow = 'hidden';
            sendVerificationEmail();
            // Start the timer
            setCooldown(30);
            // Automatically focus the first input box
            if (inputsRef.current[0]) {
                inputsRef.current[0].focus();
            }
        } else {
            // Enable scrolling
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const resendCode = () => {
        setIsResendDisabled(true);
        setCooldown(30);
        setVerifyCooldown(5);
        sendVerificationEmail();
    };
    useEffect(() => {
        // Check if all code inputs are filled
        const isComplete = codes.every(code => code !== '');
        setIsCodeComplete(isComplete);

        // If all code inputs are filled, automatically verify the account
        if (isComplete) {
            verifyAccount();
        }
        //eslint-disable-next-line
    }, [codes])
    useEffect(() => {
        let timer;
        if (verifyCooldown > 0) {
            timer = setInterval(() => {
                setVerifyCooldown(prevCooldown => prevCooldown - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [verifyCooldown]);

    useEffect(() => {
        let timer;
        if (cooldown > 0) {
            timer = setInterval(() => {
                setCooldown(prevCooldown => prevCooldown - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }

        return () => {
            clearInterval(timer);
        };
    }, [cooldown]);

    const sendVerificationEmail = async () => {
        try {
            const response = await fetch('https://api.elyza.app/v1/send-verification-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: await getUserAccessToken(),
                },
            });
            //eslint-disable-next-line
            const data = await response.json();
            // console.log('good:', data);
        } catch (error) {
            // console.error('bad:', error);
        }
    };

    const verifyAccount = async () => {
        const code = codes.join('');
        if (code.length === 6) {
            try {
                const response = await fetch('https://api.elyza.app/v1/verify-account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: await getUserAccessToken(),
                    },
                    body: JSON.stringify({ code: code })
                });
                const data = await response.json();
                if (data.message === "Verified") {
                    setShowSuccessModal(true);
                    // console.log('Verification successful:', data);
                } else {
                    setShake(true);
                    setVerifyError('Invalid code');
                    setTimeout(() => setShake(false), 750); // Shake for 500ms
                    setVerifyCooldown(0.850); // Gray the button for 2 seconds
                    // console.log('Verification failed:', data.message);
                }
            } catch (error) {
                // console.error('Error verifying account:', error);
            }
        }
    };
    const [isCodeComplete, setIsCodeComplete] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const ThanksForVerifyingModal = ({ onClose }) => {
        return (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        ✅ Thank you!
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false);
                            setShowSuccessModal(false);
                            window.location.reload();
                        }}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='sublabel-notification'>
                        Your email was successfully verified. <br></br>
                        <div
                            style={{ marginTop: '20px' }}
                            className="sublabel-notification"> You may now close this window and continue using Elyza.
                        </div>
                    </div>

                    <button onClick={() => {
                        onClose(false);
                        setShowSuccessModal(false);
                        window.location.reload();
                    }} className="notification-button-main"
                            style={{ marginTop: '25px', marginBottom: "0" }}
                    >Okay
                    </button>


                </div>
            </div>
        );
    };
    if (!isOpen) return null;

    const handleInputChange = (index, value) => {
        const newCodes = [...codes];
        if (/^\d$/.test(value)) {
            newCodes[index] = value;
            setCodes(newCodes);

            // Check if all code inputs are filled
            const isComplete = newCodes.every(code => code !== '');
            setIsCodeComplete(isComplete);

            if (index < 5) {
                inputsRef.current[index + 1].focus();
            }

            // If all code inputs are filled, automatically verify the account
            if (isComplete) {
                verifyAccount();
            }
        } else if (value === '') {
            newCodes[index] = '';
            setCodes(newCodes);
            setIsCodeComplete(false);
            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };
    const handleSignOut = async () => {
        const {error} = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error.message);
        } else {

            localStorage.clear();

            navigate('/'); // Replace with your routing logic
            window.location.reload();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && codes[index] === '') {
            if (index > 0) {
                const newCodes = [...codes];
                newCodes[index - 1] = '';
                setCodes(newCodes);
                inputsRef.current[index - 1].focus();
            }
        } else if (event.key === "Enter" && isCodeComplete) {
            verifyAccount();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pastedData = event.clipboardData.getData('text');
        if (pastedData.length === 6 && /^\d{6}$/.test(pastedData)) {
            setCodes(pastedData.split(''));
            setIsCodeComplete(true); // Set isCodeComplete to true
            verifyAccount(); // Automatically verify the account
        }
    };


    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">

                {showSuccessModal && <ThanksForVerifyingModal onClose={() => setShowSuccessModal(false)} />}

                <div className={`modal-content-email-verif ${shake ? 'shake' : ''}`}>
                    <div className='notification-lable'>
                        Verify your email.
                        {/*<div className="modal-close-icon-notification" onClick={() => {*/}
                        {/*    onClose(false);*/}
                        {/*    document.body.style.overflow = 'auto'; // Enable scrolling*/}
                        {/*}}>*/}
                        {/*    <CloseIcon/>*/}
                        {/*</div>*/}
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            We've emailed you a six digit code to verify your email address.
                            <br></br>
                            Please enter it below to continue.
                        </div>
                        <div className="code-input-container">
                            {codes.map((code, index) => (
                                <input
                                    key={index}
                                    ref={el => inputsRef.current[index] = el}
                                    type="text"
                                    id={`input-${index}`}
                                    maxLength="1"
                                    value={code}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onPaste={index === 0 ? handlePaste : null}
                                    className="code-input"
                                />
                            ))}
                        </div>
                    </div>

                    <button onClick={verifyAccount}
                            className={`notification-button-main ${verifyCooldown > 0 ? 'notification-button-main-gray-email' : (!isCodeComplete ? 'notification-button-main-gray-email' : '')}`}>
                        Verify
                    </button>
                    <button onClick={handleSignOut} className="sign-out-button"
                    style={{marginTop: '15px', marginBottom: '10px'}}
                    >
                        Sign out
                    </button>
                    {verifyError && <div className="error-message"
                                         style={{marginBottom: '-5px', marginTop: '10px'}}
                    >{verifyError}</div>}

                    <div className="a-straight-line" style={{marginTop: '10px', marginBottom: '20px'}}></div>
                    <div className='sublabel-notification'>
                        <button className={`get-a-new-code-button ${cooldown > 0 || !isCodeComplete ? 'no-hover' : ''}`}
                                onClick={resendCode} disabled={isResendDisabled || !isCodeComplete}>
                            {cooldown > 0 ? `Didn't get a code? Resend it in ${cooldown}s` : 'Click to resend code'}
                        </button>

                    </div>

                </div>
            </div>
        ),
        document.body
    );
};

export default EmailVerificationModal;
