import React, {useEffect, useState} from 'react';
// import CharacterStep1 from "../../components/CharacterCreation/CharacterStep1";
import CharacterStep2 from "../../components/CharacterCreation/CharacterStep2";
import CharacterStep3 from "../../components/CharacterCreation/CharacterStep3";
import CharacterStep4 from "../../components/CharacterCreation/CharacterStep4";
import CharacterStep5 from "../../components/CharacterCreation/CharacterStep5";
import CharacterStep7 from "../../components/CharacterCreation/CharacterStep7";
import CharacterStep6 from "../../components/CharacterCreation/CharacterStep6";
import CharacterReview from "../../components/CharacterCreation/CharacterReview";
import ImportScreen from "../../components/CharacterCreation/ImportScreen";
import './CreateCharacterPage.css';
import ReactDOM from 'react-dom';
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {ReactComponent as ArrowFront} from "./CharacterIcons/Next.svg";
import {ReactComponent as ArrowBack} from "./CharacterIcons/goBack.svg";
import {ReactComponent as CreateChar} from "./CharacterIcons/CreateChar.svg";
// import {ReactComponent as ForwardArrow} from "./CharacterIcons/ForwardArrow.svg";
import {getUserAccessToken, getUserID, userIDExists} from "../../App";
import {useNavigate} from "react-router-dom";
import TopTimer from "../../components/Notifications/TopTimer";
import {MoonLoader} from 'react-spinners';
import {useTimer} from "../../components/Context/TimerContext";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {ReactComponent as CloseIcon} from "../../components/Forms/FormsIcons/close.svg";
import {AuthModal} from "../../components/Forms/AuthModal";
import ErrorProfileModal from "../../components/Notifications/ErrorNotificationProfile";
import EmailVerification from "../../components/Forms/EmailVerification";
import CharWarningModalCentralized from "../../components/Notifications/CharWarningModalCentralized";

//TODO make sure the backup LORA is working.

const CreateCharacterNew = () => {
    const [currentStep, setCurrentStep] = useState(1);
    // const [selectedOption, setSelectedOption] = useState(null);
    const [characterName, setCharacterName] = useState('');
    const [hasReviewed, setHasReviewed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { profileData } = useProfileData();
    const [isEmailVerificationModalOpen, setIsEmailVerificationModalOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [imageStyles, setImageStyles] = useState([]);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsLoggedIn(loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        // Check local storage for the 'hasReviewed' status at component mount
        const storedHasReviewed = localStorage.getItem('hasReviewed');
        if (storedHasReviewed !== null) {
            setHasReviewed(JSON.parse(storedHasReviewed)); // Parse the stored string to boolean
        }
    }, []);

    useEffect(() => {
        // Update local storage whenever 'hasReviewed' changes
        localStorage.setItem('hasReviewed', JSON.stringify(hasReviewed));
    }, [hasReviewed]);

    useEffect(() => {
        if (isLoggedIn && !profileData.is_verified) {
            setIsEmailVerificationModalOpen(true);
        }
    }, [isLoggedIn, profileData.is_verified]);

    useEffect(() => {
        if (profileData.subscription_data.tier === 'FREE') {
            setSubscriptionError(true);
        } else {
            setSubscriptionError(false);
        }
    }, [profileData.subscription_data.tier]);

    const nextStep = () => {
        // if (isFetching) {
        //     alert("Please wait while we fetch image styles.");
        //     return;
        // }

        if (!validateStepData(currentStep)) {
            alert("Please complete all required fields before proceeding.");
            return; // Prevent moving to the next step
        }

        //eslint-disable-next-line
        if (currentStep === 4 && isFetching && currentStep >= 4 && currentStep <= 4) {
            alert("Please wait while we fetch image styles.");
            return;
        }

        // Handling jumps or simple step increments based on conditions
        if (currentStep === 4 && imageStyles.regularLoraItems.length === 0 &&
            imageStyles.ponyLoraItems.length === 0) {
            setCurrentStep(currentStep + 2);
        } else {
            setCurrentStep((prevStep) => (prevStep >= 8 ? 8 : prevStep + 1));
        }
    };

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const prevStep = () => {
        if (currentStep === 6 && imageStyles.regularLoraItems.length === 0 &&
            imageStyles.ponyLoraItems.length === 0) {
            setCurrentStep(currentStep - 2);
        } else {
            setCurrentStep(currentStep - 1);
        }
    };


    const [oldDezgoStyles, setOldDezgoStyles] = useState([]);

    useEffect(() => {
        fetchImageStyles();
        //eslint-disable-next-line
    }, [characterName]);

    const fetchImageStyles = async () => {
        const name = localStorage.getItem('characterName');
        if (name === null || name.length === 0) return;
        if (characterName !== name) {
            setCharacterName(name);
            const styles = await potentialBaseImageStyle(name);
            setImageStyles(styles);
        }
    };

    async function potentialBaseImageStyle(name) {
        setIsFetching(true);
        try {
            const response = await fetch('https://api.elyza.app/v1/potential-lora', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken(),
                },
                body: JSON.stringify({ name }),
            });

            const data = await response.json();

            if (!data.ponyLoraItems || !Array.isArray(data.ponyLoraItems)) {
                console.error('Unexpected API response:', data);
                return [];
            }

            setOldDezgoStyles(data.regularLoraItems);
            return data;
        } catch (error) {
            console.error('Error fetching image styles:', error);
            return [];
        } finally {
            setIsFetching(false);
        }
    }

    async function directIdentityCreation(userId, gender, modelStyle, isNSFW, characterName, description, isPublic, personality, appearance, scenario, dialogue, profileUrl, lora, triggerWords, airUrl) {
        setIsLoading(true);
        if (!profileUrl) {
            isPublic = false;
        }
        try {
            const response = await fetch('https://api.elyza.app/v1/direct-identity-creation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken(),
                },
                body: JSON.stringify({
                    userId,
                    gender,
                    modelStyle,
                    isNSFW,
                    characterName,
                    description,
                    isPublic,
                    personality,
                    appearance,
                    scenario,
                    dialogue,
                    profileUrl,
                    lora,
                    triggerWords,
                    airUrl
                }),
            });

            const data = await response.json();
            if (!data.success) {
                setErrorMessage(data.error);
                setShowFailureModal(true);
            }
            return {success: data.success, identityName: data.identityName, userId: data.userId};
        } catch (error) {
            setErrorMessage(`Error: ${error.message}. Please try again.`);
            setShowFailureModal(true);
            return {success: false};
        } finally {
            setIsLoading(false);
        }
    }

    //TODO ADD ERROR HANDLING
    const [GenerateProfilePicture, setGenerateProfilePicture] = useState(false);
    const [errorImagePopup, setErrorImagePopup] = useState(false);
    // console.log(errorImagePopup)


    async function createProfilePicture(userId, isAiGenerate, baseLora, triggerWords, backgroundInformation, base64, GenerateProfilePicture) {

        if (GenerateProfilePicture === false && !characterImageExists) {
            // If user has chosen to skip the creation of the profile picture, immediately return null
            // console.log('User has skipped profile picture creation.');
            return null;
        }

        try {
            // console.log('Starting to create profile picture');
            const accessToken = await getUserAccessToken();
            // console.log('Access token obtained:', accessToken);

            const response = await fetch('https://api.elyza.app/v1/create-profile-picture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                },
                body: JSON.stringify({
                    userId,
                    isAiGenerate,
                    baseLora,
                    triggerWords,
                    backgroundInformation,
                    base64,
                }),
            });

            if (!response.ok) {
                const errorInfo = await response.text();  // Get the response body as text to log more detailed error information
                // console.error('Failed to create profile picture:', errorInfo);
                throw new Error(`HTTP error ${response.status}: ${errorInfo}`);
            }
            const data = await response.json();

            return data.url;
        } catch (error) {
            throw error;
        }
    }

    const goToStep = (step) => {
        setCurrentStep(step);
    };

    const validateStepData = (currentStep) => {
        switch (currentStep) {
            // case 1: // Step 1 commented out
            //     // No data to check after step 1, as it's likely an introduction step
            //     return true;
            case 1:
                // Check for gender selection
                return localStorage.getItem('selectedGender') !== null;
            case 2:
                // Check for style selection
                return localStorage.getItem('selectedStyle') !== null;
            case 3:
                // Check for name, description, NSFW, and public flags
                return localStorage.getItem('characterName') &&
                    localStorage.getItem('shortDescription') &&
                    localStorage.getItem('contentType') &&
                    localStorage.getItem('visibility');
            case 4:
                // Check for personality, appearance, scenario, dialogue
                return localStorage.getItem('personalityDescription') &&
                    localStorage.getItem('appearanceDescription') &&
                    localStorage.getItem('scenarioDescription') &&
                    localStorage.getItem('dialogueDescription');
            case 5:

            //eslint-disable-next-line
            case 6:
                // Optional step, may not require validation
                return true;
            default:
                // For any other step, assume validation passes or is not required
                return true;
        }
    };


    const renderStep = () => {
        if(currentStep === 4) {
            fetchImageStyles().then(r => {});
        }
        switch (currentStep) {
            // case 1: // Step 1 commented out
            //     return <CharacterStep1 onOptionSelected={onOptionSelected}/>;
            case 1:
                //gender
                return <CharacterStep2/>;
            case 2:
                //style
                return <CharacterStep3/>;
            case 3:
                //name, description, nsfw, public
                return <CharacterStep4 generateAiEntry={generateAiEntry} subscriptionError={subscriptionError}/>;
            case 4:
                //personality, appearance, scenario, dialogue
                return <CharacterStep5 generateAiEntry={generateAiEntry}
                                       subscriptionError={subscriptionError}
                />;
            case 5:
                return <CharacterStep6 imageStyles={imageStyles}/>;

            case 6:
                return <CharacterStep7
                    removeImage={handleRemoveCharacterImage}
                    setCharacterImageExists={setCharacterImageExists}
                    skipProfilePicture={GenerateProfilePicture}
                    setSkipProfilePicture={setGenerateProfilePicture}
                />;

            case 7:
                return <CharacterReview goToStep={goToStep}/>;
            case 99:
                return <ImportScreen/>;
            default:
                return <CharacterReview goToStep={goToStep}/>;
        }
    };


    async function generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink) {
        const response = await fetch('https://api.elyza.app/v1/generate-ai-entry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken(),
            },
            body: JSON.stringify({
                userId,
                type,
                currentVersion,
                backgroundInformation,
                fandomLink,
            }),
        });

        const data = await response.json();
        return data.response;
    }


    const navigate = useNavigate();



    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showFailureModal, setShowFailureModal] = useState(false);

    const [processing, setProcessing] = useState(false);
    const handleCharacterClick = async (identityName, creatorId) => {
        if(processing) return;
        setProcessing(true);
        const loggedIn = await userIDExists();
        if (!loggedIn) {
            // If not logged in, stop the function execution here
            setProcessing(false);
            return;
        }

        getUserID().then(async (id) => {
            await fetch('https://api.elyza.app/v1/create-character', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify({userId: id, identityName: identityName, creatorId: creatorId ?? ""}),
            });
            navigate(`/chat/${identityName}`);
            setProcessing(false);
        });
    };




    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [isLoadingImage, setIsLoadingImage] = useState(false);
    const [characterImageExists, setCharacterImageExists] = useState(!!localStorage.getItem('characterImage'));

    // Update character image existence state based on localStorage
    useEffect(() => {
        const updateImageExistence = () => setCharacterImageExists(!!localStorage.getItem('characterImage'));
        updateImageExistence();

        window.addEventListener('storage', updateImageExistence);

        return () => {
            window.removeEventListener('storage', updateImageExistence);
        };
    }, []);

    const handleGenerateAndStoreProfileImage = async () => {
        setIsLoadingImage(true); // Show loading indicator

        const userId = await getUserID();
        const lora = localStorage.getItem('sha256') || '';
        const triggerWords = localStorage.getItem('triggerWords') || '';
        const backgroundInformation = {
            appearance: localStorage.getItem('appearanceDescription') || '',
            personality: localStorage.getItem('personalityDescription') || '',
            style: localStorage.getItem('selectedStyle') || 'Unknown',
            lora_strength: 1,
            display_name: localStorage.getItem('characterName') || 'Unknown',
            gender: localStorage.getItem('selectedGender'),
            description: localStorage.getItem('shortDescription'),
            is_Public: localStorage.getItem('visibility') === 'Public',
        };

        const base64Image = await createProfilePicture(userId, true, lora, triggerWords, backgroundInformation, '', GenerateProfilePicture, );

        if (profileData.subscription_data.tier === 'FREE' && profileData.identity_images_created === 5) {
            setIsLoadingImage(false);
            setErrorImagePopup(true);
            return;
        }

        localStorage.setItem('characterImage', base64Image); // Store the generated image

        setIsLoadingImage(false); // Hide loading indicator
        setCharacterImageExists(true);

        nextStep(); // Navigate to the next step
    };

    const handleRemoveCharacterImage = () => {
        localStorage.removeItem('characterImage');
        setCharacterImageExists(false); // Update state to reflect the removal
    };

    const simplyAdvanceStep = () => {
        nextStep();
    };

    const { timerExpired } = useTimer();


    let totalSteps = 7;

    const handleClearLocalStorage = () => {
        // Clear local storage
        localStorage.clear();

    };

    return (
        <div className="container-char-creator">
            {isEmailVerificationModalOpen && (
                <EmailVerification isOpen={isEmailVerificationModalOpen} onClose={() => setIsEmailVerificationModalOpen(false)} />
            )}


            <HeaderMainMenu/>


            {errorImagePopup && (
                <ErrorProfileModal isOpen={errorImagePopup} onClose={(confirm) => {
                    setErrorImagePopup(false)
                    if (confirm) {
                        navigate('/pricing');
                    }
                }}/>
            )}


            { !timerExpired ? (
                <div className="top-timer-container-main"
                     style={{ display: timerExpired !== false ? "none" : 'block' }}
                >
                    <TopTimer/>
                </div>
            ) : null}


            <div className="create-character-new">






                <div className="top-section-creation"
                     style={{ marginTop: timerExpired !== false ? "20vh" : '30vh' }}



                >

                    <CreateChar/>
                    Character Creator
                </div>
                <div className="character-top-header-container">
                    <span>Take the quiz to create</span>
                    <span className="plan-type"> your own unique character </span>
                    <span> on Elyza</span>
                </div>


                <div className="progress-bar-container">
                    <div className="progress-bar-background"></div>
                    <div className="progress-bar-line" style={{ width: `${(currentStep - 1) / (totalSteps - 1) * 100}%` }}></div>
                    {Array.from({ length: totalSteps }).map((_, index) => (
                        <div key={index} className={`circle-progress-bar ${index < currentStep ? 'active' : ''}`}></div>
                    ))}
                    <div className="progress-user-icon" style={{ left: `${(currentStep - 1) / (totalSteps - 1) * 100}%` }}></div>
                </div>


                <div className="general-container-create">

                    {renderStep()}



                    <div className={`change-step-container ${currentStep > 1 ? 'change-step-container-2' : ''}`}>

                        {currentStep === 1 && (
                            <button className="delete-everything-button" onClick={handleClearLocalStorage}>Reset
                                Progress
                            </button>
                        )}

                        {currentStep > 1 && (
                            <button className="change-step-button-back" onClick={() => {
                                prevStep();
                                if (currentStep === 7) {
                                    setHasReviewed(true);
                                }
                            }}>
                                Back
                                <div className="arrow-back-container">
                                    <ArrowBack/>
                                </div>
                            </button>
                        )}

                        <div className='container-char-creator-buttons'>
                            {currentStep !== 6 && currentStep < 7 && (
                                <button
                                    className={`change-step-button-2 ${currentStep === 1 ? 'change-step-button-special' : ''}`}
                                    onClick={nextStep}>
                                    Next
                                    <div className="arrow-front-container">
                                        <ArrowFront/>
                                    </div>
                                </button>
                            )}

                            {currentStep === 6 && (
                                characterImageExists ? (
                                    <button className="change-step-button-2" onClick={simplyAdvanceStep}>
                                        Next
                                        <div className="arrow-front-container">
                                            <ArrowFront/>
                                        </div>
                                    </button>
                                ) : (
                                    <button className="change-step-button-2"
                                            onClick={handleGenerateAndStoreProfileImage}>
                                        Skip
                                        <div className="arrow-front-container">
                                            <ArrowFront/>
                                        </div>
                                    </button>
                                )
                            )}


                            {isLoadingImage && ReactDOM.createPortal(
                                <div className="modal-backdrop" style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backdropFilter: 'blur(10px)',
                                    background: 'rgba(0, 0, 0, 0.7)', // Darkened background
                                    display: 'flex',
                                    alignItems: 'center', // Centers the modal vertically
                                    justifyContent: 'center', // Centers the modal horizontally
                                    zIndex: 9999 // High z-index to ensure it's above other content
                                }}>

                                    <div className="modal loading-modal" style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        zIndex: 1000
                                    }}>
                                        <div className="modal-content">
                                            <h2>Please wait!</h2>
                                            <p>We are creating your character profile picture. You may come back to this
                                                step.</p>
                                            <div className="message-loading-spin" style={{display: 'flex'}}>
                                                <MoonLoader color="white"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>,
                                document.getElementById('modal-root')
                            )}


                            {/*{hasReviewed && currentStep < 7 && (*/}
                            {/*    <button className="skip-to-review-button" onClick={() => setCurrentStep(7)}>*/}
                            {/*        To Review*/}
                            {/*        <div className="arrow-front-container">*/}
                            {/*            <ForwardArrow/>*/}
                            {/*        </div>*/}
                            {/*    </button>*/}
                            {/*)}*/}


                        </div>
                        {
                            isAuthModalOpen && (
                                <AuthModal
                                    isOpen={isAuthModalOpen}
                                    onClose={() => setIsAuthModalOpen(false)}
                                />
                            )
                        }
                        {currentStep === 7 && (
                            <button
                                className="change-step-button-2"
                                onClick={async () => {
                                    const isLoggedIn = await validateIsLoggedIn();
                                    if (!isLoggedIn) {
                                        setIsLoading(false);
                                        setIsAuthModalOpen(true);
                                        return;
                                    }

                                    setIsLoading(true);
                                    setShowLoadingModal(true);

                                    const userId = await getUserID();
                                    const gender = localStorage.getItem('selectedGender') || 'Unknown';
                                    const modelStyle = localStorage.getItem('selectedStyle') || 'Unknown';
                                    const isNSFW = localStorage.getItem('contentType') === 'NSFW';
                                    const characterName = localStorage.getItem('characterName') || 'Unknown';
                                    const description = localStorage.getItem('shortDescription') || '';
                                    const isPublic = localStorage.getItem('visibility') === 'Public';
                                    const personality = localStorage.getItem('personalityDescription') || '';
                                    const appearance = localStorage.getItem('appearanceDescription') || '';
                                    const scenario = localStorage.getItem('scenarioDescription') || '';
                                    const dialogue = localStorage.getItem('dialogueDescription') || '';
                                    const base64 = localStorage.getItem('characterImage');
                                    const lora = oldDezgoStyles.map(item => item.SHA256).join(',') || '';
                                    const triggerWords = localStorage.getItem('triggerWords') || '';
                                    const airUrl = localStorage.getItem('airUrl') || ''; // Fetch airUrl from localStorage
                                    const newTriggerWords = localStorage.getItem('triggerWords') || ''; // Fetch airUrl from localStorage


                                    const backgroundInformation = {
                                        appearance: appearance,
                                        personality: personality,
                                        style: modelStyle,
                                        lora_strength: 1,
                                        display_name: characterName,
                                    };

                                    let profilePictureUrl = base64;

                                    if (base64 && !GenerateProfilePicture) {
                                        profilePictureUrl = await createProfilePicture(userId, false, lora, '', backgroundInformation, base64, false);
                                    } else if (GenerateProfilePicture && !base64) {
                                        profilePictureUrl = await createProfilePicture(userId, true, lora, triggerWords, backgroundInformation, '', true);
                                    } else if (!base64 && !GenerateProfilePicture) {
                                        profilePictureUrl = null;
                                    }

                                    const data = await directIdentityCreation(userId, gender, modelStyle, isNSFW, characterName, description, isPublic, personality, appearance, scenario, dialogue, profilePictureUrl, lora, triggerWords, airUrl, newTriggerWords);
                                    const success = data.success;

                                    if (success) {
                                        // Clear localStorage items
                                        [
                                            'selectedGender', 'selectedStyle', 'contentType', 'characterName',
                                            'shortDescription', 'visibility', 'personalityDescription',
                                            'appearanceDescription', 'scenarioDescription', 'dialogueDescription',
                                            'characterImage', 'sha256', 'triggerWords', 'airUrl'
                                        ].forEach(item => localStorage.removeItem(item));

                                        localStorage.setItem('currentIdentityName', data.identityName);
                                        localStorage.setItem('currentIdentityCreatorId', data.userId);
                                        setShowSuccessModal(true);
                                        setShowFailureModal(false);
                                    } else {
                                        setShowSuccessModal(false);
                                        setShowFailureModal(true);
                                    }

                                    setIsLoading(false);
                                    setShowLoadingModal(false);
                                }}
                                disabled={isLoading}
                            >
                                Publish
                                <div className="arrow-front-container">
                                    <ArrowFront/>
                                </div>
                            </button>
                        )}

                    </div>

                    {showLoadingModal && ReactDOM.createPortal(
                        <div className="modal-backdrop" style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backdropFilter: 'blur(10px)',
                            background: 'rgba(0, 0, 0, 0.7)', // Darkened background
                            display: 'flex',
                            alignItems: 'center', // Centers the modal vertically
                            justifyContent: 'center', // Centers the modal horizontally
                            zIndex: 9999 // High z-index to ensure it's above other content
                        }}>

                            <div className="modal loading-modal" style={{
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1000
                            }}>
                                <div className="modal-content">
                                    <h2>Loading</h2>
                                    <p>Your character is being generated. Please wait...</p>
                                    <div className="message-loading-spin" style={{display: 'flex'}}>
                                        <MoonLoader color="white"/>
                                    </div>
                                </div>
                            </div>
                        </div>,
                        document.getElementById('modal-root')
                    )}


                    {showFailureModal && ReactDOM.createPortal(
                        <div className="modal-backdrop">
                            <div className="modal-content-notification">
                                <div className='notification-lable'>
                                    ✋ Hey there!
                                    <div className="modal-close-icon-notification"
                                         onClick={() => setShowFailureModal(false)}>
                                        <CloseIcon/>
                                    </div>
                                </div>

                                <div className='sublabel-notification'>
                                    We couldn't create your character. <br></br>
                                    {errorMessage}

                                    <div
                                        style={{marginTop: '20px'}}
                                        className="sublabel-notification"> If you think this is a mistake, please
                                        <span className="colored-purple-text"> contact support </span>
                                        <a href="https://discord.gg/V4YUYRBc5M" className="colored-purple-text-underline"
                                        >here</a>.
                                    </div>
                                </div>


                                <button onClick={() => setShowFailureModal(false)}
                                        className="notification-button-main">Okay
                                </button>


                            </div>
                        </div>
                        ,

                        document.getElementById('modal-root')
                    )}


                    {showSuccessModal && ReactDOM.createPortal(
                        <div className="modal-backdrop">
                            <div className="modal-backdrop" style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backdropFilter: 'blur(10px)',
                                background: 'rgba(0, 0, 0, 0.7)', // Darkened background
                                display: 'flex',
                                alignItems: 'center', // Centers the modal vertically
                                justifyContent: 'center', // Centers the modal horizontally
                                zIndex: 9999 // High z-index to ensure it's above other content
                            }}>

                                <div className="modal-content">
                                    <h2>Success!</h2>
                                    <p>Your character has been created successfully.</p>

                                    <div className='a-straight-line'
                                    style={{marginTop: '0'}}
                                    ></div>
                                    <div className='success-menu-footer'>


                                        <button className="random-new-button" onClick={() => {
                                            setShowSuccessModal(false); // Close the modal
                                            window.location.href = '/';
                                        }}>Back to Main Page
                                        </button>

                                        <button className="random-new-button" onClick={async () => {
                                            setShowSuccessModal(false); // Close the modal
                                            const identityName = localStorage.getItem('currentIdentityName');
                                            const creatorId = localStorage.getItem('currentIdentityCreatorId');
                                            await handleCharacterClick(identityName, creatorId);
                                            localStorage.removeItem('currentIdentityName');
                                            localStorage.removeItem('currentIdentityCreatorId');
                                        }}>Talk to Character
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-backdrop" style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 999
                            }}></div>
                        </div>,
                        document.getElementById('modal-root')
                    )}
                    {showFailureModal && (
                        <CharWarningModalCentralized
                            isOpen={showFailureModal}
                            onClose={() => setShowFailureModal(false)}
                            message={errorMessage}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateCharacterNew;
