import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const MicroSuccess = ({isOpen, onClose, children, purchase, coins, rewardType, images, audios}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    🎉 Woo-hoo!
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">


                    <div className='sublabel-notification'>
                        We've added
                        <span className="colored-purple-text"> {images} images </span>
                        and
                        <span className="colored-purple-text"> {audios} audio messages.</span>
                    </div>
                    <div className='sublabel-notification'>
                        Thank you for using Elyza!
                    </div>

                </div>


                <button onClick={onClose} className="notification-button-main">Okay!</button>


            </div>
        </div>
    );
};

export default MicroSuccess;