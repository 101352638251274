import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../icons/cross.svg';

// import {ReactComponent as CopyPasteIcon} from '../../icons/copy-paste.svg';


const ReportDislikeButton = ({isOpen, onCancel, imageUrl, reportNegativeImage, reportFormMessage, handleShortDescriptionChange}) => {



    if (!isOpen) return null;

    const modalContent = (
        <div className="modal-backdrop" onClick={onCancel}>
    <div className="main-space-finder-modal" onClick={onCancel}>
            <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>

                <div className="main-upper-text-row">
                    <div className="profile-setting-label">Having a problem?</div>
                    <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>

                </div>
                <div className="profile-setting-sublabel">No worries though, we’ve got you covered.
                    Please provide a short explanation so our team may review your complaints.
                </div>

                <div className="line-container">
                    <span className="text-line-container">REPORT</span>
                    <div className="line"></div>
                </div>
                <div className="profile-setting-sublabel">Your input matters a lot to us, please help us refine our
                    image generation and provide a short explanation of what you didn’t like.
                </div>


                <div className="textbox-name-general-short">


                    <label htmlFor="shortDescription" className="label-sign-up-h">
                        Write your complaint here:
                    </label>

                    <textarea
                        id="shortDescription"
                        placeholder="One thing wrong with the image is..."
                        rows="3"
                        maxLength="250"
                        value={reportFormMessage}
                        onChange={handleShortDescriptionChange}
                    />
                </div>


                <div className="line" style={{marginTop: '15px'}}></div>


                <div className="parent">
                    <button className="redeem-button" style={{width: "100%", height: '50px'}} onClick={() => {
                        reportNegativeImage(imageUrl, reportFormMessage);
                    }}>SUBMIT</button>
                </div>
            </div>
        </div>
        </div>
    );


    return (<>
            {ReactDOM.createPortal(modalContent, document.body)}
        </>);


};

export default ReportDislikeButton

