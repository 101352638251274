import React, {useEffect, useState} from "react";
import {NewPricingContainer} from "../components/MainMenuPage/PricingPage/NewPricingContainer";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {ReactComponent as CreditCardIcon} from "../icons/credit-card.svg";
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';
import {getUserEmail, userIDExists} from "../App";
import AnimeGirl from "../components/Banners/BannerIcons/anime1.png";
import GirlReal from "../components/Banners/BannerIcons/real1.png";
import TopTimer from "../components/Notifications/TopTimer";
import {useTimer} from "../components/Context/TimerContext";
import {AuthModal} from "../components/Forms/AuthModal";
import {useProfileData} from "../components/Context/ProfileDataContext";
import "./MicroTransactions.css"
import {A11y, Navigation} from "swiper/modules";

const NewPricingPage = () => {

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedTop, setSelectedTop] = useState(1);
    const [selectedBottom, setSelectedBottom] = useState(1);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // No decimals, adjust if needed
    });

    const toggleTopSelection = (index) => {
        if (selectedTop === index) {
            setSelectedTop(null); // Unselect if already selected
        } else {
            setSelectedTop(index); // Select new option
        }
    };

    // Toggle selection for bottom row
    const toggleBottomSelection = (index) => {
        if (selectedBottom === index) {
            setSelectedBottom(null); // Unselect if already selected
        } else {
            setSelectedBottom(index); // Select new option
        }
    };

    useEffect(() => {
        const topPrice = selectedTop !== null ? MicroTransOptions[selectedTop].numericPrice : 0;
        const bottomPrice = selectedBottom !== null ? MicroTransOptions[selectedBottom + 3].numericPrice : 0;
        setTotalPrice(currencyFormatter.format(topPrice + bottomPrice));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTop, selectedBottom, currencyFormatter]);

    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };


        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleViewMorePlans = () => {
        window.location.href = '/pricing';
    };

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const {timerExpired} = useTimer();
    const {profileData} = useProfileData();

    const placeholderPlans = [
        {
            heading: 'Premium Plan',
            features: ["Unlimited messages.", "Advanced chat model.",  "Maximum memory.", "100 images / month.", "100 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "4 Personality Profiles.", "4 Memory Fragments."]
        },
        {
            heading: 'Ultimate Plan',
            features: ["Unlimited messages.", "Advanced chat model.",  "Ultimate memory.", "500 images / month.", "500 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "Early access to new features.", "Dynamic Scenario.", "6 Personality Profiles.", "6 Memory Fragments."]
        },
        {
            heading: 'Basic Plan',
            features: ["Unlimited messages.", "Advanced chat model.",  "Better memory.", "50 images / month.", "50 audios / month.", "NSFW images.", 'Refund of poor images.', "AI Character Creation.",  "2 Personality Profiles.", "2 Memory Fragments."]
        },
        {
            heading: 'Free Plan',
            features: ["Unlimited messages.",  "Create your own exclusive characters with basic models for free.",  "Basic memory.", "Custom scenarios and response settings.", "Access to the most capable models and NSFW image generation.", "1 Personality Profile.", "1 Memory Fragment."]
        }
    ];


    const MicroTransOptions = [
        {
            heading: '+50 Images',
            price: '$9.99',
            numericPrice: 9.99,
            itemId: '50_images-USD',
            url: 'https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_addon-USD-Monthly&subscription_items[quantity][0]=1&subscription_items[item_price_id][1]=50_images-USD&subscription_items[quantity][1]=1&layout=full_page'
        },
        {
            heading: '+300 Images',
            bonusPercentage: '+20%',
            price: '$49.99',
            numericPrice: 49.99,
            itemId: '300_images-USD'
        },
        {
            heading: '+1,500 Images',
            bonusPercentage: '+25%',
            price: '$199.99',
            numericPrice: 199.99,
            itemId: '1500_images-USD'
        },
        {
            heading: '+500 Audios',
            price: '$9.99',
            numericPrice: 9.99,
            itemId: '500_audios-USD'
        },
        {
            heading: '+3,000 Audios',
            bonusPercentage: '+20%',
            price: '$49.99',
            numericPrice: 49.99,
            itemId: '3000_audios-USD'
        },
        {
            heading: '+15,000 Audios',
            bonusPercentage: '+25%',
            price: '$199.99',
            numericPrice: 199.99,
            itemId: '15000_audios-USD'
        }
    ];

    function generateCheckoutURL(firstItemPriceId = '', secondItemPriceId = '') {
        const baseURL = 'https://elyza.chargebee.com/hosted_pages/checkout';
        const layout = 'full_page';
        let url = `${baseURL}?subscription_items[item_price_id][0]=elyza_addon-USD-Monthly&subscription_items[quantity][0]=1`;

        if (firstItemPriceId.length > 0) {
            url += `&subscription_items[item_price_id][1]=${encodeURIComponent(firstItemPriceId)}&subscription_items[quantity][1]=1`;
        }

        if (secondItemPriceId.length > 0) {
            url += `&subscription_items[item_price_id][2]=${encodeURIComponent(secondItemPriceId)}&subscription_items[quantity][2]=1`;
        }

        url += `&layout=${layout}`;
        return url;
    }

    async function handlePayment() {
        let firstItem = '';
        let secondItem = '';
        if (selectedTop !== null) {
            firstItem = MicroTransOptions[selectedTop].itemId;
        }
        if (selectedBottom !== null) {
            secondItem = MicroTransOptions[selectedBottom + 3].itemId;
        }

        const url = generateCheckoutURL(firstItem, secondItem);

        const loggedIn = await validateIsLoggedIn();
        if (loggedIn) {
            setIsAuthModalOpen(false);
            window.location.href = url + `&customer[email]=${encodeURIComponent(await getUserEmail())}`
        } else {
            setIsAuthModalOpen(true);
        }
    }

    return (
        <>
            <HeaderMainMenu setSearchText={() => {
            }} searchText={''}/>

            {
                isAuthModalOpen && (
                    <AuthModal
                        isOpen={isAuthModalOpen}
                        onClose={() => setIsAuthModalOpen(false)}
                    />
                )
            }

            {!timerExpired ? (
                <div className="top-timer-container-main-pricing"
                     style={{display: timerExpired !== false ? "none" : 'block'}}
                >
                    <TopTimer/>
                </div>
            ) : null}

            <div className="new-pricing-page-container">
                {isWide && (
                    <div className="top-pricing-row-container">
                        <div className="new-toggle-pricing">
                            <div className="new-pricing-subtitle-microtrans">
                                <h2>Want more? Get more.</h2>
                                <span>Explore our packages with </span>
                                <span className="highlight-text-price"> up to 25% bonus</span>
                                <span> for the next few hours. Unselect any you wish to not purchase.</span>
                            </div>
                            <div className='select-container-microtrans'>
                                {MicroTransOptions.slice(0, 3).map((option, index) => (
                                    <div className={`microtrans-option ${selectedTop === index ? 'selected' : ''}`}
                                         key={index}
                                         onClick={() => toggleTopSelection(index)}>
                                        {option.bonusPercentage && (
                                            <div className='microtrans-option-bonus'>
                                                <div className='bonus-oval'>
                                                    <h4>{option.bonusPercentage} bonus</h4>
                                                </div>
                                            </div>
                                        )}
                                        <div className='microtrans-option-header'>
                                            <span className='plan-heading-microtrans'>{option.heading}</span>
                                            <span className='plan-subheading-microtrans'>{option.price}</span>
                                        </div>
                                        <div className='microtrans-option-features'>
                                            {/* Features would go here if needed */}
                                        </div>
                                    </div>
                                ))}
                                {MicroTransOptions.slice(3, 6).map((option, index) => (
                                    <div className={`microtrans-option ${selectedBottom === index ? 'selected' : ''}`}
                                         key={index + 3}
                                         onClick={() => toggleBottomSelection(index)}>
                                        {option.bonusPercentage && (
                                            <div className='microtrans-option-bonus'>
                                                <div className='bonus-oval'>
                                                    <h4>{option.bonusPercentage} bonus</h4>
                                                </div>
                                            </div>
                                        )}
                                        <div className='microtrans-option-header'>
                                            <span className='plan-heading-microtrans'>{option.heading}</span>
                                            <span className='plan-subheading-microtrans'>{option.price}</span>
                                        </div>
                                        <div className='microtrans-option-features'>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}>
                                <div
                                    className={`buy-right-now-button-microtrans ${totalPrice === '$0' ? 'no-select' : ''}`}
                                    onClick={handlePayment}>
                                    <CreditCardIcon/>
                                    Pay with Card
                                </div>
                                <div className="get-pricing-final">
                                    <header>You will be charged {totalPrice}</header>
                                </div>
                            </div>

                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                {!isWide && (

                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "60px" : '100px'}}
                    >
                        <div className="new-toggle-pricing">
                            <div className="left-padding-text-container">
                                <div className="new-pricing-subtitle-microtrans">
                                    <h2>Want more? Get more.</h2>
                                    <span>Explore our packages with </span>
                                    <span className="highlight-text-price"> up to 25% bonus</span>
                                    <span> for the next few hours. Unselect any you wish to not purchase.</span>
                                </div>
                            </div>
                            <div className="card-container-main-premium">
                                <div className="pricing-swiper-component-microtrans">
                                    <>
                                        <Swiper modules={[Navigation, A11y]}
                                                slidesPerView={3}
                                                centeredSlides={true}
                                                loop={false}
                                                initialSlide={1}
                                                onSwiper={(swiper) => console.log(swiper)}
                                                onSlideChange={() => console.log('slide change')}
                                        >
                                            {MicroTransOptions.slice(0, 3).map((option, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className='slide-option-mobile-micro'>

                                                        <div
                                                            className={`microtrans-option ${selectedTop === index ? 'selected' : ''}`}
                                                            onClick={() => toggleTopSelection(index)}>
                                                            {option.bonusPercentage && (
                                                                <div className='microtrans-option-bonus'>
                                                                    <div className='bonus-oval'>
                                                                        <h4>{option.bonusPercentage} bonus</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <span
                                                                className='plan-heading-microtrans'>{option.heading}</span>
                                                            <span
                                                                className='plan-subheading-microtrans'>{option.price}</span>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </>
                                </div>
                                <div className="pricing-swiper-component-microtrans">
                                    <>
                                        <Swiper modules={[Navigation, A11y]}
                                                slidesPerView={3}
                                                initialSlide={1}
                                                centeredSlides={true}
                                                loop={false}
                                                onSwiper={(swiper) => console.log(swiper)}
                                                onSlideChange={() => console.log('slide change')}
                                        >
                                            {MicroTransOptions.slice(3, 6).map((option, index) => (
                                                <SwiperSlide key={index + 3}>

                                                    <div className='slide-option-mobile-micro'>
                                                        <div
                                                            className={`microtrans-option ${selectedBottom === index ? 'selected' : ''}`}
                                                            onClick={() => toggleBottomSelection(index)}>
                                                            {option.bonusPercentage && (
                                                                <div className='microtrans-option-bonus'>
                                                                    <div className='bonus-oval'>
                                                                        <h4>{option.bonusPercentage} bonus</h4>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <span
                                                                className='plan-heading-microtrans'>{option.heading}</span>
                                                            <span
                                                                className='plan-subheading-microtrans'>{option.price}</span>
                                                        </div>

                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>

                                    </>
                                </div>
                            </div>
                        </div>
                        <div className="pricing-container-top-column-microtrans">
                            <div className={`buy-right-now-button-microtrans ${totalPrice === '$0' ? 'no-select' : ''}`}
                                 onClick={handlePayment}>
                                <CreditCardIcon/>
                                Pay with Card
                            </div>
                            <div className="get-pricing-final">
                                <header>You will be charged {totalPrice}</header>
                            </div>
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}
                <div className="bottom-of-the-page-container">
                    <div className="left-padding-text-container">
                        <h2>This is your current plan.</h2>
                        {profileData.subscription_data.tier !== 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>It’s pretty neat, but let’s be real;<br/>You’re missing out <span
                                    className="highlight-text-price">on a bunch of stuff!</span></span>
                                    {profileData.subscription_data.tier !== 'PREMIUM' && (
                                        <>

                                            <div className="buy-right-now-button-credit" onClick={handleViewMorePlans}>
                                                View other plans
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {profileData.subscription_data.tier === 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>And it seems you have maxed out!<br/>Thank you for using <span

                                    className="highlight-text-price">Elyza!</span></span>
                                </div>
                            </>
                        )}
                    </div>

                    {profileData.subscription_data.tier === 'GOLD' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Ultimate Plan')
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'PREMIUM' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Premium Plan')
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'BASIC' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Basic Plan')
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'FREE' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === 'Free Plan')
                                .map((plan, index) => (
                                    <NewPricingContainer
                                        key={index}
                                        heading={plan.heading}
                                        features={plan.features}
                                    />
                                ))}
                        </>
                    )}

                    <img src={AnimeGirl} alt="Anime Girl" className="anime-girl" draggable="false"/>

                </div>
            </div>
        </>
    );
};

export default NewPricingPage;


