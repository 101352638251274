import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../icons/cross.svg'; // Import the CloseIcon
import './ResponseSettings.css';
import Slider from '@mui/material/Slider';
import DropDownGeneral from "./DropDownGeneral";
import {getUserAccessToken, getUserID} from "../../App";
import CharacterEdit from "./CharacterEdit";
import {useProfileData} from "../Context/ProfileDataContext";
import UpgradeNewModel from "../Notifications/UpgradeNewModel";
import CustomScenarioModal from "../Notifications/CustomScenarioModal";
import {useNavigate} from "react-router-dom";
import ResponseModeMenu from "./ResponseSettings/ResponseModeMenu";
import {ReactComponent as InfoIcon} from "./ChatIcons/info-icon.svg";
import ConfirmationModal from "./ResponseSettings/ConfirmationModal";
import LoadingModal from "./ResponseSettings/LoadingModal";
import Tooltip from "@mui/material/Tooltip";
import {FormControlLabel, Switch} from "@mui/material";

const ResponseSettings = ({
                              isVisible,
                              onCancel,
                              llmModel,
                              setLlmModel,
                              maxTokens,
                              setMaxTokens,
                              temperature,
                              setTemperature,
                              responseMode,
                              setResponseMode,
                              dynamicScenarioEnabled,
                              setDynamicScenarioEnabled,
                              contextualImages,
                              setContextualImages,
                              isOwnerBoolean,
                              characterId,
                              setDeleteCurrentChat
                          }) => {
    const navigate = useNavigate();
    const [subscriptionError, setSubscriptionError] = React.useState(false);
    const {profileData} = useProfileData();
    const [showCharacterEdit, setShowCharacterEdit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isSubscriptionLocked = profileData.subscription_data.tier === "FREE" || profileData.subscription_data.tier === "BASIC";

    const handleSwitchClick = (event) => {
        if (isSubscriptionLocked) {
            // event.preventDefault();
            setIsModalOpen(true);
        } else {
            switchToggle(event);
        }
    };

    const switchToggle = (event) => {
        setDynamicScenarioEnabled(event.target.checked);
        setCacheDynamicScenarioEnabled(event.target.checked);
    };

    const handleModifyCharacter = () => {
        setShowCharacterEdit(true);
    };
    const handleClose = () => {
        setShowCharacterEdit(false);
    };

    const handleJoinClick = () => {
        window.open('https://discord.gg/V4YUYRBc5M', '_blank');
    };

    const [cacheLlmModel, setCacheLlmModel] = useState(llmModel);
    const [cacheMaxTokens, setCacheMaxTokens] = useState(maxTokens);
    const [cacheTemperature, setCacheTemperature] = useState(temperature);
    const [cacheResponseMode, setCacheResponseMode] = useState(responseMode);
    const [cacheDynamicScenarioEnabled, setCacheDynamicScenarioEnabled] = useState(dynamicScenarioEnabled);
    const [cacheContextualImages, setCacheContextualImages] = useState(contextualImages);

    const [modelSwitchExternalCache, setModelSwitchExternalCache] = useState(false);

    useEffect(() => {
        if (isOwnerBoolean === undefined) {
            // Do nothing if isOwnerBoolean is undefined
        }
    }, [isOwnerBoolean]);

    useEffect(() => {
        setCacheLlmModel(llmModel);
        setCacheMaxTokens(maxTokens);
        setCacheTemperature(temperature);
        setCacheResponseMode(responseMode);
        setCacheContextualImages(contextualImages);
        setCacheDynamicScenarioEnabled(dynamicScenarioEnabled)
    }, [llmModel, maxTokens, temperature, responseMode, dynamicScenarioEnabled, contextualImages]);

    const onInternalCancel = () => {
        setCacheLlmModel(llmModel);
        setCacheMaxTokens(maxTokens);
        setCacheTemperature(temperature);
        setCacheResponseMode(responseMode);
        setCacheContextualImages(contextualImages);
        setCacheDynamicScenarioEnabled(dynamicScenarioEnabled)
        onCancel();
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handleDeleteIdentityConfirmation = () => {
        setShowConfirmationModal(true);
    };

    let handleDeleteIdentity = async (confirmDelete) => {
        setShowConfirmationModal(false);
        if (!confirmDelete) {
            return;
        }

        setIsLoading(true); // Start loading state
        const userID = await getUserID();
        const userAccessToken = await getUserAccessToken();

        if (!userID || !characterId) {
            console.error("UserID or CharacterID missing");
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch('https://api.elyza.app/v1/delete-identity', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': userAccessToken,
                }, body: JSON.stringify({userId: userID, characterId: characterId}),
            });

            const data = await response.json();

            if (response.ok) {
                // console.log("Identity deleted successfully", data);
                setDeleteCurrentChat(true);
                onCancel(); // Assuming onCancel is a prop function to close the modal or navigate away
            } else {
                throw new Error(data.error || "Failed to delete identity");
            }
        } catch (error) {
            console.error("Error deleting identity:", error);
            // Optionally, display the error to the user here
        } finally {
            setIsLoading(false); // End loading state
        }
    };

//eslint-disable-next-line
    const submitUpdateSettings = async () => { // TODO: INTEGRATE INTO SUBMISSION
        setLlmModel(cacheLlmModel);
        setMaxTokens(cacheMaxTokens);
        setTemperature(cacheTemperature);
        setResponseMode(cacheResponseMode);
        setDynamicScenarioEnabled(cacheDynamicScenarioEnabled)
        setContextualImages(cacheContextualImages);

        const identityName = window.location.pathname.substring(6);
        await fetch('https://api.elyza.app/v1/change-settings', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(),
                identityName: identityName,
                newModel: cacheLlmModel,
                newMaxTokens: cacheMaxTokens,
                newTemperature: cacheTemperature,
                newResponseMode: cacheResponseMode,
                dynamicScenario: cacheDynamicScenarioEnabled,
                contextualImages: true
            })
        });

        onCancel();
    }


    // Function to render a modal component
    const renderCharEditModal = () => (
        <CharacterEdit
            isOpen={showCharacterEdit}
            onClose={handleClose}
            data={data}
            setData={setData}
            fetchData={fetchData}
            identityName={window.location.pathname.substring(6)}
        />
    );

    const [MinTokens, setMinTokens] = useState(null);
    const [MaxTokens, setMaxFakeTokens] = useState(null);
    const [defaultFakeValue, setDefaultFakeValue] = useState(null);

    useEffect(() => {
        if (cacheLlmModel === 'Elyza Modern') {
            setMinTokens(340);
            setMaxFakeTokens(620);
            setDefaultFakeValue(380);
            setMaxTokens(380);
        } else {
            setMinTokens(175);
            setMaxFakeTokens(512);
            setDefaultFakeValue(256);
            setMaxTokens(256);
        }
//eslint-disable-next-line
    }, [cacheLlmModel]);

    const [data, setData] = useState({}); // Default to an empty object or suitable default
    const [loading, setLoading] = useState(false);
    const identityName = window.location.pathname.substring(6);

    const fetchData = async () => {
        setLoading(true);
        try {
            const userId = await getUserID();
            const userAccessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/direct-identity-info', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({userId, identityName})
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status}`);
            }

            const directIdentityInfo = await response.json();

            if (directIdentityInfo.data) {
                setData(directIdentityInfo.data);
            } else {
                // Handle case where no data is returned
                setData({}); // You could set defaults or handle this case as needed
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000); // Add a delay of 1 second before setting loading to false
        }
    }

    useEffect(() => {
        if (identityName && identityName.trim() !== "" && isOwnerBoolean) {
            fetchData();
        }
        //eslint-disable-next-line
    }, [identityName, isOwnerBoolean]);



    if (!isVisible) return null;
    const modalContent = (
        <>
            <div className="main-space-finder-modal"
                 style={{backgroundColor: window.innerWidth < 680 ? '#131313' : ''}}
            >
                {subscriptionError && <UpgradeNewModel isOpen={subscriptionError} onClose={(confirm) => {
                    setSubscriptionError(false);
                    if (confirm) {
                        navigate('/pricing');
                    }
                }}/>}
                <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>

                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">Character Settings</div>
                        <div className="close-modal-icon" onClick={onInternalCancel}><CloseIcon/>

                        </div>

                    </div>
                    <div className="main-elyza-text-response-settings">You may edit chat settings as you prefer.</div>

                    <div className="line-container">
                        <span className="text-line-container">MODEL SELECTION</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">You may select a model from the options suggested
                        to
                        change, different models will have different effects on your interactions with AI.
                    </div>

                    <div className="drop-down-menu">
                        <DropDownGeneral
                            value={cacheLlmModel}
                            setValue={setCacheLlmModel}
                            setLlmModel={setLlmModel}
                            modelSwitchExternalCache={modelSwitchExternalCache}
                            setModelSwitchExternalCache={setModelSwitchExternalCache}
                        />
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">CHAT SETTINGS</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">Here you may select more specific response
                        settings which will directly change the way AI responses to your messages.
                    </div>

                    <div className="slider-label"
                         style={{marginTop: '5px'}}
                    >Dynamic Scenario
                        <Tooltip title="Enables and disables dynamic scenario." placement="top" arrow>
                            <InfoIcon/>
                        </Tooltip>

                        <div className='two-switches-menu'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={cacheDynamicScenarioEnabled}
                                        // disabled={isSubscriptionLocked}
                                        onChange={handleSwitchClick}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: 'white', // Thumb color when checked
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                                },
                                            }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#9072D9', // Track color when checked
                                            }, '& .MuiSwitch-track': {
                                                backgroundColor: 'grey', // Track color when unchecked
                                            },
                                        }}
                                    />}
                                labelPlacement="start"/>
                        </div>
                    </div>

                    <div className="main-elyza-text-response-settings">This premium feature enhances our system's
                        ability to create and update dynamic scenarios, allowing for improved memory and more stable
                        dialogue development.
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label">Response Length
                            <Tooltip title="Corresponds to how much text is allowed for AI to be outputted."
                                     placement="top" arrow>
                                <InfoIcon/>
                            </Tooltip>
                        </div>
                        <Slider
                            value={cacheMaxTokens}
                            onChange={(event, newValue) => {
                                if (profileData.subscription_data.tier === "FREE") {
                                    setSubscriptionError(true);
                                } else {
                                    setCacheMaxTokens(newValue);
                                }
                            }}
                            aria-labelledby="response-length-slider"
                            valueLabelDisplay="auto"
                            defaultValue={defaultFakeValue}
                            min={MinTokens}
                            max={MaxTokens}
                            step={1}

                            sx={{
                                color: '#916DE8', padding: '0px', '& .MuiSlider-thumb': {
                                    backgroundColor: '#6335D2', width: '16px', height: '16px',
                                }, '& .MuiSlider-rail': {
                                    height: '8px', // Adjust this to make the slider track thicker
                                }, '& .MuiSlider-track': {
                                    height: '8px', // Adjust this to make the slider track thicker
                                },
                            }}
                        />
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', fontSize: '0.75em', color: '#E3E3E3'
                        }}>
                            <span>{MinTokens}</span>
                            <span>{MaxTokens}</span>
                        </div>
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label">Creativity
                            <Tooltip title="Temperature of the prompt" placement="top" arrow>
                                <InfoIcon/>
                            </Tooltip>
                        </div>
                        <Slider
                            defaultValue={0.77} // Set default value
                            value={typeof cacheTemperature === 'number' ? cacheTemperature : 0.77} // Ensure value is in the correct range
                            onChange={(event, newValue) => {
                                if (profileData.subscription_data.tier === "FREE") {
                                    setSubscriptionError(true);
                                } else {
                                    setCacheTemperature(newValue);
                                }
                            }}
                            aria-labelledby="response-length-slider"
                            min={0.65}
                            max={1}
                            step={0.01}
                            valueLabelDisplay="auto"
                            sx={{
                                color: '#916DE8', padding: '0px', '& .MuiSlider-thumb': {
                                    backgroundColor: '#6335D2', width: '16px', height: '16px',
                                }, '& .MuiSlider-rail': {
                                    height: '8px',
                                }, '& .MuiSlider-track': {
                                    height: '8px',
                                },
                            }}
                        />
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', fontSize: '0.75em', color: '#E3E3E3'
                        }}>
                            <span>Rigid</span>
                            <span>Creative</span>
                        </div>
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label">Response Mode
                            <Tooltip
                                title="Each mode represent a different setting for how AI will respond to you. Try them all out to understand the difference!"
                                placement="top" arrow>
                                <InfoIcon/>
                            </Tooltip>
                        </div>

                        <ResponseModeMenu
                            activeMode={responseMode}
                            onModeChange={setResponseMode}
                        />

                    </div>
                    <div className="a-straight-line-3"
                         style={{marginTop: '10px'}}
                    ></div>


                    <div className="footer-button-container"
                         style={{justifyContent: isOwnerBoolean ? 'flex-end' : 'space-between'}}>
                        {isOwnerBoolean && (<>
                            <div className="owner-custom-settings">
                                <button
                                    className="big-blue-button"
                                    onClick={handleModifyCharacter}
                                    disabled={loading}>
                                    Modify this character
                                </button>
                                <button className="big-red-button" onClick={handleDeleteIdentityConfirmation}>
                                    Delete this character
                                </button>
                            </div>
                        </>)}

                        <button className="need-help-button" onClick={handleJoinClick}
                                style={{width: isOwnerBoolean ? '30%' : '30%'}}>
                            Need help?
                        </button>

                        <button className="save-settings-button" onClick={submitUpdateSettings}
                                style={{width: isOwnerBoolean ? '35%' : '65%'}}>
                            Save
                        </button>
                    </div>

                </div>
            </div>
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={() => handleDeleteIdentity(true)}
            />

            <LoadingModal isOpen={isLoading}/>

            <CustomScenarioModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />


        </>
    );

    return (<>
        {ReactDOM.createPortal(modalContent, document.body)}
        {showCharacterEdit && ReactDOM.createPortal(renderCharEditModal(), document.body)}
    </>);
};

export default ResponseSettings;

