import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as TrashCan } from './icons/TrashCan.svg';

const CharacterStep6 = ({ imageStyles }) => {
    const [bestImageStyle, setBestImageStyle] = useState(() => localStorage.getItem('BestImageStyle') || null);

    useEffect(() => {
        localStorage.setItem('BestImageStyle', bestImageStyle);
    }, [bestImageStyle]);


    const handleStyleSelect = (style) => {
        const { SHA256, airUrl, trainedWords, imageUrl } = style;

        if (airUrl) {
            // This is a Pony Diffusion model
            localStorage.setItem('airUrl', airUrl);
            localStorage.setItem('ponyImageUrl', imageUrl);
            setBestImageStyle(airUrl);
        } else {
            // This is a regular model
            localStorage.setItem('sha256', SHA256);
            setBestImageStyle(SHA256);
        }

        // Convert trainedWords array to a comma-separated string
        const trainedWordsString = Array.isArray(trainedWords) ? trainedWords.join(',') : trainedWords;
        localStorage.setItem('triggerWords', trainedWordsString);
    };

    const handleRemoveStyle = () => {
        localStorage.removeItem('BestImageStyle');
        localStorage.removeItem('sha256');
        localStorage.removeItem('airUrl');
        localStorage.removeItem('ponyImageUrl');
        localStorage.removeItem('triggerWords');
        setBestImageStyle(null);
    };

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setViewportWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const allStyles = imageStyles.ponyLoraItems;

    return (
        <div className="gender-selection-container">
            <div className="char-created-label-3">Let's choose the best base image.</div>
            <span className="profile-setting-sublabel">
                This base image will help generate other images of this character.
            </span>

            <div className="gender-options-container"
                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {allStyles.length > 0 ? (
                    allStyles.map((style) => (
                        <div
                            key={style.airUrl || style.SHA256}
                            className={`gender-option ${bestImageStyle === (style.airUrl || style.SHA256) ? 'gender-option-selected' : ''}`}
                            onClick={() => handleStyleSelect(style)}
                        >
                            <LazyLoadImage
                                src={style.imageUrl}
                                alt={style.name}
                                className="gender-image"
                                effect="blur"
                                style={{
                                    pointerEvents: 'none',
                                    width: viewportWidth <= 680 ? '180px' : '240px',
                                    height: viewportWidth <= 680 ? '180px' : '320px'
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <p>No image styles available. Please try again.</p>
                )}
            </div>
            <span style={{display: 'flex', marginTop: '10px', marginBottom: '10px', marginLeft: '5px'}}
                  className="profile-setting-sublabel">Note: This will change how the image turns out, and CANNOT be changed after the character was created, be sure to check well before applying.</span>

            <button className='image-reupload-prompt' style={{marginLeft: '5px'}} onClick={handleRemoveStyle}>
                <TrashCan/>
                Clear Styles
            </button>
        </div>
    );
};

export default CharacterStep6;