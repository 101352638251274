import React from 'react';
import './BannerStyle.css';
import {ReactComponent as Discord} from './BannerIcons/discordbanner.svg';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';

const JoinCommunityBanner = ({ isBannerVisibleFirstDiscord, hideBanner }) => {
    const handleClick = () => {
        window.open('https://discord.gg/V4YUYRBc5M', '_blank');
    };


    if (!isBannerVisibleFirstDiscord) return null;

    return (
        <div className="banner-container banner-state-1">
            <div className="banner-content">
                <div className="banner-left">
                    <span className="banner-title">Join Our Community</span>
                    <span className="banner-subtitle">on Discord
                        <div className='banner-icon'><Discord/></div>
                    </span>
                </div>
                <div className="banner-right">
                    <div className="center-component-banner">
                        <button className="banner-button button-state-1" onClick={handleClick}>
                            <StarIcon className='star-icon-banner'/>
                            Join Server
                        </button>
                        <span className='sub-button-text' onClick={hideBanner}>Remind me to join later</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinCommunityBanner;
