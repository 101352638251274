import React, { useState, useEffect } from 'react';
import './CreateCharacter.css';
import { ReactComponent as Generate } from "./icons/generate.svg";
import {getUserID} from "../../App";
import Tooltip from '@mui/material/Tooltip';
import UpgradeNotification from "../Notifications/UpgradeNotification";
import {useNavigate} from "react-router-dom";

const CharacterStep5 = ({generateAiEntry, subscriptionError}) => {
    // Initializing state from local storage or default values
    const [personalityDescription, setPersonalityDescription] = useState(() => localStorage.getItem('personalityDescription') || '');
    const [appearanceDescription, setAppearanceDescription] = useState(() => localStorage.getItem('appearanceDescription') || '');
    const [scenarioDescription, setScenarioDescription] = useState(() => localStorage.getItem('scenarioDescription') || '');
    const [dialogueDescription, setDialogueDescription] = useState(() => localStorage.getItem('dialogueDescription') || '');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    //eslint-disable-next-line
    const [characterName, setCharacterName] = useState(() => localStorage.getItem('characterName') || '');
    //eslint-disable-next-line
    const [shortDescription, setShortDescription] = useState(() => localStorage.getItem('shortDescription') || '');
    // Effect to update local storage whenever the state changes
    useEffect(() => {
        localStorage.setItem('personalityDescription', personalityDescription);
        localStorage.setItem('appearanceDescription', appearanceDescription);
        localStorage.setItem('scenarioDescription', scenarioDescription);
        localStorage.setItem('dialogueDescription', dialogueDescription);
    }, [personalityDescription, appearanceDescription, scenarioDescription, dialogueDescription]);

    // Handlers for each text area input change
    const handlePersonalityDescriptionChange = (e) => {
        setPersonalityDescription(e.target.value);
    };

    const handleAppearanceDescriptionChange = (e) => {
        setAppearanceDescription(e.target.value);
    };

    const handleScenarioDescriptionChange = (e) => {
        setScenarioDescription(e.target.value);
    };

    const handleDialogueDescriptionChange = (e) => {
        setDialogueDescription(e.target.value);
    };



    const [showUpgradeNotification, setShowUpgradeNotification] = useState(false); // State to control UpgradeNotification visibility

    const context = {
        characterName: localStorage.getItem('characterName') || characterName,
        shortDescription: localStorage.getItem('shortDescription') || shortDescription,
        scenarioDescription: scenarioDescription,
        appearanceDescription: appearanceDescription,
        dialogueDescription: dialogueDescription,
        personalityDescription: personalityDescription,
    };


    const generatePersonalityDescription = async () => {

        setIsLoading(true);
        if (subscriptionError) {
            setShowUpgradeNotification(true); // Show upgrade notification on subscription error
            setIsLoading(false);
            return;
        }
        setError(null);
        setPersonalityDescription("");
        try {
            const userId = await getUserID();
            const type = "personality";
            const currentVersion = "";
            const backgroundInformation = JSON.stringify(context);
            const fandomLink = "";
            const generatedDescription = await generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink);
            setPersonalityDescription(generatedDescription);
        } catch (error) {
            console.error("Failed to generate AI entry:", error);
            setError("Failed to generate description. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };
    const generateAppearanceDescription = async () => {
        setIsLoading(true);
        if (subscriptionError) {
            setShowUpgradeNotification(true); // Show upgrade notification on subscription error
            setIsLoading(false);
            return;
        }
        setError(null);
        setAppearanceDescription("");
        try {
            const userId = await getUserID();
            const type = "appearance";
            const currentVersion = "";
            const backgroundInformation = JSON.stringify(context);
            const fandomLink = "";
            const generatedDescription = await generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink);
            setAppearanceDescription(generatedDescription);
        } catch (error) {
            console.error("Failed to generate AI entry:", error);
            setError("Failed to generate description. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };
    const generateScenarioDescription = async () => {
        setIsLoading(true);
        if (subscriptionError) {
            setShowUpgradeNotification(true); // Show upgrade notification on subscription error
            setIsLoading(false);
            return;
        }
        setError(null);
        setScenarioDescription("");
        try {
            const userId = await getUserID();
            const type = "scenario";
            const currentVersion = "";
            const backgroundInformation = JSON.stringify(context);
            const fandomLink = "";
            const generatedDescription = await generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink);
            setScenarioDescription(generatedDescription);
        } catch (error) {
            console.error("Failed to generate AI entry:", error);
            setError("Failed to generate description. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };
    const generateDialogueDescription = async () => {
        setIsLoading(true);
        if (subscriptionError) {
            setShowUpgradeNotification(true); // Show upgrade notification on subscription error
            setIsLoading(false);
            return;
        }
        setError(null);
        setDialogueDescription("");
        try {
            const userId = await getUserID();
            const type = "dialogue";
            const currentVersion = "";
            const backgroundInformation = JSON.stringify(context);
            const fandomLink = "";
            const generatedDescription = await generateAiEntry(userId, type, currentVersion, backgroundInformation, fandomLink);
            setDialogueDescription(generatedDescription);
        } catch (error) {
            console.error("Failed to generate AI entry:", error);
            setError("Failed to generate description. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = useNavigate();

    return (
        <div className="gender-selection-container">
            <div className="char-created-label-3">Let’s structure your character.</div>
            <span className="profile-setting-sublabel">This will determine how your character acts, thinks, and responds to you.</span>


            <div className="textbox-generate-button-general">
                <div className="textbox-name-general-short">
                    <Tooltip title="3000 characters max" placement="top" arrow>
                    <label htmlFor="personalityDescription" className="label-sign-up-h">Personality Description</label>
                    </Tooltip>

                    <textarea
                        id="personalityDescription"
                        value={personalityDescription}
                        onChange={handlePersonalityDescriptionChange}
                        rows="4"
                        maxLength="3000"
                        cols="50"
                        placeholder="Personality description of the character, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."

                    />
                </div>
                <Tooltip title="Generate With AI">
                <button
                    className={`generate-w-ai-button ${isLoading ? 'loading' : ''}`}
                    onClick={generatePersonalityDescription}
                    disabled={isLoading}
                >
                    Generate <Generate />
                </button>
                </Tooltip>

                {error && <p className="error">{error}</p>}
            </div>

            <div className="textbox-generate-button-general">
                <div className="textbox-name-general-short">
                <Tooltip title="2000 characters max" placement="top" arrow>
                    <label htmlFor="appearanceDescription" className="label-sign-up-h">Appearance Description</label>
                </Tooltip>

                <textarea
                        id="appearanceDescription"
                        value={appearanceDescription}
                        onChange={handleAppearanceDescriptionChange}
                        rows="4"
                        maxLength="2000"
                        cols="50"
                        placeholder="Appearance description of the character, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."

                    />
                </div>
                <Tooltip title="Generate With AI">
                <button
                    className={`generate-w-ai-button ${isLoading ? 'loading' : ''}`}
                    onClick={generateAppearanceDescription}
                    disabled={isLoading}
                >
                    Generate <Generate />
                </button>
                </Tooltip>

                {error && <p className="error">{error}</p>}
            </div>


            <div className="textbox-generate-button-general">
                <div className="textbox-name-general-short">
                    <Tooltip title="1500 characters max" placement="top" arrow>
                    <label htmlFor="scenarioDescription" className="label-sign-up-h">Scenario Description</label>
                    </Tooltip>
                    <textarea
                        id="scenarioDescription"
                        value={scenarioDescription}
                        onChange={handleScenarioDescriptionChange}
                        rows="4"
                        maxLength="1500"
                        cols="50"
                        placeholder="Scenario description of the character, where the character is, what they are doing, etc, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."
                    />
                </div>
                <Tooltip title="Generate With AI">
                <button
                    className={`generate-w-ai-button ${isLoading ? 'loading' : ''}`}
                    onClick={generateScenarioDescription}
                    disabled={isLoading}
                >
                    Generate <Generate />
                </button>
                </Tooltip>

                {error && <p className="error">{error}</p>}
            </div>

            <div className="textbox-generate-button-general">
                <div className="textbox-name-general-short">
                    <Tooltip title="1500 characters max" placement="top" arrow>
                    <label htmlFor="dialogueDescription" className="label-sign-up-h">First Message</label>
                    </Tooltip>
                    <textarea
                        id="dialogueDescription"
                        value={dialogueDescription}
                        onChange={handleDialogueDescriptionChange}
                        rows="4"
                        maxLength="1500"
                        cols="50"
                        placeholder="This will function as the first message from the character. You shall use {{user}} and {{char}} to refer to the character in the dialogue."
                    />
                </div>
                <Tooltip title="Generate With AI">
                <button
                    className={`generate-w-ai-button ${isLoading ? 'loading' : ''}`}
                    onClick={generateDialogueDescription}
                    disabled={isLoading}
                >
                    Generate <Generate />
                </button>
                    </Tooltip>
                {error && <p className="error">{error}</p>}
            </div>


            <div className="profile-setting-sublabel-1">Please review your structure. You can use generate to help fill in some fields.</div>
            {showUpgradeNotification && (
                <UpgradeNotification isOpen={showUpgradeNotification} onClose={(confirm) => {
                    setShowUpgradeNotification(false);
                    if (confirm) {
                        navigate('/pricing');
                    }
                }} />
            )}
        </div>
    );
};

export default CharacterStep5;
