import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useProfileData} from "../Context/ProfileDataContext";

const CustomizationLimitUpsell = ({ isOpen, onClose, type }) => {

    function getArticle(word) {
        const vowels = ['a', 'e', 'i', 'o', 'u'];
        return vowels.includes(word[0].toLowerCase()) ? 'an' : 'a';
    }

    const { profileData } = useProfileData();
    const currentTier = profileData.subscription_data.tier.toLowerCase();
    const actionType = type === 'personalities' ? "Add More Profiles" : "Add More Fragments";
    const MemPer = type === 'personalities' ? "Personality" : "Memories";

    if (!isOpen) return null;

    const tierMap = {
        'FREE': 'Free',
        'BASIC': 'Basic',
        'PREMIUM': 'Premium',
        'GOLD': 'Ultimate'
    };

    const upgradeTierMap = {
        'FREE': 'Basic',
        'BASIC': 'Premium',
        'PREMIUM': 'Ultimate',
        'GOLD': 'Ultimate'
    };

    const displayTier = tierMap[currentTier] || currentTier;
    const upgradeTier = upgradeTierMap[currentTier] || 'Ultimate';

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop" style={{zIndex: "9999",
            backdropFilter: "blur(10px)"
            }}>
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        ✋ Whoa there!
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            You have reached your
                            <span className="colored-purple-text"> {MemPer} Limits </span>
                            as a {displayTier} user.
                            <br></br>
                            <br></br>
                            Get
                            <span className="colored-purple-text"> increased limits </span>
                            as {getArticle(upgradeTier)}
                            <span className="colored-purple-text"> {upgradeTier} </span>
                            user.
                            <br></br>
                            <br></br>
                            <span className="colored-purple-text"> Upgrade Now </span>
                            to {actionType}.
                        </div>
                    </div>

                    <button onClick={() => {
                        onClose(true)
                    }} className="notification-button-main">Upgrade Now</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default CustomizationLimitUpsell;