import { ReactComponent as ElyzaLogoIcon } from "./icons/ElyzaLogoFixed.svg";
import './SalesNavbar.css';
// import { Link as ScrollLink } from 'react-scroll';
import SalesHero from "./SalesHero";

const SalesNavbar = () => {
    // const navigate = useNavigate();

    // const handleLoginClick = () => {
    //     navigate('/');
    // };
    //
    // const handleSignUpClick = () => {
    //     navigate('/');
    // };

    return (

        <div className="sales-navbar">.

            <div className="ElyzaSalesPageLogo">
            <ElyzaLogoIcon />
        </div>


            {/*<div className="sales-navbar-buttons">*/}
            {/*    <button className="login-button-sales-page" onClick={handleLoginClick}>Login</button>*/}
            {/*    <button className="signup-button-sales-page" onClick={handleSignUpClick}>Sign Up</button>*/}
            {/*</div>*/}
        </div>

    );
};

const SalesPage = () => {
    return (
        <div className="first-gradient">
            <>
                <SalesNavbar/>
                <SalesHero/>
            </>
        </div>
    );
};

export default SalesPage;
