import React, { useState } from 'react';
import './BannerStyle.css';
import { ReactComponent as StarIcon } from './BannerIcons/buttonIcon.svg';
// import ReferralEarnMenu from "../Referral/ReferralEarnMenu";
import ReferralMenu from "./Referral/ReferralMenu";

const ReferUsersBanner = ({ isBannerVisible, hideBanner}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [isEarnMenuOpen, setIsEarnMenuOpen] = useState(false);
    // const [isRedeemMenuOpen, setIsRedeemMenuOpen] = useState(false);

    // const openRedeemMenu = () => {
    //     setIsEarnMenuOpen(false);
    //     setIsRedeemMenuOpen(true);
    // };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // Here is where we integrate the hideBanner function
    const handleHideBanner = () => {
        hideBanner(); // This function will be provided by the parent component and should update the state to hide the banner.
    };

    if (!isBannerVisible) return null;

    return (
        <div className="banner-container banner-state-2">
            <div className="banner-content">
                <div className="banner-left">
                    <span className="banner-title">Refer users</span>
                    <span className="banner-subtitle">Earn tokens
                        <div className='banner-icon'></div>
                    </span>
                </div>
                <div className="banner-right">
                    <div className="center-component-banner">
                        <button className="banner-button button-state-2" onClick={handleOpenModal}>
                            <StarIcon className='star-icon-banner'/>
                            Refer Now
                        </button>
                        <span className='sub-button-text' onClick={handleHideBanner}>Remind me to refer later</span>
                    </div>
                </div>
            </div>

            <ReferralMenu

                isOpen={isModalOpen}
                onCancel={handleCloseModal}

            />
        </div>
    );
};

export default ReferUsersBanner;
