import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const CharWarningModalCentralized = ({ isOpen, onClose, message }) => {
    if (!isOpen) return null;

    let content = {};

    switch (message) {
        case 'Some concerns detected. Please be cautious.':
            content = {
                title: '⚠️ Concerns Detected',
                body: 'We\'ve detected some concerns with your recent activity. Please review our terms of service and proceed with caution.',
                contactSupport: 'Do not contact us regarding this issue.',
                action: 'The character you created may be subject to review.',
                buttonText: 'I Understand'
            };
            break;
        case 'Content violates our policies':
            content = {
                title: '🚫 Violation Detected',
                body: 'A severe violation of our terms of service has been detected. The content you provided is not allowed on our platform.',
                contactSupport: 'Do not contact support regarding this issue.',
                action: 'Your account may be subject to further action.',
                buttonText: 'Acknowledge'
            };
            break;
        default:
            content = {
                title: '📢 Uh oh!',
                body: 'Something went wrong. It might be something on our end. Please try again later.',
                contactSupport: 'If the issue persists, please contact support.',
                buttonText: 'OK'
            };
    }

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        {content.title}
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {content.body}
                            <br></br>
                            <br></br>
                            {content.action}
                            <br></br>
                            <br></br>
                            {content.contactSupport}
                            <br></br>
                        </div>
                    </div>

                    <button onClick={() => {onClose(true)}} className="notification-button-main">{content.buttonText}</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default CharWarningModalCentralized;