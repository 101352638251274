import React, {useCallback, useEffect, useRef, useState} from 'react';
import './ChatMainMenu.css';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";
import {CharacterDeleteForm} from "./CharacterDeleteForm";
import {getUserAccessToken, getUserID, userIDExists} from "../../App";
import {ReactComponent as SearchIcon} from '../../icons/search-chat.svg';
import {ReactComponent as DeleteIcon} from '../../icons/delete.svg';
import {ReactComponent as GroupChatIcon} from "../../icons/multiplepeople.svg";
import {ReactComponent as CloseMainMenu} from "../../icons/message/close-the-menu.svg";
import {ReactComponent as CustomizationIcon} from "./ChatIcons/customization-icon.svg";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RecentChatListLoader from "../LoadingEvents/RecentChatListLoader";
import {useTimer} from "../Context/TimerContext";
import {useChat} from "../Context/ChatContext";
import debounce from 'lodash/debounce';
import CustomizationModal from "./CustomizationScreen/CustomizationModal";

const RecentChatList = ({
                            isLoading,
                            setLocalChats,
                            localChats,
                            profileUsername,
                            setCurrentCharName,
                            chatContainerRef,
                            setShowChatMainMenu,
                            isMobile,
                            setLlmModel,
                            setMaxTokens,
                            setTemperature,
                            setResponseMode,
                            // setNsfwEnabled,
                            setDynamicScenarioEnabled,

                            setContextualImages,
                            clientUpdateData,
                            setIsOwnerBoolean,
                            deleteCurrentChat,
                            setDeleteCurrentChat,
                        }) => {
    const navigate = useNavigate();
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [isDeletionFormOpen, setIsDeletionFormOpen] = useState(false);
    const [deletionData, setDeletionData] = useState([]);
    const [customizationModalState, setCustomizationModalState] = useState(false);

    async function fetchMemoriesAndPersonalities() {
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);

        if (identityName.length === 0) {
            return;
        }

        try {
            const response = await fetch('https://api.elyza.app/v1/fetch-memories-and-personalities', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({userId, identityName})
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching memories and personalities:", error);
            throw error;
        }
    }

    const [memoriesAndPersonalities, setMemoriesAndPersonalities] = useState(null);

    useEffect(() => {
        if (window.innerWidth > 680) {
            fetchMemoriesAndPersonalities()
                .then(result => setMemoriesAndPersonalities(result))
                .catch(error => console.error("Failed to fetch memories and personalities:", error));
        }
        //eslint-disable-next-line
    }, [window.location.pathname]);

    const handleCustomizationModal = () => {
        setCustomizationModalState(!customizationModalState);
    };

    const {
        isMenuOpen,
        toggleMenu,
        setIsActiveModal,
        setWasRecentChatUpdated
    } = useChat();

    useEffect(() => {
        if (customizationModalState) {
            setIsActiveModal(true);
        }

        return () => {
            if (customizationModalState) {
                setIsActiveModal(false);
            }
        };
    }, [customizationModalState, setIsActiveModal]);


    const useDebounceRender = (initialState = false, delay = 50) => {
        const [isRendering, setIsRendering] = useState(initialState);
        const [shouldRender, setShouldRender] = useState(initialState);
//eslint-disable-next-line react-hooks/exhaustive-deps
        const debouncedSetShouldRender = useCallback(
            debounce(setShouldRender, delay),
            [delay]
        );

        useEffect(() => {
            if (isRendering) {
                debouncedSetShouldRender(true);
            } else {
                setShouldRender(false);
                debouncedSetShouldRender.cancel();
            }
        }, [isRendering, debouncedSetShouldRender]);

        return [shouldRender, setIsRendering];
    };

    const [shouldRender, setIsRendering] = useDebounceRender();

    const {
        setDescription, setScenario, setProfileURLBanner, setActiveComponent,
    } = useChat();

    useEffect(() => {
        setActiveComponent('RecentChatList');
        return () => {
            setActiveComponent('');
        };
    }, [setActiveComponent]);

    useEffect(() => {
        if (clientUpdateData === null || clientUpdateData === undefined) {
            return;
        }
        const filteredChatsCopy = filteredChats;
        for (let i = 0; i < filteredChatsCopy.length; i++) {
            if (filteredChatsCopy[i].identityName === clientUpdateData.identityName) {
                filteredChatsCopy[i].lastMessageTime = clientUpdateData.newTimestamp;
                filteredChatsCopy[i].lastMessageContent = clientUpdateData.finalMessage;
            }
        }
        const sortedByTimestamp = filteredChatsCopy.sort((a, b) => {
            const dateA = new Date(a.lastMessageTime);
            const dateB = new Date(b.lastMessageTime);
            return dateB - dateA;
        });
        setFilteredChats(sortedByTimestamp);

        // eslint-disable-next-line
    }, [clientUpdateData]);

    useEffect(() => {
        const characterIdentity = window.location.pathname.substring(6);
        setTimeout(() => {
        }, 1000);
        renderChats(characterIdentity);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    useEffect(() => {
        if (deleteCurrentChat === true) {
            setDeleteCurrentChat(false);
            deleteChat(selectedChat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCurrentChat]);

    const deleteChat = (chat) => {
        setChats(chats.filter((c) => c !== chat));
        setLocalChats(chats.filter((c) => c !== chat));
        const x = chats.filter((c) => c !== chat);
        setIsDeletionFormOpen(false);
        setDeletionData([]);
        // No network based rendering here
        setTimeout(() => {
            if (x.length > 0) {
                handleChatSelection(x[0]);
            } else {
                navigate("/chat")
                setSelectedChat(null);
                // setIdentityName("");
            }
        }, 100);
    }

    const renderChats = async (identity) => {
        setIsRendering(true);
        try {
            const exists = await userIDExists();
            if (!exists) {
                setIsRendering(false);
                return;
            }

            const id = await getUserID();

            let chatsData;
            // Use existing chats if available
            if (localChats.length > 0) {
                chatsData = localChats;
            } else {
                // Fetch chats if not available locally
                const response = await fetch('https://api.elyza.app/v1/recent-chat', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: await getUserAccessToken(),
                    },
                    body: JSON.stringify({userId: id})
                });

                const data = await response.json();
                setWasRecentChatUpdated(true);
                chatsData = data.filter(item => item.identityName !== undefined);
            }

            updateChatsAndSelection(chatsData, identity);

        } catch (error) {
            console.error('Error in renderChats:', error);
        } finally {
            setIsRendering(false);
        }
    };

    const updateChatsAndSelection = (chatsData, identity) => {
        const selectedChat = chatsData.find(chat => chat.identityName === identity);

        if (selectedChat) {
            setIsOwnerBoolean(selectedChat.isOwner === true);
            setSelectedChat(selectedChat);
            handleChatSelection(selectedChat);

            // Update character info
            const {description, characterScenario, imageSrc} = selectedChat;
            setDescription(description);
            setScenario(characterScenario);
            setProfileURLBanner(imageSrc);
        } else if (chatsData.length > 0) {
            handleChatSelection(chatsData[0]);
        }

        setChats(chatsData);
        setLocalChats(chatsData);
    };

    const handleChatSelection = (chat, isUserSelection = false) => {

        if (isMobile && !isUserSelection && chat !== selectedChat) {
            return;
        }

        if (isMobile) {
            if (isLoading && selectedChat === undefined) {
                // console.log("Failed selection???")
                return;
            }
        } else if (isLoading) {
            // console.log("Failed selection???")
            return;
        }

        setCurrentCharName(chat.name);
        navigate(`/chat/${chat.identityName}`)
        setSelectedChat(chat);

        if (chat.llmModel !== undefined) {
            setLlmModel(chat.llmModel);
        }
        if (chat.maxTokens !== undefined) {
            setMaxTokens(chat.maxTokens);
        }
        if (chat.temperature !== undefined) {
            setTemperature(chat.temperature);
        }
        if (chat.responseMode !== undefined) {
            setResponseMode(chat.responseMode);
        }
        if (chat.dynamicScenario !== undefined) {
            setDynamicScenarioEnabled(chat.dynamicScenario);
        }
        if (chat.contextualImages !== undefined) {
            setContextualImages(chat.contextualImages);
        }
        if (chat.contextualImages !== undefined) {
            setContextualImages(chat.contextualImages);
        }
        scrollToBottom();

        if (chatContainerRef.current) {
            const images = chatContainerRef.current.getElementsByTagName('img');
            Array.from(images).forEach((img) => {
                if (img.complete) {
                    // Image is not yet loaded, attach an event listener
                    img.addEventListener('load', scrollToBottom);
                    img.addEventListener('error', scrollToBottom); // Handle broken images too
                }
            });
        }

        setShowChatMainMenu(true);

    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.style.scrollBehavior = 'smooth';
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                chatContainerRef.current.style.scrollBehavior = 'auto';
            }
        }, 1000);
    }

    const handleChatDeletion = (chat) => {

        if (isLoading) {
            return;
        }
        setDeletionData(chat);
        setIsDeletionFormOpen(true);

    };

    const handleChatDeletionMobile = (chatToDelete, event) => {

        if (event && touchInitiatedRef.current) {
            event.stopPropagation(); // Prevent click event from propagating if it's a touch-induced click
            return; // Skip click handling as the touch event has already been processed
        }

        const confirmDeletion = async () => {

            const id = await getUserID();
            const postData = {
                userId: id, identityName: chatToDelete.identityName // Ensure chatToDelete object has identityName property
            };
            fetch('https://api.elyza.app/v1/delete-character', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify(postData)
            })
                .then(response => response.json())
                .then(data => {
                    // console.log(data);
                    // Update state to trigger re-render
                    setChats(currentChats => currentChats.filter(chat => chat.identityName !== chatToDelete.identityName));
                })
                .catch(error => {
                    console.error('Deletion failed:', error);
                });
        };

        if (window.confirm('Are you sure you want to delete this chat?')) {
            confirmDeletion();
        }
    };

    const truncateMessage = (message, limit = 50, charName = "", chatIdentityName) => {
        if (!message) {
            return "Unknown message";
        }

        // Check if a draft exists in localStorage for the specific character
        const draftMessage = localStorage.getItem(`message-${chatIdentityName}`);
        if (draftMessage) {
            message = `*Draft:* ${draftMessage}`;
        }

        message = message.replace(/\*/g, "");
        message = message.replace(/{{char}}/g, charName)
            .replace(/{{user}}/g, profileUsername)
            .replace(/{char}/g, charName)
            .replace(/{user}/g, profileUsername)
            .replace(/{Char}/g, charName)
            .replace(/{User}/g, profileUsername);
        return message.length > limit ? message.substring(0, limit) + '...' : message;
    };

    function formatTimeAgo(dateTime) {
        // Check for null or invalid date
        if (!dateTime || isNaN(Date.parse(dateTime))) {
            return "Unknown time";
        }

        const messageDate = new Date(dateTime);
        const now = new Date();
        const differenceInSeconds = Math.floor((now - messageDate) / 1000);
        const minute = 60;
        const hour = 3600;
        const day = 86400;
        const week = 604800;
        const month = 2628000;

        if (differenceInSeconds < minute) {
            return `${differenceInSeconds}s ago`;
        } else if (differenceInSeconds < hour) {
            return `${Math.floor(differenceInSeconds / minute)}m ago`;
        } else if (differenceInSeconds < day) {
            return `${Math.floor(differenceInSeconds / hour)}h ago`;
        } else if (differenceInSeconds < week) {
            return `${Math.floor(differenceInSeconds / day)}d ago`;
        } else if (differenceInSeconds < week * 12) {
            const weeksAgo = Math.floor(differenceInSeconds / week);
            if (weeksAgo < 1) {
                return `${Math.floor(differenceInSeconds / day)}d ago`;
            }
            return `${weeksAgo}w ago`;
        } else {
            const monthsAgo = Math.floor(differenceInSeconds / month);
            if (monthsAgo < 12) {
                return `${monthsAgo}m ago`;
            } else {
                const yearsAgo = Math.floor(monthsAgo / 12);
                return `${yearsAgo}y ago`;
            }
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredChats, setFilteredChats] = useState(chats);

    useEffect(() => {
        if (searchTerm !== '') {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            const filteredAndSortedChats = chats
                .filter(chat => chat.name && chat.name.toLowerCase().includes(lowercasedSearchTerm))
                .sort((a, b) => a.name.localeCompare(b.name));
            setFilteredChats(filteredAndSortedChats);
        } else {
            const sortedByTimestamp = [...chats].sort((a, b) => {
                const dateA = new Date(a.lastMessageTime);
                const dateB = new Date(b.lastMessageTime);
                return dateB - dateA;
            });
            setFilteredChats(sortedByTimestamp);
        }
    }, [searchTerm, chats]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const [visibleExtensionName, setVisibleExtensionName] = useState(null);
    const extensionTimerRef = useRef(null);
    const touchInitiatedRef = useRef(false);

    //TODO MOVE THE EXTENSION NAME TO USE IDENTITY NAME INSTEAD OF CHAT.NAME OR ADD A FIX TO AVOID DUPLICATES
    const handleLongInteractionStart = (chat) => () => {
        clearTimeout(extensionTimerRef.current);
        extensionTimerRef.current = setTimeout(() => {
            setVisibleExtensionName(chat.name);
            setSelectedChat(chat); // Set the selected chat here
        }, 150); // Delay showing the extension to avoid immediate visibility
    };

    const handleInteractionEnd = () => {
        clearTimeout(extensionTimerRef.current);
        // Delay the hiding to prevent it from disappearing immediately
        extensionTimerRef.current = setTimeout(() => {
            setVisibleExtensionName(null);
        }, 15000); // Slight delay before hiding the extension
    };

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleMouseEnter = (chatName) => {
        setVisibleExtensionName(chatName);
    };

    const handleMouseLeave = () => {
        setVisibleExtensionName(null);
    };

    const [showNoCharacterModal, setShowNoCharacterModal] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (chats.length === 0) {
                setShowNoCharacterModal(true);
            } else {
                setShowNoCharacterModal(false);
            }
        }, 2000);

        // Cleanup function to clear the timeout if the effect re-runs or the component unmounts
        return () => clearTimeout(timer);
    }, [chats]); // Dependency array, re-run the effect if 'ch

    const {timerExpired} = useTimer();

    function setVH() {
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    window.addEventListener('resize', setVH);
    setVH(); // Set the value on initial load


    return (
        <>
            <div className={`recent-chat-list-container-h ${isMenuOpen ? 'open' : 'closed'}`}
                 style={{
                     zIndex: isMenuOpen === true ? '1' : '1',
                     // height: timerExpired !== false ? '100vh' : 'calc(100vh - 0px)',
                 }}
            >
                {shouldRender ? (
                    <div
                        className="recent-chat-loader"
                        style={{top: timerExpired !== false ? '75px' : '55px'}}
                    >
                        <RecentChatListLoader/>
                    </div>
                ) : null}

                <div className="recent-chat-list-container"
                     style={{
                         marginTop: timerExpired !== false ? '85px' : '145px',
                         height: timerExpired !== false
                             ? 'calc(var(--vh, 1vh) * 100 - 85px)'
                             : 'calc(var(--vh, 1vh) * 100 - 145px)',
                         '--vh': '1dvh',
                     }}
                >
                    {showNoCharacterModal && (
                        <div className="empty-modal">
                            <div className="empty-modal-content"
                                 style={{marginTop: timerExpired !== false ? '80px' : '100px'}}>
                                <span className="feature-icon-text">It's awfully empty.</span>
                                <br></br>
                                <span>Choose to either create a new one or select one right now!</span>
                                <div className='a-straight-line' style={{marginTop: '20px', marginBottom: '5px'}}></div>
                                <div className="button-bottom-no-chars-section">
                                    <button
                                        className='button-no-chars'
                                        onClick={() => {
                                            setShowNoCharacterModal(false);
                                            navigate('/');
                                        }}
                                    >
                                        Find one
                                    </button>
                                    <button
                                        className='button-no-chars'
                                        onClick={() => {
                                            setShowNoCharacterModal(false);
                                            navigate('/create-character'); // Navigate to Create Character
                                        }}
                                    >
                                        Create one
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="chat-navigation-control-menu">
                        <div className="top-section">
                            <span className="recent-chat-list-title">Chats</span>


                            <div className="settings-icons">
                                {!isMobile && (
                                <Tooltip title="Customize Elyza" placement="top" arrow>
                                    <CustomizationIcon onClick={handleCustomizationModal}/>
                                </Tooltip>
                                )}
                                {!isMobile && (
                                    <Tooltip title="Close Menu" placement="top" arrow>
                                        <CloseMainMenu onClick={toggleMenu}/>
                                    </Tooltip>
                                )}
                            </div>


                        </div>
                        <p className="smaller-text">View your recent chats, start a new chat, or change chat options
                            here.</p>
                        <div className="search-container-chatpage">
                            <SearchIcon/>
                            <input
                                className="chat-search-input"
                                type="text"
                                placeholder="Search Chats"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <div
                        className="recent-chat-sidebar-menu"
                        // style={{
                        //     paddingBottom: isWide
                        //         ? (timerExpired !== false ? '70px' : '115px')
                        //         : (timerExpired !== false ? '40%' : '55%')
                        // }}
                    >
                        {filteredChats.map((chat, index) => (
                            <div
                                key={chat.id}
                                className={`sidebar-menu-item ${chat === selectedChat ? 'selected' : ''} ${index === filteredChats.length - 1 ? 'last-chat-item' : ''}`}
                                style={{
                                    borderRadius: !isWide && visibleExtensionName === chat.name ? (index === filteredChats.length - 1 ? '0 0 16px 16px' : '16px 16px 0 0') : {}
                                }}
                                onClick={() => handleChatSelection(chat, true)}
                                onTouchStart={handleLongInteractionStart(chat)}
                                onTouchEnd={handleInteractionEnd}
                                onMouseEnter={() => handleMouseEnter(chat.name)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="recent-chat-info">
                                    {chat.imageSrc ? (
                                        <div style={{display: "flex", maxWidth: '52px', minHeight: '52px'}}>
                                            <LazyLoadImage
                                                className="recent-chat-image"
                                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=128,height=128,compression=fast/${chat.imageSrc}`}
                                                alt="Chat Image"
                                                effect="blur"
                                                loading={'lazy'}
                                                draggable="false" // Prevent dragging the image.
                                                onContextMenu={(e) => e.preventDefault()} // Prevent opening the context menu on right-click.
                                            />
                                        </div>
                                    ) : (
                                        <GroupChatIcon className="group-chat-icon"/>
                                    )}
                                    <div className="chat-details">
                                        <div className="recent-chat-label">{chat.name}</div>
                                        <div className="recent-chat-last-message">
                                            <span>{truncateMessage(chat.lastMessageContent, 40, chat.name, chat.identityName)}</span>
                                        </div>
                                    </div>
                                </div>
                                {isWide ? (
                                    <div className="recent-chat-time">{formatTimeAgo(chat.lastMessageTime)}</div>
                                ) : (
                                    <div className="recent-chat-time-mobile">{formatTimeAgo(chat.lastMessageTime)}</div>
                                )}
                                {isWide && (
                                    <div className="icons-container" onClick={(event) => event.stopPropagation()}>
                                        <button
                                            className="recent-chat-delete-icon"
                                            onClick={() => handleChatDeletion(chat)}
                                        >
                                            <Tooltip title="Delete Conversation" arrow>
                                                <DeleteIcon/>
                                            </Tooltip>
                                        </button>
                                    </div>
                                )}
                                {!isWide && visibleExtensionName === chat.name && (
                                    <div className="icons-container-mobile">
                                        <div
                                            className={`extension-component ${index === filteredChats.length - 1 ? 'last-extension-component' : ''}`}
                                            onClick={(event) => event.stopPropagation()}
                                        >
                                            <button
                                                className="recent-chat-delete-icon-mobile"
                                                onClick={() => handleChatDeletionMobile(chat)}
                                            >
                                                <Tooltip title="Delete Conversation" arrow>
                                                    <DeleteIcon/>
                                                </Tooltip>
                                            </button>
                                            Click to delete conversation.
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {isWide && (
                        <CharacterDeleteForm
                            deleteCall={deleteChat}
                            chat={deletionData}
                            isOpen={isDeletionFormOpen}
                            close={setIsDeletionFormOpen}
                        />
                    )}
                </div>
            </div>

            <CustomizationModal
                isOpen={customizationModalState}
                onCancel={handleCustomizationModal}
                memoriesAndPersonalities={memoriesAndPersonalities}
                setMemoriesAndPersonalities={setMemoriesAndPersonalities}
            />

        </>
    );
}
export default RecentChatList;