import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { ReactComponent as CloseIcon } from '../../../icons/cross.svg';
import "./CustomizationModal.css";
// import Tooltip from "@mui/material/Tooltip";
import {getUserID} from "../../../App";
import {addMemoryFragment, addPersonalityProfile} from "./CustomizationFunctions";
import CustomizationLimitUpsell from "../../Notifications/CustomizationLimitUpsell";

const AddCustomization = ({ isOpen, onClose, onSaveNew, type }) => {
    const [customizationName, setCustomizationName] = useState('');
    const [customizationPrompt, setCustomizationPrompt] = useState('');
    const [portalRoot, setPortalRoot] = useState(null);
    const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

    useEffect(() => {
        setPortalRoot(document.getElementById("modal-root"));
    }, []);

    const handleCustomizationNameChange = (e) => {
        setCustomizationName(e.target.value);
    };

    const handleCustomizationPromptChange = (e) => {
        setCustomizationPrompt(e.target.value);
    };

    const handleSave = async () => {
        const userId = await getUserID();
        const identityName = window.location.pathname.substring(6);
        let select = true;

        try {
            let result;
            if (type === 'personalities') {
                result = await addPersonalityProfile(userId, customizationName, customizationPrompt, select);
            } else {
                result = await addMemoryFragment(userId, identityName, customizationName, customizationPrompt, select);
            }

            if (result.error) {
                console.error('Error adding item:', result.error);
                if (result.error === "Maximum number of memories reached for current subscription" || result.error === "Maximum number of personalities reached for current subscription") {
                    setIsUpsellModalOpen(true);
                }
                return;
            }

            onSaveNew();

            // onSaveNew({
            //     id: result.id, // Assuming the API returns an id
            //     name: customizationName,
            //     description: customizationPrompt,
            //     type: type
            // });

            onClose();
        } catch (error) {
            console.error('Error adding item:', error);
        }
    };

    if (!isOpen || !portalRoot) return null;

    const modalContent = (
        <>
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-centered-container">
                <div className="app-modal-content-response-settings"
                     style={{maxWidth: "420px"}}
                     onClick={(e) => e.stopPropagation()}>
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">Customization Settings</div>
                        <div className="close-modal-icon" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="line-container">

                        <span className="text-line-container">
                            {type === 'personalities'
                                ? "ADD PERSONALITY PROFILE"
                                : "ADD MEMORY FRAGMENT"}
                        </span>

                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">Name your customization</div>

                    <div className="textbox-name-general"
                         style={{marginTop: '5px', marginBottom: '15px', width: '100%'}}
                    >
                        <input
                            type="text"
                            placeholder="Public Name"
                            value={customizationName}
                            onChange={handleCustomizationNameChange}
                            maxLength="25"
                        />
                    </div>

                    <div className="main-elyza-text-response-settings">
                        {type === 'personalities'
                            ? "What do you want the character to know about you?"
                            : "What memory do you want to add?"}
                    </div>

                    <div className="textbox-name-general-short"
                         style={{marginTop: '5px', marginBottom: '25px'}}
                    >
                        <textarea
                            id="scenarioDescription"
                            value={customizationPrompt}
                            onChange={handleCustomizationPromptChange}
                            rows="4"
                            maxLength="500"
                            cols="50"
                            placeholder={type === 'personalities'
                                ? "Describe your own personality, interests, and background. Use {{user}} and {{char}} respectively to refer to the user and character."
                                : "Describe the memory you want to add. Use {{user}} and {{char}} respectively to refer to the user and character."}
                        />
                    </div>

                    <div className="footer-button-container">

                        <button className="need-help-button"
                                style={{backgroundColor: '#757575'}}
                                onClick={onClose}>
                            Cancel
                        </button>

                        <button className="save-settings-button" onClick={handleSave}>
                            Save
                        </button>

                    </div>
                </div>
            </div>
        </div>

            <CustomizationLimitUpsell
                isOpen={isUpsellModalOpen}
                onClose={() => setIsUpsellModalOpen(false)}
                type={type}
            />
        </>
    );

    return ReactDOM.createPortal(modalContent, portalRoot);
};

export default AddCustomization;