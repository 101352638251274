import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const MessageLimitUpsell = ({isOpen, onClose, onSwitchFree, model, modelSwitchExternalCache}) => {

    if (!isOpen || modelSwitchExternalCache === "Elyza Free") return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        ✋ Whoa there!
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false)
                            onSwitchFree(true)
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className="sublabel-notification">
                            <p>
                                As a free user, you have reached your free monthly
                                <span className="colored-purple-text font-bold"> advanced </span>
                                messages limit.
                            </p>
                            <p className="mt-2">
                                Get <span className="colored-purple-text font-bold">unlimited messages</span> as a
                                <span className="colored-purple-text font-bold"> Premium </span> user.
                            </p>

                            {model !== 'Elyza Free' && (
                                "Or switch to the Free Model to continue using Elyza at no cost."
                            )}
                        </div>
                    </div>

                    <button onClick={() => {
                        onClose(true)
                    }} className="notification-button-main"
                    style={{marginTop: model === 'Elyza Classic' ? '20px' : '10px'}}
                    >Upgrade Now
                    </button>

                    {model !== 'Elyza Free' && (
                        <button onClick={() => {
                            onSwitchFree(true)
                        }} className="notification-button-main"
                                style={{backgroundColor: 'gray', marginTop: '10px'}}
                        >Switch to Free</button>
                    )}
                </div>
            </div>
        ),
        document.body // This is where the portal will be rendered
    );
};

export default MessageLimitUpsell