import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import { ReactComponent as UnlockIcon } from "../MainMenuPage/icons/unlock.svg";
import {AuthModal} from "../Forms/AuthModal";

const SignUpToSeeMore = ({ opacity, onClose }) => {
    const isFullyVisible = opacity >= 0.85;
    // console.log(isFullyVisible)
    const [showModal, setShowModal] = useState(false);

    const handleUnlockClick = () => {
        setShowModal(true);
        onClose(false);
        document.body.style.overflow = 'hidden'; // Disable scrolling
    };

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'auto'; // Re-enable scrolling
    };

    return ReactDOM.createPortal(
        (<div
            className="modal-backdrop"
            style={{
                zIndex: '999',
                backgroundColor: `rgba(0,0,0,${opacity * 0.75})`,
                backdropFilter: `blur(${10 * opacity}px)`,
                pointerEvents: isFullyVisible ? 'auto' : 'none' // Only interactive when fully visible
            }}
        >
            <div
                className="modal-content-notification"
                style={{
                    opacity,
                    pointerEvents: isFullyVisible ? 'auto' : 'none', // Interactive only when fully visible
                    zIndex: '10'
                }}
            >
                <div className='notification-lable'>
                    Sign up to see more
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        Get
                        <span className="colored-purple-text"> unlimited messages </span>
                        and create your perfect AI girlfriend or boyfriend.
                        <br /><br />
                        Plus, there are plenty of other exciting features waiting for you! Ready to dive in?
                    </div>
                </div>

                <button
                    onClick={handleUnlockClick}
                    className="notification-button-main"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}
                >
                    <UnlockIcon />
                    Unlock Now
                </button>
            </div>
            {showModal && <AuthModal onClose={handleCloseModal} />}
        </div>),
        document.body
    );
};

export default SignUpToSeeMore;