import React from 'react';
import './ResponseModeMenu.css';

const modeMapping = {
    'Classic': 'ElyzaChocolate',
    'Bold': 'ElyzaCookies&Cream',
    // 'Timid': 'ElyzaStrawberry',
    'Dialogue': 'ElyzaMint',
    'SFW': 'ElyzaVanilla'
};

const ResponseModeMenu = ({ activeMode, onModeChange }) => {
    const modes = Object.keys(modeMapping);

    return (
        <div className="menu-container">
            <div className="button-group">
                {modes.map((mode) => (
                    <button
                        key={mode}
                        className={`mode-button ${modeMapping[mode] === activeMode ? 'active' : ''}`}
                        onClick={() => onModeChange(modeMapping[mode])}
                    >
                        {mode}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ResponseModeMenu;
