import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";





const PlanSuccess = ({isOpen, onClose, children, purchase, coins, rewardType, plan}) => {
    if (!isOpen) return null;
    const displayPlan = plan === 'Gold' ? 'Ultimate' : plan;

    const planBenefits = {
        'Basic': { images: 50, audio: 50 },
        'Premium': { images: 100, audio: 100 },
        'Gold': { images: 500, audio: 500 },
    };
    const benefits = planBenefits[plan] || { images: 0, audio: 0 }; // provide a default value if plan is not a valid key
    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-lable'>
                    🎉 Woo-hoo!
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                     <span className='sublabel-notification'>Welcome to the
                        <span className="colored-purple-text"> Elyza {displayPlan}!</span>
</span>


                    <div className='sublabel-notification'>
                        Your plan comes with
                        <span className="colored-purple-text"> {benefits.images} images </span>
                         and
                        <span className="colored-purple-text"> {benefits.audio} audio messages.</span>
                    </div>

                </div>

                <button onClick={onClose} className="notification-button-main">Okay!</button>


            </div>
        </div>
    );
};

export default PlanSuccess;