import {getUserAccessToken, getUserID} from "../../App";

const identityName = window.location.pathname.substring(6);

export const updateSettingsOnServer = async (newModel) => {
    const userID = await getUserID();
    const userAccessToken = await getUserAccessToken();

    const response = await fetch('https://api.elyza.app/v1/change-settings', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': userAccessToken
        },
        body: JSON.stringify({
            userId: userID,
            identityName: identityName,
            newModel: newModel,
        })
    });

    if (!response.ok) {
        throw new Error('Failed to update settings');
    }
};