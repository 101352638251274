import React from "react";
import "./NewCharacterContainer.css";
import 'react-tooltip/dist/react-tooltip.css';
import { ReactComponent as AltCard } from "./icons/altcard.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Tooltip from '@mui/material/Tooltip';


export const CharacterContainer = ({ character, handleCharacterClick, username, scrollPosition }) => {

    function formatDescription(character, username) {
        let description = character.description.replace(/{{char}}/gi, character.display_name).replace(/{{user}}/gi, username);
        if (description.length > 50) {
            return `${description.substring(0, 50)}...`;
        } else {
            return description;
        }
    }

    const selectImageQuality = () => {
        const width = window.innerWidth;
        if (width <= 768) {
            return "width=416,height=416";
        } else if (width <= 1024) {
            return "width=512,height=512";
        } else if (width <= 1536) {
            return "width=512,height=512";
        } else {
            return "width=512,height=512";
        }
    };

    const imageUrl = character.profile ? `https://elyza.app/cdn-cgi/image/format=avif,${selectImageQuality()},compression=fast/${character.profile}` : "";
    function formatFullDescription(character, username) {
        return character.description.replace(/{{char}}/g, character.display_name).replace(/{{user}}/g, username);
    }
    return (
        <div className="character-container-new" onClick={() => handleCharacterClick(character.identity_name, character.creatorId)}>
            {/*<Tooltip id={`tooltip-${character.identity_name}`} place="bottom" effect="solid" />*/}
            <div className="character-image-overlay-container-new">
                {imageUrl ? (
                    <LazyLoadImage
                        effect="blur"
                        src={imageUrl}
                        alt={character.display_name}
                        className="character-image-main-new"
                        placeholderSrc={AltCard}
                        scrollPosition={scrollPosition} // Utilize the scrollPosition prop
                    />
                ) : (
                    <AltCard className="character-svg-altcard" />
                )}
                <div className="character-gradient-overlay-new"></div>
                <div className="character-text-content-new">
                    <div className="character-name-new" data-tip data-for={`tooltip-${character.identity_name}`}>
                        {character.display_name}
                    </div>

                    <div className="character-description-new" data-tip data-for={`tooltip-${character.identity_name}`}>
                        <Tooltip title={formatFullDescription(character, username)} placement="top" arrow>
                            <span>{formatDescription(character, username)}</span>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};
