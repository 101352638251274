import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    const [portalRoot, setPortalRoot] = useState(null);

    useEffect(() => {
        setPortalRoot(document.getElementById("modal-root"));
    }, []);

    if (!isOpen || !portalRoot) return null;

    const modalContent = (
        <div className="modal-overlay"
             // style={{backdropFilter: 'blurpx)'}}
             onClick={onClose}
        >
            <div className="modal-centered-container">
                <div className="app-modal-content-response-settings"
                     onClick={(e) => e.stopPropagation()}
                     style={{ maxWidth: "360px"}}
                >
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">Are you sure?</div>
                        <div className="close-modal-icon" onClick={onClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className="a-straight-line-3"></div>

                    <div className="main-elyza-text-response-settings">
                        This action cannot be undone and will permanently delete this profile. Are you sure you want to continue?
                    </div>

                    <div className="footer-button-container"
                         style={{marginTop: '10px'}}
                    >
                        <button className="need-help-button"
                                style={{backgroundColor: '#757575'}}
                                onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button className="save-settings-button"
                                style={{backgroundColor: '#E86D6D'}}
                                onClick={onConfirm}>
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modalContent, portalRoot);
};

export default DeleteConfirmationModal;