import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import {ReactComponent as StackCoins} from '../../../icons/stack-o-coins.svg';
import {ReactComponent as MoreMessageIcon} from '../../../icons/MoreMessagesIcon.svg';
import {getUserAccessToken, getUserID} from "../../../App";

const ProductModal = ({
                          isOpen,
                          onClose,
                          setRewardCoins, setBonusMessages

}) => {


    const [selectedOption, setSelectedOption] = useState(50);
    const [totalCoins, setTotalCoins] = useState(0);
    const [exchangeOutcome, setExchangeOutcome] = useState(0);
    const [showFailedMessage, setShowFailedMessage] = useState(false);


    const handlePurchase = async () => {
        try {
            const accessToken = await getUserAccessToken();
            const userId = await getUserID();
            const response = await fetch('https://api.elyza.app/v1/referral-purchase', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken,
                },
                body: JSON.stringify({
                    userId: userId,
                    packageType: 'messages',
                    packageCount: selectedOption // Use selectedOption to determine packageCount
                }),
            });

            if (response.ok) {
                // console.log('Purchase successful!');
                // Update bonus messages and deduct cost from reward coins
                setBonusMessages(prevMessages => Number(prevMessages) + exchangeOutcome);
                setRewardCoins(prevCoins => prevCoins - totalCoins);
                onClose(); // Close modal on successful purchase
            } else {
                console.error('Purchase failed');
                setShowFailedMessage(true);

            }
        } catch (error) {
            console.error('Error making purchase:', error);
            setShowFailedMessage(true);


        }
    };



    const handleClose = () => {
        setShowFailedMessage(false); // Close the modal
        if (onClose) {
            onClose(); // Call any additional onClose logic passed as a prop
        }
    };

    const exchangeRates = {
        50: { coins: 100, outcome: 50 },
        300: { coins: 500, outcome: 300 },
        1000: { coins: 1750, outcome: 1000 },
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // Update totalCoins based on the selected option
        if (exchangeRates[selectedOption]) {
            setTotalCoins(exchangeRates[selectedOption].coins);
            setExchangeOutcome(exchangeRates[selectedOption].outcome);
        } else {
            setTotalCoins(0);
            setExchangeOutcome(0);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);
    // eslint-disable-next-line react-hooks/rules-of-hooks

    if (!isOpen) return null;
    const modalContent = (

        <div className="main-space-finder-modal" onClick={onClose}>

            <div className="app-modal-content-referral" onClick={(e) => e.stopPropagation()}>
                {showFailedMessage && (
                    <div className="modal-backdrop">
                        <div className="modal-content-notification">
                            <div className='notification-lable'>
                                ✋ Uh oh!
                                <div className="modal-close-icon-notification" onClick={handleClose}>
                                    <CloseIcon/>
                                </div>
                            </div>

                            <div className='sublabel-notification'>
                                Seems that your balance is too low! <br></br>
                                <div
                                    style={{marginTop: '20px'}}
                                    className="sublabel-notification"> You can earn more coins by inviting friends or completing tasks.
                                </div>
                            </div>


                            <button onClick={handleClose} className="notification-button-main">Okay

                            </button>


                        </div>
                    </div>
                )}
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">Verify your purchase!</div>
                    <div className="close-modal-icon" onClick={onClose}><CloseIcon/></div>
                </div>

                <div className="line-container">
                    <span className="text-line-container">PRODUCT</span>
                    <div className="line"></div>
                </div>
                <div className="profile-setting-sublabel">Spend your well earned Elyza coins here to purchase additional
                    features and addons.
                </div>


                <div className="parent-container-transfer">
                    <span className="coins-transfer-container">{totalCoins}<StackCoins/></span>
                    <span className="coins-transfer-container">=</span>
                    <span className="coins-transfer-container">{exchangeOutcome}<MoreMessageIcon/></span>
                </div>


                <div className="line-container">
                <span className="text-line-container">AMOUNT</span>
                    <div className="line"></div>
                </div>
                <div className="profile-setting-sublabel">Here you can select the amount of images you want to buy.
                </div>


                <div className="product-option-row">
                    <div className={`product-container-option ${selectedOption === 50 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(50)}>
                        <span className="product-option-insides">50
                        <MoreMessageIcon/>
                        </span>
                    </div>
                    <div className={`product-container-option ${selectedOption === 300 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(300)}>
                        <span className="product-option-insides">300
                        <MoreMessageIcon/>
                        </span>
                    </div>
                    <div className={`product-container-option ${selectedOption === 1000 ? 'selected' : ''}`}
                         onClick={() => setSelectedOption(1000)}>
                        <span className="product-option-insides">1000
                        <MoreMessageIcon/>
                        </span>
                    </div>
                </div>


                <div className="line-container">
                    <span className="text-line-container">TOTAL</span>
                    <div className="line"></div>
                </div>


                <div className="parent">
                    <div className='total-cost-container'>
                    <span className="total-coins">Total cost: </span>
                    <span className='highlight-text-price'>{totalCoins}</span>
                        <StackCoins/>
                    </div>
                    <button className="redeem-button" onClick={handlePurchase}>Purchase</button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modalContent, document.body);
};

export default ProductModal;