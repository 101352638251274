import React, {useState, useRef, useEffect} from 'react';
import './SendMessageTextbox.css';
import {ReactComponent as AttachIcon} from '../../icons/attach.svg';
import {ReactComponent as CloseIcon} from "../../icons/cross.svg";
import {ReactComponent as HearMe} from '../../icons/HearMe.svg';
import {ReactComponent as ViewMe} from "../../icons/ViewMe.svg";
import {ReactComponent as SendMessageIcon} from '../../icons/send-message.svg';
// import CharacterTurnMenu from "./CharacterTurnMenu";
import UpgradeNotification from "../Notifications/UpgradeNotification";
import {useNavigate} from "react-router-dom";
import {useProfileData} from "../Context/ProfileDataContext";
import Tooltip from "@mui/material/Tooltip";
// import {ChatFuncContext} from "../Context/ChatFunctionsContext";

const SendMessageTextbox = ({
                                addUserMessage,
                                isMessageRunning,
                                setIsMessageRunning,
                                isRendering

                            }) => {
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const containerRef = useRef(null);
    const fileInputRef = useRef(null);
    const [bottomMargin, setBottomMargin] = useState(0);
    const [base64Image, setBase64Image] = useState('');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const buttonContainerRef = useRef(null); // Ref for the button container
    const identityName = window.location.pathname.substring(6);


    useEffect(() => {
        const adjustHeight = () => {
            const textarea = textareaRef.current;
            if (!textarea) return;

            textarea.style.height = '36px';
            let newHeight = Math.max(textarea.scrollHeight, 36);

            newHeight = Math.min(newHeight, 120);
            textarea.style.height = `${newHeight}px`;

            setBottomMargin(newHeight - 50);

            // Adjust the container's margin bottom based on textarea's height
            const container = containerRef.current;
            if (container) {
                let paddingBottom = Math.max(0, newHeight - 20);
                paddingBottom = Math.min(paddingBottom, 120);
                container.style.marginBottom = `${paddingBottom - 20}px`;

                // Adjusting padding to make space for an image if attached
                if (base64Image) {
                    const imageHeight = 100;
                    setBottomMargin(newHeight + 15);
                    paddingBottom += imageHeight;
                    container.style.marginBottom = `${paddingBottom - 55}px`;
                }
            }

            // Adjust the position of the buttons
            const buttonContainer = buttonContainerRef.current;
            if (buttonContainer) {
                buttonContainer.style.transform = `translateY(-${newHeight - 36}px)`; // Moving buttons up as textarea grows
            }
        };

        adjustHeight();
        window.addEventListener('input', adjustHeight);
        window.addEventListener('resize', adjustHeight);
        return () => {
            window.removeEventListener('input', adjustHeight);
            window.removeEventListener('resize', adjustHeight);
        };
    }, [message, base64Image, isRendering]);


    const [isLoading, setIsLoading] = useState(false);

    const fetchMessage = () => {
        setIsLoading(true);
        const savedMessage = localStorage.getItem(`message-${identityName}`);
        setTimeout(() => {
            if (savedMessage) {
                setMessage(savedMessage);
            } else {
                setMessage('');
            }
            setIsLoading(false);
        }, 100); // Simulated delay of 1 second
    };

    // useEffect to fetch message when identityName changes
    useEffect(() => {
        fetchMessage();
        //eslint-disable-next-line
    }, [identityName]);





    useEffect(() => {
        if (!isLoading) {
            const timer = setTimeout(() => {
                if (message) {
                    localStorage.setItem(`message-${identityName}`, message);
                } else {
                    localStorage.removeItem(`message-${identityName}`);
                }
            }, 100); // 1-second delay

            return () => clearTimeout(timer);
        }
    }, [message, identityName, isLoading]);

    const handleChange = (e) => setMessage(e.target.value);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (isMobile) {
                // Insert a new line
                setMessage(prevMessage => prevMessage + '\n');
            } else {
                sendMessage();
            }
        }
    };

    const sendMessage = () => {
        const trimmedMessage = message.trim();
        if (trimmedMessage || base64Image) {
            setIsMessageRunning(true);
            addUserMessage(trimmedMessage, base64Image);
            localStorage.removeItem(`message-${identityName}`);
            setMessage('');
            setBase64Image('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(message);
        setMessage('');
    };

    const handleAttachClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleRemoveImage = () => {
        setBase64Image('');
    };
    const containerClasses = [
        "send-message-container",
        isMessageRunning ? "is-message-running" : "",
        base64Image ? "attached-image-form-extension" : ""
    ].filter(Boolean).join(' ');

    const formClasses = [
        "send-message-form",
        isMessageRunning ? "is-message-running" : "",
        base64Image ? "form-image-attached" : ""
    ].filter(Boolean).join(' ');

    const { profileData } = useProfileData();

    const [subscriptionError, setSubscriptionError] = useState(false);

    useEffect(() => {
        if (profileData.subscription_data.tier === 'FREE') {
            setSubscriptionError(true);
        } else {
            setSubscriptionError(false);
        }
    }, [profileData.subscription_data.tier]);

    const [showUpgradeNotification, setShowUpgradeNotification] = useState(false);

    //TODO add a feature for the user to actually click in into the image and allow fullscreen viewing of the message.
    useEffect(() => {
        if (!isMessageRunning && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [isMessageRunning]);

    const navigate = useNavigate();


    const internalGenerateImage = async () => {
        setMessage("Send me a picture");
    }

    const internalGenerateAudio = async () => {
        setMessage("I want to hear you");
    }



    return (
        <div ref={containerRef} className={containerClasses}>


            <div className="ControlContainerFunction" style={{marginBottom: `${bottomMargin}px`}}>

                <button
                    onClick={internalGenerateImage}
                    className='call-function-button'
                    disabled={false}
                    style={{
                        // backgroundColor: contextualImageLoading ? '#1e1e1e' : '',
                        // cursor: contextualImageLoading ? 'not-allowed' : 'pointer'
                        backgroundColor: '',
                        cursor: 'pointer'
                    }}
                >
                    Send me a picture
                    <div className="FunctionButtonIcon">
                        <ViewMe/>
                    </div>
                </button>

                <button
                    onClick={internalGenerateAudio}
                    className='call-function-button'
                    disabled={false}
                    style={{
                        // backgroundColor: contextualAudioLoading ? '#1e1e1e' : '',
                        // cursor: contextualAudioLoading ? 'not-allowed' : 'pointer'
                        backgroundColor: '',
                        cursor: 'pointer'
                    }}
                >
                    I want to hear you
                    <div className="FunctionButtonIcon">
                        <HearMe/>
                    </div>
                </button>


                {/*<button*/}
                {/*    onClick={''}*/}
                {/*    className='call-function-button'*/}
                {/*    disabled={false}*/}
                {/*    style={{*/}
                {/*        backgroundColor: '',*/}
                {/*        cursor: 'pointer'*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div className="FunctionButtonIcon">*/}
                {/*        <ViewMe/>*/}
                {/*    </div>*/}
                {/*</button>*/}

            </div>


            {base64Image && (
                <div className="attached-image-preview">
                    <img className='attached-file-image' src={base64Image} alt="Attached"/>
                    <button className="close-icon-button" onClick={handleRemoveImage}>
                        <CloseIcon/>
                    </button>
                </div>
            )}


            <form className={formClasses} onSubmit={handleSubmit}>


                <input
                    type="file"
                    ref={fileInputRef}
                    style={{display: 'none'}}
                    onChange={handleFileChange}
                    onClick={(e) => {
                        if (!subscriptionError) {
                            return;
                        }
                        e.preventDefault();
                        setShowUpgradeNotification(true);
                    }}
                    accept="image/*"
                />


                <div className="textarea-wrapper">
            <textarea
                ref={textareaRef}
                className="message-input"
                value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Type a message..."
                disabled={isMessageRunning}
                maxLength={800}
                autoFocus
            />
                </div>

                <Tooltip title='Attach image' arrow placement={"top"}>
                    <button type="button" className="icon-button attach"
                            style={{marginLeft: 0}}
                            onClick={handleAttachClick}
                            disabled={isMessageRunning}>
                        <AttachIcon/>
                    </button>
                </Tooltip>
                <SendMessageIcon className="icon-button" onClick={sendMessage} disabled={isMessageRunning}/>
            </form>
            {showUpgradeNotification && (
                <UpgradeNotification isOpen={showUpgradeNotification} onClose={(confirm) => {
                    setShowUpgradeNotification(false)
                    if (confirm) {
                        navigate('/pricing');
                    }
                }}/>
            )}
        </div>

    );
}

export default SendMessageTextbox;