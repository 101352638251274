import React, { useState, useEffect } from 'react';
import './CreateCharacter.css';
import RealStyle from "./icons/realstyle.webp";
import AnimeStyle from "./icons/animestyle.webp";
import FurryStyle from "./icons/furrystyle.webp";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css'; // for blur effect


const CharacterStep3 = () => {
    const [selectedStyle, setSelectedStyle] = useState(() => {
        // Attempt to get the selected style from local storage
        const savedStyle = localStorage.getItem('selectedStyle');
        return savedStyle ? savedStyle : null;
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 680);
        };
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to handle gender selection
    const handleStyleSelect = (style) => {
        setSelectedStyle(style);
        localStorage.setItem('selectedStyle', style);
    };
    return (
        <div className="gender-selection-container">
            <div className="char-created-label-3">What style will this character be in?</div>
            <span className="profile-setting-sublabel">
                This will dictate the style images of the character will be created in.
            </span>

            <div className="gender-options-container" style={isMobile ? { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' } : {}}>
                <div
                    className={`gender-option ${selectedStyle === 'Realistic' ? 'gender-option-selected' : ''}`}
                    onClick={() => handleStyleSelect('Realistic')}
                    style={isMobile ? { flex: '1 0 48%' } : {}}
                >
                    <LazyLoadImage
                        alt="Realistic"
                        src={RealStyle}
                        className="gender-image"
                        effect="blur"
                        style={{ pointerEvents: 'none' }}
                    />



                    <span className='enforced-display-char-creator'>Realistic</span>
                </div>

                <div
                    className={`gender-option ${selectedStyle === 'Anime' ? 'gender-option-selected' : ''}`}
                    onClick={() => handleStyleSelect('Anime')}
                    style={isMobile ? { flex: '1 0 48%' } : {}}
                >
                    <LazyLoadImage
                        alt="Anime"
                        src={AnimeStyle}
                        className="gender-image"
                        effect="blur"
                        style={{ pointerEvents: 'none' }}
                    />
                    <span className='enforced-display-char-creator'>Anime</span>
                </div>

                <div
                    className={`gender-option ${selectedStyle === 'Furry' ? 'gender-option-selected' : ''}`}
                    onClick={() => handleStyleSelect('Furry')}
                    style={isMobile ? { flexBasis: '50%' } : {}}
                >
                    <LazyLoadImage
                        alt="Furry"
                        src={FurryStyle}
                        className="gender-image"
                        effect="blur"
                        style={{ pointerEvents: 'none' }}
                    />
                    <span className='enforced-display-char-creator'>Furry</span>
                </div>
            </div>
        </div>
    );
};


export default CharacterStep3;
