import React, {createContext, useState, useEffect, useContext} from 'react';
import {getUserAccessToken, getUserID} from "../../App";

export const ProfileContext = createContext();

export const ProfileDataProvider = ({children}) => {
    const [profileData, setProfileData] = useState({username: 'Guest'},);
    const [rewardCoins, setRewardCoins] = useState(parseInt(profileData.reward_coins, 10) || 0);
    const [isDataFetched, setIsDataFetched] = useState(false);

    useEffect(() => {
        setRewardCoins(parseInt(profileData.reward_coins, 10) || 0);
    }, [profileData.reward_coins]);

    useEffect(() => {
        const fetchUserData = async () => {
            const userID = await getUserID();
            if (!userID) {
                setProfileData({
                    username: 'Guest',
                    subscription_data: {
                        tier: 'FREE',
                    }
                });

                setIsDataFetched(true);
                return;
            }

            const token = await getUserAccessToken();
            try {
                await fetch('https://api.elyza.app/v1/update-profile', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify({
                        userId: userID,
                        imageBase64: "",
                        newUsername: "",
                        newBio: "",
                        genderPreference: localStorage.getItem('genderPreference') ?? "Female",
                        justRegistered: true,
                        affCode: localStorage.getItem('affCode') ?? "",
                        referralCode: localStorage.getItem('referralCode') ?? "",
                        campaignCode: localStorage.getItem('campaignCode') ?? "",
                        targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([]))
                    })
                });
            } catch (error) {
                console.log('Error fetching user data:', error);
            }

            try {
                const response = await fetch('https://api.elyza.app/v1/profile-data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken(),
                    },
                    body: JSON.stringify({userId: userID}),
                });

                if (!response.ok) {
                    console.error('Failed to fetch user data:', response);
                    if (response.status === 404) {
                        console.error('User not found, logging out...');
                        localStorage.clear();
                        window.location.href = '/';
                    }
                    setIsDataFetched(true);
                    return;
                }

                const data = await response.json();
                // Update both profileData and currentName upon fetching
                setProfileData(data);
                //setCurrentName(data.username); // Ensure currentName is updated with fetched username
                setIsDataFetched(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (!isDataFetched) {
            fetchUserData();
        }
    }, [isDataFetched]);


    if (!isDataFetched) return null;

    return (
        <ProfileContext.Provider value={{profileData, rewardCoins, setRewardCoins}}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfileData = () => {
    const context = useContext(ProfileContext);
    if (context === undefined) {
        throw new Error('useProfileData must be used within a ProfileDataProvider');

    }
    return context;
};