import React, { useCallback, useState, useEffect } from 'react';
import './CreateCharacter.css';
import { ReactComponent as FolderImage } from './icons/FolderImage.svg';
import { ReactComponent as TrashCan } from './icons/TrashCan.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {FormControlLabel, Switch} from "@mui/material";
import { ReactComponent as AltCard } from "../MainMenuPage/icons/altcard.svg";
import {userIDExists} from "../../App";
import {AuthModal} from "../Forms/AuthModal";
import {ReactComponent as InfoIcon} from "../MainMenuPage/icons/HelpMe.svg";
import Tooltip from "@mui/material/Tooltip";
import GenerateImagePopup from "./GenerateImagePopup";
import Cookies from "js-cookie";
const CharacterStep7 = ({removeImage, setCharacterImageExists, skipProfilePicture, setSkipProfilePicture}) => {
    //eslint-disable-next-line
    const [imageFile, setImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [isGenerateImagePopupOpen, setIsGenerateImagePopupOpen] = useState(false);

    const handleCheckboxChange = async () => {
        const loggedIn = await validateIsLoggedIn();
        if (!loggedIn) {
            setIsModalOpen(true); // Open the modal if the user is not logged in
        } else {
            setSkipProfilePicture(!skipProfilePicture);
            setIsGenerateImagePopupOpen(!skipProfilePicture); // Open the GenerateImagePopup modal when the switch is turned on
        }
    };

    const fileToBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });


    useEffect(() => {
        const savedImage = localStorage.getItem('characterImage');
        if (savedImage) {
            setImagePreviewUrl(savedImage);
        } else {
            setImagePreviewUrl(null); // Explicitly set to null when no image is found
            setCharacterImageExists(false); // Update the state to reflect that no image exists
        }
        //eslint-disable-next-line
    }, []);
    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    const handleFileChange = async (file) => {
        // Check file size
        if (file.size > MAX_FILE_SIZE) {
            alert("File is too large. Please upload a file smaller than 5MB.");
            return;
        }

        // Check file type
        const validTypes = ['image/png', 'image/jpeg','image/JPG', 'image/webp'];
        if (!validTypes.includes(file.type)) {
            alert("Invalid file type. Only PNG, JPG, JPEG, and WEBP are allowed.");
            return;
        }

        setImageFile(file);
        try {
            const base64 = await fileToBase64(file);
            localStorage.setItem('characterImage', base64);
            setCharacterImageExists(true);
            setImagePreviewUrl(base64);
        } catch (error) {
            console.error("Error converting file to base64:", error);
        }
    };


    const onFileInputChange = useCallback((event) => {
        const file = event.target.files[0];
        //eslint-disable-next-line
        if (file) handleFileChange(file);
        //eslint-disable-next-line
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        //eslint-disable-next-line
        if (file) handleFileChange(file);
        //eslint-disable-next-line
    }, []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
    }, []);

    const handleRemoveImageClick = () => {
        removeImage(); // Call the prop function to handle removal logic
        setImagePreviewUrl(''); // Clear the local image preview state
    };


    const [loginError, setLoginError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists();
        setLoginError(!loggedIn);

        return loggedIn;
    };

    useEffect(() => {
        const checkLoginStatus = async () => {
            const loggedIn = await validateIsLoggedIn();
            if (!loggedIn) {


            }
        };

        checkLoginStatus();
    }, []);



    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const shouldHideInfoIcon = Cookies.get('hideGenerateImagePopup') === 'true';

    return (
        <div>
            {!shouldHideInfoIcon && (

                <GenerateImagePopup isOpen={isGenerateImagePopupOpen} onClose={() => setIsGenerateImagePopupOpen(false)} />
            )}
            {loginError && isModalOpen ? (
                <div className="ErrorScreenOverlay">
                    <AuthModal onClose={handleCloseModal}/>
                </div>
            ) : null}

            <div className="char-created-label-3">Want to give your character a picture?</div>
            <span className="profile-setting-sublabel">You can upload one now, or generate some using our services!</span>

            <div className='two-switches-menu'
                 style={{marginTop: '5px', marginBottom: '-10px', alignItems: 'center'}}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={skipProfilePicture}
                            onChange={handleCheckboxChange}
                            name="skipProfilePicture"
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'white', // Thumb color when checked
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                    },
                                }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#9072D9', // Track color when checked
                                }, '& .MuiSwitch-track': {
                                    backgroundColor: 'grey', // Track color when unchecked
                                },
                            }}
                        />}
                    labelPlacement="end"
                    label={
                        <>
                            I would like a generated image.
                        </>
                    }
                />

                {!shouldHideInfoIcon && (
                    <button onClick={() => setIsGenerateImagePopupOpen(true)}>
                        <Tooltip title="Click for Info" placement="right" arrow>
                            <InfoIcon style={{marginLeft: '-30px', marginTop: '5px', cursor: 'pointer'}}/>
                        </Tooltip>
                    </button>
                )}
            </div>


            <div
                className="image-upload-container"
                onDrop={onDrop}
                onDragOver={onDragOver}
                style={{border: '2px dashed #696969', padding: '20px', textAlign: 'center'}}
            >

                {imagePreviewUrl ? (
                    <div className={"final-image-option-h"}>

                        <div className="final-option-image-upload">
                            {imagePreviewUrl ? (
                                <LazyLoadImage
                                    effect="blur"
                                    src={imagePreviewUrl}
                                    alt={""}
                                    className="char-final-image"
                                    placeholderSrc={AltCard}
                                />
                            ) : (
                                <AltCard className="character-svg-altcard"/>
                            )}
                        </div>

                        <div className='image-control-option'>


                            <label htmlFor="file-input">
                                <div className="image-reupload-prompt">
                                    <FolderImage className='folder-image-icon'/>
                                    Change Image
                                </div>
                            </label>

                            <button className='image-reupload-prompt' onClick={handleRemoveImageClick}>
                                <TrashCan/>
                                Remove Image
                            </button>

                        </div>
                        <span style={{justifyContent: 'center', display: 'flex', marginTop: '10px'}}
                            className="profile-setting-sublabel">Note: Characters without an image cannot be public.</span>
                    </div>
                ) : (

                    <>

                        <label htmlFor="file-input"
                               style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}}>
                            <div className="image-upload-prompt">
                                <span className="profile-setting-label-2">Drop your image here or click to browse</span>
                                <span>Supports: PNG, JPG, JPEG, WEBP</span>
                            </div>
                        </label>

                    </>
                )}


                <input
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    onChange={onFileInputChange}
                    style={{display: 'none'}}
                    id="file-input"
                />
            </div>

        </div>
    );
};

export default CharacterStep7;
