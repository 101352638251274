// ChatContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the Chat context
const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

// Provider component
export const ChatProvider = ({ children }) => {
    const [isGalleryBannerVisible, setIsGalleryBannerVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [description, setDescription] = useState("");
    const [scenario, setScenario] = useState("");
    const [profileURLBanner, setProfileURLBanner] = useState("");
    const [activeComponent, setActiveComponent] = useState("");
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [wasRecentChatUpdated, setWasRecentChatUpdated] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const value = {
        isGalleryBannerVisible,
        setIsGalleryBannerVisible,
        description,
        setDescription,
        scenario,
        setScenario,
        profileURLBanner,
        setProfileURLBanner,
        isMenuOpen,
        setIsMenuOpen,
        activeComponent,
        setActiveComponent,
        toggleMenu,
        isActiveModal,
        setIsActiveModal,
        wasRecentChatUpdated,
        setWasRecentChatUpdated,
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};
