import React, {useCallback, useState} from 'react';
import '../CharacterCreation/CreateCharacter.css';
import {ReactComponent as CloseIcon} from "../CharacterCreation/icons/cross.svg";
import {getUserAccessToken, getUserID} from "../../App";
import Tooltip from '@mui/material/Tooltip';
import ReactDOM from 'react-dom';
import './CharacterEdit.css';
import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import {BeatLoader} from "react-spinners";


const CustomAccordion = styled(Accordion)({
    backgroundColor: '#171717',
    color: '#C4C4C4',
    borderRadius: '8px',
    '@media (max-width: 680px)': {
        // Add styles for smaller screens
    }
});

const CustomAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#252525',
    color: 'white',
    borderRadius: '8px',
    "&.Mui-expanded": {
        borderRadius: '0',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        maxHeight: '52px',
        minHeight: '52px'
    },
    '&:hover': {
        backgroundColor: '#272727', // Lighter shade on hover
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'white'
    },
    '@media (max-width: 680px)': {
        padding: '0 10px 0 10px',
        fontSize: '0.9rem',
        maxHeight: '42px',
        minHeight: '42px'
    }
});

const CustomAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#313131',
    color: 'white',
    padding: '20px',
    borderRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    '@media (max-width: 680px)': {
        padding: '5px 10px 5px 10px'
    }
});

const CustomTextArea = styled('textarea')({
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    color: 'white',
    backgroundColor: '#171717',
    border: '1px solid #696969',
    borderRadius: '16px',
    boxSizing: 'border-box',
    resize: 'vertical',
    maxHeight: '220px',
    minHeight: '80px',
    overflow: 'auto',
    '@media (max-width: 680px)': {
        resize: 'none',
        maxHeight: '80px'
    }
});


const CharacterEdit = ({isOpen, onClose, data, setData, fetchData, identityName}) => {
    // const { data, setData, fetchData, identityName } = useContext(IdentityDataContext);





    const {
        display_name,
        // gender,
        // modelStyle,
        // isNSFW,
        // characterName,
        description,
        is_public,
        personality,
        appearance,
        character_scenario,
        dialogue,
        profile,
        // lora,

    } = data;


    const [expanded, setExpanded] = useState('panel0');
    const [isLoading, setIsLoading] = useState(false);


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const [privateStatus, setPrivateStatus] = useState(is_public);

    const handleToggle = (event) => {
        setPrivateStatus(!privateStatus);
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const [profileLink, setProfileLink] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(profile);


    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (event) => {
        setIsLoading(true);
        const file = event.target.files[0];
        if (!file) return;

        // Check file size
        if (file.size > MAX_FILE_SIZE) {
            alert("File is too large. Please upload a file smaller than 5MB.");
            return;
        }

        // Check file type
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
        if (!validTypes.includes(file.type)) {
            alert("Invalid file type. Only PNG, JPG, JPEG, and WEBP are allowed.");
            return;
        }

        setImagePreviewUrl(file);
        try {
            const userId = await getUserID();
            const userAccessToken = await getUserAccessToken();
            const base64 = await fileToBase64(file);
            setImagePreviewUrl(base64);

            // Send a POST request to the provided URL
            const response = await fetch('https://api.elyza.app/v1/create-profile-picture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken,
                },
                body: JSON.stringify({
                    userId,
                    base64: base64,
                    isAiGenerated: false,
                    triggerWords: '',
                    baseLora: '',
                    backgroundInformation: {
                        display_name: display_name
                    }
                }),
            });

            if (response.ok) {
                const data = await response.json();
                const profileUrl = data.url;
                setProfileLink(profileUrl);
                setIsLoading(false);
            } else {
                // console.error('Failed to upload profile picture');
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            // console.error("Error converting file to base64 or uploading image:", error);
        }
    };

    const onFileInputChange = useCallback((event) => {
        handleFileChange(event);
        //eslint-disable-next-line
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) handleFileChange({ target: { files: [file] } });
        //eslint-disable-next-line
    }, []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
    }, []);



    const updateCharacter = async (updatedCharacterData) => {
        const {identityName, gender, modelStyle, isNSFW, characterName, description, isPublic, personality, appearance, scenario, dialogue, profileUrl, lora} = updatedCharacterData;

        try {
            const userId = await getUserID();
            const userAccessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/direct-identity-edit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' ,
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({
                    userId,
                    identityName,
                    gender,
                    modelStyle,
                    isNSFW,
                    characterName,
                    description,
                    isPublic,
                    personality,
                    appearance,
                    scenario,
                    dialogue,
                    profileUrl,
                    lora
                })
            });

            if (response.ok) {
                // console.log('Character updated successfully');

            } else {
                // console.log('Failed to update character');
            }
        } catch (error) {
            // console.error('An error occurred while updating the character:', error);
        }
    };

    const [shortDescriptionInput, setShortDescriptionInput] = useState(description);
    const [personalityDescriptionInput, setPersonalityDescriptionInput] = useState(personality);
    const [appearanceDescriptionInput, setAppearanceDescriptionInput] = useState(appearance);
    const [scenarioDescriptionInput, setScenarioDescriptionInput] = useState(character_scenario);
    const [dialogueDescriptionInput, setDialogueDescriptionInput] = useState(dialogue);
    const [displayNameInput, setDisplayNameInput] = useState(display_name);

    const handleDisplayNameChange = (event) => {
        setDisplayNameInput(event.target.value);
    };
    const handleShortDescriptionChange = (event) => {
        setShortDescriptionInput(event.target.value);
    };
    const handlePersonalityDescriptionChange = (event) => {
        setPersonalityDescriptionInput(event.target.value);
    };
    const handleAppearanceDescriptionChange = (event) => {
        setAppearanceDescriptionInput(event.target.value);
    };
    const handleScenarioDescriptionChange = (event) => {
        setScenarioDescriptionInput(event.target.value);
    };
    const handleDialogueDescriptionChange = (event) => {
        setDialogueDescriptionInput(event.target.value);
    };



    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const SuccessModal = ({ onClose }) => {
        return (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-lable'>
                        ✅ Thank you!
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className='sublabel-notification'>
                        Your character was successfully updated. <br></br>
                        <div
                            style={{marginTop: '20px'}}
                            className="sublabel-notification"> Your changes will apply in the next couple minutes.
                        </div>
                    </div>


                    <button onClick={onClose} className="notification-button-main"
                            style={{marginTop: '25px', marginBottom: "0"}}
                    >Okay

                    </button>


                </div>
            </div>
        );
    };
    const handleSave = async () => {
        const updatedCharacterData = {
            identityName: identityName,
            characterName: displayNameInput,
            description: shortDescriptionInput,
            isPublic: privateStatus,
            personality: personalityDescriptionInput,
            appearance: appearanceDescriptionInput,
            scenario: scenarioDescriptionInput,
            dialogue: dialogueDescriptionInput,
            profileUrl: profileLink ?? imagePreviewUrl,
        };

        try {
            await updateCharacter(updatedCharacterData);
            await fetchData();


            setData(prevData => ({
                ...prevData,
                characterName: displayNameInput,
                description: shortDescriptionInput,
                is_public: privateStatus,
                personality: personalityDescriptionInput,
                appearance: appearanceDescriptionInput,
                scenario: scenarioDescriptionInput,
                dialogue: dialogueDescriptionInput,
                profile: profileLink ?? imagePreviewUrl,
            }));
            setShowSuccessModal(true);  // Show the success modal

        } catch (error) {
            console.error("Error updating character: ", error);
        }
    };



    const onCloseModal = () => {
        // Reset all state values
        setExpanded('panel0');
        setIsLoading(false);
        setPrivateStatus(is_public);
        setProfileLink(null);
        setImagePreviewUrl(profile);
        setShortDescriptionInput(description);
        setPersonalityDescriptionInput(personality);
        setAppearanceDescriptionInput(appearance);
        setScenarioDescriptionInput(character_scenario);
        setDialogueDescriptionInput(dialogue);
        setDisplayNameInput(display_name);
        setShowSuccessModal(false);

        // Close the modal
        onClose();
    };




    if (!isOpen) return null;
    return ReactDOM.createPortal((


        <div className="modal-backdrop">

            {isLoading && (
                <div className="modal-backdrop">
                    <div className="message-loading-spin"
                         style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px'}}
                    >
                        <BeatLoader color="white"/>
                    </div>
                </div>
            )}

            <>
                {showSuccessModal && <SuccessModal onClose={() => {
                    setShowSuccessModal(false);
                    onCloseModal();
                }} />}
            </>

            <div className='content-setting-menu'>

                <div className="gender-selection-container">
                    <div className="char-created-label-3" style={{
                        alignItems: "center", width: "100%", display: "flex", justifyContent: "space-between"
                    }}>
                        Tweak your character's details.
                        <div className="close-modal-icon" style={{width: '24px'}} onClick={onCloseModal}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <span className="profile-setting-sublabel">You can dynamically edit the character's personality, appearance, scenario, and dialogue.</span>
                    <div className="textbox-generate-button-general"
                         style={{display: 'flex', alignItems: 'center'}}
                    >
                        <Tooltip title="Update Profile Picture" placement="bottom" arrow>
                            <div className="update-profile-image"
                                 onDrop={onDrop}
                                 onDragOver={onDragOver}
                            >
                                <input type="file" style={{display: 'none'}} id="file-input" onChange={onFileInputChange}/>
                                {imagePreviewUrl ? (
                                    <img src={imagePreviewUrl} alt="Profile"
                                         style={{maxWidth: '100%', borderRadius: '8px', maxHeight: '100%', objectFit: 'contain', objectPosition: 'center'}}
                                         onClick={() => document.getElementById('file-input').click()}
                                    />
                                ) : (
                                    <label htmlFor="file-input" style={{cursor: 'pointer',}}>Upload Image</label>
                                )}
                            </div>
                        </Tooltip>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className="textbox-name-general"
                                 style={{maxWidth: "180px"}}
                            >
                                <Tooltip title="25 characters max" placement="top" arrow>
                                    <label className="label-sign-up-h">Character Name</label>
                                </Tooltip>

                                <input
                                    type="text"
                                    placeholder="Public Name"
                                    maxLength="25"
                                    value={displayNameInput}
                                    onChange={handleDisplayNameChange}
                                />
                            </div>

                            <div className='public-to-private-switch' style={{marginLeft: "0"}}>
                                <FormControlLabel
                                    control={<Switch
                                        checked={privateStatus}
                                        onChange={handleToggle}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: 'white', // Thumb color when checked
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                                },
                                            }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#9072D9', // Track color when checked
                                                marginLeft: '0px',
                                            }, '& .MuiSwitch-track': {
                                                backgroundColor: 'grey', // Track color when unchecked
                                            },
                                        }}
                                    />}
                                    labelPlacement="start"
                                    style={{marginLeft: '0px',}}
                                    label="Public"
                                />
                            </div>
                        </div>
                    </div>


                    <div className="accordion-component-main">
                        <CustomAccordion expanded={expanded === 'panel0'} onChange={handleAccordionChange('panel0')}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Short Description</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea id="shortDescription" rows="4" cols="50"
                                                defaultValue={description}
                                                maxLength='250'
                                                value={shortDescriptionInput}
                                                onChange={handleShortDescriptionChange}
                                                placeholder="This is the short description for your character, this is only visible to all users if the character is public."/>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Personality Description</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea id="personalityDescription" rows="4" cols="50"
                                                defaultValue={personality}
                                                maxLength='3000'
                                                value={personalityDescriptionInput}
                                                onChange={handlePersonalityDescriptionChange}
                                                placeholder="Personality description of the character, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."/>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Appearance Description</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea id="appearanceDescription" rows="4" cols="50"
                                                defaultValue={appearance}
                                                maxLength='2000'
                                                value={appearanceDescriptionInput}
                                                onChange={handleAppearanceDescriptionChange}
                                                placeholder="Appearance description of the character, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."/>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>Scenario Description</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea id="scenarioDescription" rows="4" cols="50"
                                                defaultValue={character_scenario}
                                                maxLength='1500'
                                                value={scenarioDescriptionInput}
                                                onChange={handleScenarioDescriptionChange}
                                                placeholder="Scenario description of the character, where the character is, what they are doing, etc, including intimate details. You shall describe your characters shortly, but it is good enough to infer what you are describing."/>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                        <CustomAccordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>First Message</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea id="dialogueDescription" rows="4" cols="50"
                                                maxLength='1500'
                                                defaultValue={dialogue}
                                                value={dialogueDescriptionInput}
                                                onChange={handleDialogueDescriptionChange}
                                                placeholder="This will function as the first message from the character. You shall use {{user}} and {{char}} to refer to the character in the dialogue."/>
                            </CustomAccordionDetails>
                        </CustomAccordion>
                    </div>

                </div>
                <div className="a-straight-line-3" style={{zIndex: 1}}></div>
                <div className="footer-button-container"
                     style={{justifyContent: "flex-end"}}>
                    <button className="save-settings-button"
                            style={{width: '200px', height: '40px', marginTop: '10px', marginBottom: '15px'}}
                            onClick={handleSave}>
                        Save
                    </button>
                </div>


            </div>


        </div>), document.body);
};


export default CharacterEdit;
