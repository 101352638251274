import React from 'react';
import './CreateCharacter.css';


const ImportScreen = () => {






    return (<div className="gender-selection-container">
        <div className="profile-setting-label-2">Let’s get that imported.</div>
        <span className="profile-setting-sublabel">
Provide a link to the character from another character AI website.
We might ask for login credentials if this is a private character.
            </span>


        <div className="textbox-name-general-import">
            <label className="label-sign-up-h"></label>
            <input
                type="text"
                placeholder="https://beta.character.ai/character-profile..."  // Added placeholder attribute
            />

        </div>




    </div>);
};

export default ImportScreen;
