import React from 'react';

import ReactDOM from 'react-dom';

import Modal from 'react-modal';
import './CharacterDeleteForm.css';
import { ReactComponent as CloseIcon } from "../../icons/cross.svg";
import {getUserAccessToken, getUserID} from "../../App";



export const CharacterDeleteForm = ({ deleteCall, chat, isOpen, close }) => {

    const confirmDeletion = async () => {
        const id = await getUserID();
        const postData = {
            userId: id,
            identityName: chat.identityName
        };
        fetch('https://api.elyza.app/v1/delete-character', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: await getUserAccessToken(),
            },
            body: JSON.stringify(postData)
        })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                deleteCall(chat);
            })
            .catch(error => {
                console.error(error);
            });
    }
    const modal = (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => close(false)}
            contentLabel="Delete Character"
            className="custom-modal-content-delete-char"
            overlayClassName="custom-modal-overlay"
        >
            <div className="deletion-char-modal">
                <div className="main-upper-text-row">
                    <span className="main-elyza-text-label"
                          style={{fontSize: '1.5em'}}
                    >✋ Hey there!</span>
                    <div className="close-modal-icon" onClick={() => close(false)}>
                        <CloseIcon />
                    </div>
                </div>
                <div className="deletion-text">

                    <span className="main-elyza-text-sublabel">Are you sure you want to delete this character?
                  <br></br>
                    This is an action you cannot undo.
                    </span>
                </div>
                <button className="change-step-button" onClick={confirmDeletion}>
                    Delete Conversation
                </button>
            </div>

        </Modal>

    );

    return document.getElementById('modal-root') ? ReactDOM.createPortal(
        modal,
        document.getElementById('modal-root')
    ) : null;

}