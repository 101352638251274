import React, {useEffect, useState, useRef, useContext} from 'react';
// import {Tooltip} from 'react-tooltip';
import Tooltip from '@mui/material/Tooltip';
import './Header.css';
import {Link, useLocation} from 'react-router-dom';
import {ReactComponent as ElyzaIcon} from '../../icons/ElyzaText.svg';
import SearchBarNew from './Searchbar';
import {ReactComponent as HomeIcon} from './icons/HomeIcon.svg';
import {ReactComponent as ChatIcon} from './icons/ChatIcon.svg';
import {ReactComponent as CreateCharIcon} from './icons/CreateCharIcon.svg';
import {ReactComponent as UserProfileIcon} from './icons/UserProfileIcon.svg';
import {CharacterContext} from "../Context/CharacterContext";
import ProfileEdit from "../ProfileEdit/ProfileEdit";
import {userIDExists} from "../../App";
import {AuthModal} from "../Forms/AuthModal";
import {ReactComponent as SearchIcon} from "../../icons/search.svg";

export const HeaderMainMenu = ({searchText, setSearchText, setSearchCharacters}) => {
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedMenu, setSelectedMenu] = useState(location.pathname);
    const searchbarRef = useRef(null);
    const {realChars} = useContext(CharacterContext);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const headerRef = useRef(null);
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

    const hideSearchBar = location.pathname.includes('/chat') || location.pathname.includes('/create-character') || location.pathname.includes('/pricing') || location.pathname.includes('/legal') || location.pathname.includes('/gallery');

    const toggleSearchBarVisibility = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        let path;

        if (location.pathname === '/') {
            path = '/';
        } else if (location.pathname.startsWith('/chat')) {
            path = '/chat';
        } else if (location.pathname === '/profile') {
            path = '/profile';
        } else if (location.pathname === '/create-character') {
            path = '/create-character';
        } else {
            path = location.pathname; // For any other path
        }

        setSelectedMenu(path);
    }, [location]);


    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
    const toggleProfileEditModal = () => {
        setIsProfileEditModalOpen(!isProfileEditModalOpen);
    };

    const menuItems = [
        {name: <HomeIcon/>, tooltip: 'Home', path: '/'},
        {name: <ChatIcon/>, tooltip: 'Chat', path: '/chat', requiresLogin: true},
        {name: <CreateCharIcon/>, tooltip: 'Create Character', path: '/create-character', requiresLogin: false},
        {name: <UserProfileIcon/>, tooltip: 'User Profile', path: '/profile', requiresLogin: true},
    ];

    let modifiedMenuItems = [...menuItems];
    if (windowWidth < 680 && !hideSearchBar) {
        const searchToggleItem = {
            name: <SearchIcon/>,
            tooltip: 'Search',
            path: '#',
            customAction: toggleSearchBarVisibility
        };
        const homeIndex = modifiedMenuItems.findIndex(item => item.tooltip === 'Home');
        if (homeIndex !== -1) {
            modifiedMenuItems.splice(homeIndex + 1, 0, searchToggleItem);
        }
    }

    // useEffect(() => {
    //     setSelectedMenu(location.pathname);
    // }, [location.pathname]);


    useEffect(() => {
        if (isProfileEditModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isProfileEditModalOpen]);

    const isChatRoute = location.pathname.startsWith('/chat');
    const headerContainerClass = location.pathname.includes('/chat') ? 'header-container-chat' : 'header-container';

    return (
        <div
            className={`main-header-container ${isSearchBarVisible ? 'expanded' : ''} ${isChatRoute ? 'main-header-container-chat' : ''}`}
            ref={headerRef}

        >

            {isProfileEditModalOpen && (
                <ProfileEdit
                    onClose={toggleProfileEditModal}
                    loginError={false} // TODO: Add login error logic

                />
            )}

            {
                isAuthModalOpen && (
                    <AuthModal
                        isOpen={isAuthModalOpen}
                        onClose={() => setIsAuthModalOpen(false)}
                    />
                )
            }
            <div className={headerContainerClass}>

                <div className="header-icon">
                    <Link to="/">
                        <ElyzaIcon/>
                    </Link>
                </div>

                {!hideSearchBar && (
                    <>
                        <div className="header-searchbar">
                            {/* Conditionally render the SearchBarNew component */}
                            <SearchBarNew
                                setSearchText={setSearchText}
                                searchText={searchText}
                                characters={null} // Adjust according to your implementation
                                searchbarRef={null} // Adjust if needed
                                setSearchCharacters={setSearchCharacters}
                            />
                        </div>
                        {windowWidth < 680 && (
                            <div className="mobile-search-icon">
                            </div>
                        )}
                    </>
                )}

                <div className="header-menu">
                    {modifiedMenuItems.map((menu, index) => (
                        <div key={index} className="menu-item-container">
                            <Tooltip className="tooltip-for-header" title={menu.tooltip} placement="bottom" arrow>
                                {menu.customAction && !hideSearchBar ? (
                                    <div
                                        className={`header-menu-item ${isSearchBarVisible ? 'selected' : ''}`}
                                        onClick={() => {
                                            // Assuming this is your toggle for search bar visibility
                                            toggleSearchBarVisibility();
                                        }}
                                    >
                                        {menu.name}
                                    </div>
                                ) : menu.tooltip === 'User Profile' ? (
                                    <div
                                        className={`header-menu-item ${selectedMenu === menu.path ? 'selected' : ''}`}
                                        onClick={() => {
                                            validateIsLoggedIn().then(loggedIn => {
                                                if (loggedIn) {
                                                    setTimeout(() => {
                                                        toggleProfileEditModal();
                                                    }, 100); // Add delay here
                                                } else {
                                                    setIsAuthModalOpen(true);
                                                }
                                            });
                                        }}
                                    >
                                        {menu.name}
                                    </div>
                                ) : (
                                    <Link
                                        to={menu.path}
                                        className={`header-menu-item ${selectedMenu === menu.path ? 'selected' : ''}`}
                                        onClick={(e) => {
                                            const isSelected = selectedMenu === menu.path;
                                            if (menu.requiresLogin && !isSelected) {
                                                e.preventDefault();
                                                validateIsLoggedIn().then(loggedIn => {
                                                    if (loggedIn) {
                                                        window.location.href = menu.path;
                                                    } else {
                                                        setIsAuthModalOpen(true);
                                                    }
                                                });
                                            }
                                        }}
                                    >
                                        {menu.name}
                                    </Link>
                                )}
                            </Tooltip>
                        </div>
                    ))}
                </div>

            </div>
            {isSearchBarVisible && (
                <div className="header-searchbar-visible">
                    <SearchBarNew
                        setSearchText={setSearchText}
                        searchText={searchText}
                        characters={realChars}
                        searchbarRef={searchbarRef}
                        setSearchCharacters={setSearchCharacters}
                    />
                </div>
            )}
        </div>
    );
};