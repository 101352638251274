import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useProfileData } from "../Context/ProfileDataContext";
import UpgradeNewModel from "../Notifications/UpgradeNewModel";
import MessageLimitUpsell from "../Notifications/MessageLimitUpsell";
import { useNavigate } from "react-router-dom";
import { getUserAccessToken, getUserID } from "../../App";
import { useChat } from "../Context/ChatContext"; // Assuming this is the correct import path

const CustomDropdown = ({ value, setValue, setLlmModel, modelSwitchExternalCache, setModelSwitchExternalCache }) => {
    const navigate = useNavigate();
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [messageLimitError, setMessageLimitError] = useState(false);

    const { profileData } = useProfileData();
    const { wasRecentChatUpdated, setWasRecentChatUpdated } = useChat();

    const isFreeUser = profileData.subscription_data.tier === "FREE";
    const hasReachedMessageLimit = isFreeUser && profileData.message_count >= 50;

    const identityName = window.location.pathname.substring(6);

    useEffect(() => {
        if (wasRecentChatUpdated) {
            Object.keys(localStorage).forEach(key => {
                if (key.startsWith('model_')) {
                    localStorage.removeItem(key);
                }
            });
            setWasRecentChatUpdated(false);
        }

        const storedModel = localStorage.getItem(`model_${identityName}`);
        if (storedModel) {
            setModelSwitchExternalCache(storedModel);
            setValue(storedModel);
            setLlmModel(storedModel);
        } else {
            setModelSwitchExternalCache(value);
        }
    }, [wasRecentChatUpdated, identityName, setValue, setLlmModel, setModelSwitchExternalCache, value, setWasRecentChatUpdated]);

    const handleSwitchToFree = async () => {
        const newModel = 'Elyza Free';
        setLlmModel(newModel);
        setValue(newModel);
        setModelSwitchExternalCache(newModel);
        setMessageLimitError(false);

        localStorage.setItem(`model_${identityName}`, newModel);

        try {
            await updateSettingsOnServer(newModel);
            // console.log("Settings updated successfully");
        } catch (error) {
            // console.error('Error updating settings:', error);
        }
    };

    const updateSettingsOnServer = async (newModel) => {
        const userID = await getUserID();
        const userAccessToken = await getUserAccessToken();

        const response = await fetch('https://api.elyza.app/v1/change-settings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userAccessToken
            },
            body: JSON.stringify({
                userId: userID,
                identityName: identityName,
                newModel: newModel,
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update settings');
        }
    };

    const options = useMemo(() => {
        const baseOptions = [
            { value: 'Elyza Free', label: 'Elyza Free', restricted: false },
            {
                value: 'Elyza Classic',
                label: 'Elyza Classic',
                restricted: isFreeUser && hasReachedMessageLimit
            },
        ];

        if (!isFreeUser) {
            baseOptions.push({ value: 'Elyza Modern', label: 'Elyza Advanced', restricted: false });
        } else {
            baseOptions.push({ value: 'Elyza Modern', label: 'Elyza Advanced', restricted: true });
        }
        return baseOptions;
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier, hasReachedMessageLimit]);

    useEffect(() => {
        setMessageLimitError(hasReachedMessageLimit && (value === 'Elyza Classic' || value === 'Elyza Modern'));
    }, [value, hasReachedMessageLimit]);

    const selectedValue = useMemo(() => {
        const storedModel = localStorage.getItem(`model_${identityName}`);
        return options.find(option => option.value === (storedModel || value));
    }, [options, identityName, value]);

    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'white',
            backgroundColor: '#9072D9',
            minHeight: '50px',
            height: '50px',
            borderRadius: '14px',
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#9072D9',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#744dd7' : '#9072D9',
            color: 'white',
            cursor: 'pointer',
            ':active': {
                backgroundColor: '#744dd7',
            },
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    const handleChange = async (selectedOption) => {
        if (selectedOption.restricted) {
            if (isFreeUser && hasReachedMessageLimit && selectedOption.value === 'Elyza Classic') {
                setMessageLimitError(true);
                setSubscriptionError(true);
            } else {
                setSubscriptionError(true);
            }
            return; // Exit early if the option is restricted
        }

        const newModel = selectedOption.value;

        // Update front-end state
        setModelSwitchExternalCache(newModel);
        setValue(newModel);
        setLlmModel(newModel);

        // Update localStorage for all models
        localStorage.setItem(`model_${identityName}`, newModel);

        // Only send request if switching to 'Elyza Free'
        if (newModel === 'Elyza Free') {
            try {
                await updateSettingsOnServer(newModel);
                // console.log("Settings updated successfully");
            } catch (error) {
                // console.error('Error updating settings:', error);
            }
        }
    };

    return (
        <>
            {subscriptionError && <UpgradeNewModel isOpen={subscriptionError} onClose={(confirm) => {
                setSubscriptionError(false);
                if (confirm) {
                    navigate('/pricing');
                }
            }}/>}

            {messageLimitError && <MessageLimitUpsell
                isOpen={messageLimitError}
                onClose={() => setMessageLimitError(false)}
                onSwitchFree={handleSwitchToFree}
                model={value}
                modelSwitchExternalCache={modelSwitchExternalCache}
            />}

            <Select
                options={options}
                styles={customStyles}
                value={selectedValue}
                onChange={handleChange}
                menuPortalTarget={document.body}
                isSearchable={false}
            />
        </>
    );
};

export default CustomDropdown;